import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal } from "@awesome.me/kit-0ea32a987c/icons";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { actionGetMagazineApiCall } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";

import classes from "./magazineoverview.module.scss";

const MagazineOverview = ({ magazines }) => {
  console.log("MO", magazines);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={classes.grid_5}>
        {magazines?.length > 0 &&
          magazines?.map((item, index) => {
            return (
              <a
                href={`/my-magazines/${item.id}/edit`}
                className={classes.item}
              >
                <div className={classes.image}>
                  <img
                    src={`http://woningmarkt.s3.amazonaws.com/production/magazines/images/${item.magazine_type.script_class}.png`}
                    alt=""
                  />
                  <div className={[classes.tags, classes.flex].join(" ")}>
                    <div className={classes.tag}>
                      <FontAwesomeIcon icon={fal.faMailbox} />
                      <span>
                        <span className={classes.number}>
                          {" "}
                          {item.publication_date === null
                            ? t("magazine_unknown_date")
                            : DateTime.fromISO(item.publication_date).toFormat(
                                "dd LLL"
                              )}
                        </span>
                        <span className={classes.label}>
                          {" "}
                          {item.publication_date === null
                            ? t("magazine_unknown_date")
                            : DateTime.fromISO(item.publication_date).toFormat(
                                "yyyy"
                              )}
                        </span>
                      </span>
                    </div>
                    <div className={classes.tag}>
                      <FontAwesomeIcon icon={fal.faCloudArrowUp} />
                      <span>
                        <span className={classes.number}>
                          {item.deadline === null
                            ? t("magazine_unknown_date")
                            : DateTime.fromISO(item.deadline).toFormat(
                                "dd LLL"
                              )}
                        </span>
                        <span className={classes.label}>
                          {item.deadline === null
                            ? t("magazine_unknown_date")
                            : DateTime.fromISO(item.deadline).toFormat("yyyy")}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.content}>
                  <h4>{item.name}</h4>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      let body = { id: item.id };
                      dispatch(actionGetMagazineApiCall(body));
                      navigate(`/my-magazines/${item.id}/edit`);
                    }}
                    className={classes.button}
                  >
                    <FontAwesomeIcon icon={fal.faPartyHorn} /> Deelnemen
                  </a>
                </div>
              </a>
            );
          })}
      </div>
    </React.Fragment>
  );
};

export { MagazineOverview };
