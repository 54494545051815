import React, { useEffect, useState } from "react";
import classes from "./footer.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionChangeLanguageApiCall,
  actionFooterStrapiApiCall,
  actionGetLanguagesApiCall,
  actionHomeStrapiApiCall,
  actionSaveSelctedLanguage,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import FooterRedirectionTab from "../../../utils/FooterRedirectionTab";
import { Link } from "react-router-dom";
const Footer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languages = useSelector((state) => state.CommonReducer.languages);
  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );
  const saveFooterStrapi = useSelector(
    (state) => state.CommonReducer.saveFooterStrapi
  );
  const saveCopyRightContentStrapi = useSelector(
    (state) => state.CommonReducer.saveCopyRightContentStrapi
  );
  const saveSocialIconsStrapi = useSelector(
    (state) => state.CommonReducer.saveSocialIconsStrapi
  );
  const authorizeToken = localStorage.getItem("authToken");
  const auth = authorizeToken && authorizeToken !== "" ? true : false;
  const [language, setLanguage] = useState(
    selectedLanguage?.language ? selectedLanguage?.language : "nl"
  );
  useEffect(() => {
    dispatch(actionGetLanguagesApiCall());
    dispatch(actionFooterStrapiApiCall({ navigate, title: "footer" }));
    dispatch(actionHomeStrapiApiCall({ navigate, title: "copy-right" }));
    dispatch(actionHomeStrapiApiCall({ navigate, title: "socials" }));
    // i18n.changeLanguage(selectedLanguage?.language);
  }, []);
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage?.language);
    dispatch(actionHomeStrapiApiCall({ navigate, title: "home-pages" }));
    dispatch(actionFooterStrapiApiCall({ navigate, title: "footer" }));
    dispatch(actionHomeStrapiApiCall({ navigate, title: "copy-right" }));
    dispatch(actionHomeStrapiApiCall({ navigate, title: "socials" }));
    dispatch(actionFooterStrapiApiCall({ navigate, title: "header" }));
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [selectedLanguage]);
  const languageChangeHandler = (e) => {
    // i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
    let data = {
      langCode: e.target.value,
    };
    let body = { data, navigate };
    if (e.target.value) {
      dispatch(
        actionSaveSelctedLanguage(
          languages.find((l) => l.language == e.target.value)
        )
      );
    }
    if (auth) {
      dispatch(actionChangeLanguageApiCall(body));
      localStorage.setItem("language", e.target.value);
    } else {
      localStorage.setItem("language", e.target.value);
    }
  };
  return (
    <div className={classes.footer_container}>
      <div className={classes.footer_section}>
        {saveFooterStrapi?.data?.length > 0 &&
          saveFooterStrapi?.data?.map((data) => {
            return (
              <div key={data.id} className={classes.footer_box}>
                <div className={classes.footer_heading}>
                  <h4>
                    {t(`${data?.attributes?.Title}`) || data?.attributes?.Title}
                  </h4>
                </div>
                <div className={classes.footer_user_info_pages}>
                  {data?.attributes?.Items?.length > 0 &&
                    data?.attributes?.Items?.map((item) => {
                      return (
                        <div key={item.id}>
                          <Link
                            to={FooterRedirectionTab(item)}
                            state={{
                              id: item?.cms_page?.data?.id,
                              slug: item?.cms_page?.data?.attributes?.Slug,
                            }}
                            target={
                              item["__component"] === "menu.external-link"
                                ? "_blank"
                                : "_self"
                            }
                          >
                            {t(`${item?.Title}`) || item?.Title}
                          </Link>
                          <br />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
      <div className={classes.socialFooter_section}>
        <div className={classes.sociali_links_icons}>
          {saveSocialIconsStrapi?.data?.length > 0 &&
            saveSocialIconsStrapi?.data?.map((item) => {
              return (
                <div key={item.id} className={classes.social_icons}>
                  <img
                    src={item?.attributes?.Icon?.data?.attributes?.url}
                    alt={item?.attributes?.Icon?.data?.attributes?.name}
                    onClick={() => window.open(`${item?.attributes?.Link}`)}
                  />
                </div>
              );
            })}
        </div>
        <div className={classes.copyRight_containt}>
          <p
            dangerouslySetInnerHTML={{
              __html: saveCopyRightContentStrapi?.data?.attributes?.Content,
            }}
          ></p>
        </div>
        <div className={classes.language_containt}>
          <p>{t("language")}</p>
          <select
            name="language"
            id=""
            value={language}
            defaultValue={language}
            onChange={languageChangeHandler}
          >
            {languages?.length > 0 &&
              languages?.map((item, index) => {
                return (
                  <option key={index} value={item.language}>
                    {item.language_label}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </div>
  );
};
export default Footer;
