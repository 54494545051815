import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import CreateOrgAccountMainOne from "../../components/CreateOrgAccountMainOne";
import CreateOrgAccountMainTwo from "../../components/CreateOrgAccountMainTwo";
import { useLocation } from "react-router-dom";

const CreateOrgAccountPage = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Header />
      {/* {location.pathname == "/create-org-account-step1" && ( */}
      <CreateOrgAccountMainOne />
      {/* )} */}
      {/* {location.pathname.includes("/create-org-account-step2") && (
        <CreateOrgAccountMainTwo />
      )} */}
      <Footer />
    </React.Fragment>
  );
};

export default CreateOrgAccountPage;
