import "./App.scss";
import { RouterProvider, useParams } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { router } from "./Router/Router";
import { useDispatch, useSelector } from "react-redux";
import { actionGetSiteSettingsApiCall, actionSaveLoginInfo } from "./Redux/Actions";
import { useState } from "react";
import ComingSoonPage from "./pages/ComingSoonPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "./components/Loader";
import APIUrl from "./API/APIUrl";

const App = () => {
  const dispatch = useDispatch();
  const saveSiteSettings = useSelector(
    (state) => state.CommonReducer.saveSiteSettings
  );
  const [isShowComingSoon, setIsShowComingSoon] = useState(false);

  useEffect(() => {
    if (
      window.location.href.includes(
        "https://woningmarket.testyourapp.online"
      ) ||
      window.location.href.includes("localhost")
    ) {
      localStorage.setItem("Platform", "DEV");
    } else if (
      window.location.href.includes("https://wm-test.woningmarkt.nl")
    ) {
      localStorage.setItem("Platform", "UAT");
    } else {
      localStorage.setItem("Platform", "PROD");
    }
    dispatch(actionGetSiteSettingsApiCall());
  }, []);
  useEffect(() => {
    if (saveSiteSettings && saveSiteSettings.length > 0) {
      let isMaintenance = saveSiteSettings.find(
        (item) => item.key === "ismaintenance"
      );
      if (Object.keys(isMaintenance).length > 0) {
        if (
          isMaintenance.key == "ismaintenance" &&
          isMaintenance.title == "true"
        ) {
          setIsShowComingSoon(true);
        } else {
          setIsShowComingSoon(false);
        }
      }
    }
  }, [saveSiteSettings]);
  useEffect(() => {
    // Parse the token from the URL
	const params = useParams;
	console.log(params,"params romjfnjkd")
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const email = urlParams.get("email");
    const userId = urlParams.get("userId");
    const name = urlParams.get("name");
    console.log(token, "token frontenddd");
    if (token) {
		console.log(email,"paramssss")
		dispatch(
			actionSaveLoginInfo({
			  full_name: name,
			}))
      // Store the token in local storage
      localStorage.setItem("authToken", token);
      localStorage.setItem("email", email);
      localStorage.setItem("user_id", userId);
      localStorage.setItem("userName", name);
    }
  }, []);

  const LoaderComponent = () => {
    return (
      <div className="div-center">
        <Loader />
      </div>
    );
  };

  return (
    <Suspense fallback={<LoaderComponent />}>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          icon={false}
        />
        {isShowComingSoon ? (
          <ComingSoonPage
            data={saveSiteSettings.find((item) => item.key === "maintenance")}
          />
        ) : (
          <RouterProvider router={router} />
        )}
      </div>
    </Suspense>
  );
};

export default App;
