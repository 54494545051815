import React, { useState } from "react";
import classes from "./propartysearchbar.module.scss";
import SearchIocn from "../../../assets/icons/SearchIcon.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  actionCityInfoApiCall,
  actionGetPropertyAllDataApiCall,
  actionLatestPropertyListingHomeApiCall,
  actionSavePropertyType,
  actionSaveSearchBackgroundImageUrl,
  actionSearchStatus,
} from "../../../Redux/Actions";
import fromDateMap from "../../../utils/fromDateMap";
import moment from "moment";
import { useEffect } from "react";
import Select from "react-select";

const PropartySearchBar = (props) => {
  const pathName = useLocation().pathname;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state_id = localStorage.getItem("user_id");
  console.log(props.country,"propertysearchbar")
  const savePropertyType = useSelector(
    (state) => state.CommonReducer.savePropertyType
  );
  const state = useSelector((state) => state.CommonReducer);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const OrgID = useParams();
  const isHomePage = pathName === '/' || 
                    pathName === '/home' || 
                    pathName.startsWith('/home/');
  const [searchData, setSearchData] = useState({
    address: props.address ? props.address : "",
    distance: props.distance ? props.distance : "",
    minPrice: props.minPrice ? props.minPrice : "",
    maxPrice: props.maxPrice ? props.maxPrice : "",
    listingType: params.get("listingType")
      ? params.get("listingType")
      : savePropertyType
      ? savePropertyType
      : "",

    address_data: state?.saveSearchBackground
      ? {
          label:
            state?.saveSearchBackground.type == "NEIGHBOURHOOD" ||
            state?.saveSearchBackground.type == "DISTRICT"
              ? `${state?.saveSearchBackground.name}(${t(
                  state?.saveSearchBackground.type
                )}
          , ${state?.saveSearchBackground.city?.name})`
              : `${state?.saveSearchBackground.name}(${t(
                  state?.saveSearchBackground.type
                )})`,
          value: state?.saveSearchBackground,
        }
      : {},
    region_id: state?.saveSearchBackground?.id,
    region: state?.saveSearchBackground?.type,
    countryId: params.get("countryId") || props.CountryId || 1
  });
  console.log("the searchData is",searchData);

  const [addressSearch, setAddressSearch] = useState("");
  console.log("addressSearch", addressSearch);
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      boxShadow: "none",
      border: "none",
    }),
    control: (base) => ({
      ...base,
      background: !pathName.includes("/view-organisation")
        ? pathName.includes("/home") || !pathName.includes("/search")
          ? "#fbfbfb"
          : state?.saveSearchBackground &&
            Object.keys(state?.saveSearchBackground).length > 0 &&
            state?.saveSearchBackground?.image
          ? "#fbfbfb"
          : "#fbfbfb"
        : "#fbfbfb",

      // width: "500px",
      // height: "50px", // Set the height as per your requirement
      minHeight: "35px",
      border: "none",
      borderRadius: "80px",
      padding: "0px 10px",
      borderBottomLeftRadius: state.isFocused ? 0 : 80,
      borderBottomRightRadius: state.isFocused ? 0 : 80,
      boxShadow: state.isFocused ? "0 0 0 2px pink" : "none",// Maintain pink border on hover when focused
      // // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",

      // Removes weird border around container
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 15,
      // kill the gap
      marginTop: 5,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      borderRadius: 15,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "5px 10px",
    }),
  };
  const backgroundColor = !pathName.includes("/view-organisation")
    ? pathName.includes("/home") || !pathName.includes("/search")
      ? "#F0F0F0"
      : state?.saveSearchBackground &&
        Object.keys(state?.saveSearchBackground).length > 0 &&
        state?.saveSearchBackground?.image
      ? "#F0F0F0"
      : "#fbfbfb"
    : "#fbfbfb";
  useEffect(() => {
    const listingTypeFromParams = params.get("listingType");
    if (listingTypeFromParams) {
      setSearchData((prevSearchData) => ({
        ...prevSearchData,
        listingType: listingTypeFromParams,
      }));
    }
  }, [params.get("listingType")]);
  useEffect(() => {
    console.log("inside the useEffect data is",searchData)
    dispatch(
      actionCityInfoApiCall({
        navigate,
        search: addressSearch
          ? addressSearch
          : searchData?.address_data?.value?.type
          ? searchData?.address_data?.value?.type
          : searchData?.region,
        countryId: searchData.countryId || params.get("countryId") || props.country || "1"
      })
    );
  }, [addressSearch]);

  useEffect(() => {
    if (pathName == "/home" || pathName == "/") {
      setSearchData({
        ...searchData,
        address_data: {},
      });
      dispatch(actionSaveSearchBackgroundImageUrl({}));
    }
  }, [pathName]);

  useEffect(() => {
    let defaultsearchVal =
      state?.saveSearchBackground &&
      Object.keys(state?.saveSearchBackground).length > 0 &&
      state?.saveSearchBackground?.name
        ? {
            label:
              state?.saveSearchBackground.type == "NEIGHBOURHOOD" ||
              state?.saveSearchBackground.type == "DISTRICT"
                ? `${state?.saveSearchBackground.name}(${t(
                    state?.saveSearchBackground.type
                  )}
                            , ${state?.saveSearchBackground.city?.name})`
                : `${state?.saveSearchBackground.name}(${t(
                    state?.saveSearchBackground.type
                  )})`,
            value: {
              id: state?.saveSearchBackground.id,
              type: state?.saveSearchBackground.type,
              image: state?.saveSearchBackground.image,
              name: state?.saveSearchBackground.name,
            },
          }
        : null;

    setAddressSearch(defaultsearchVal);
  }, [state?.saveSearchBackground]);

  const array = [
    "0 km",
    "1 km",
    "2 km",
    "5 km",
    "10 km",
    "15 km",
    "30 km",
    "50 km",
    "100 km",
  ];

  const handleChange = (e) => {
    let { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };
  const searchFilterData = async () => {
    const today = moment().format("YYYY-MM-DD");

    let data = {
      area: searchData.address_data && searchData.address_data?.value?.name,
      region: searchData.address_data?.value?.type || searchData?.region,
      region_id: searchData.address_data?.value?.id || searchData?.region_id,
      minPrice: searchData.minPrice,
      maxPrice: searchData.maxPrice,
      listingType: params.get("listingType")
        ? params.get("listingType")
        : savePropertyType
        ? savePropertyType
        : "",
      energyClass: params.get("energyClass") ? params.get("energyClass") : "",
      description: params.get("description") ? params.get("description") : "",
      bedroom: params.get("bedroom") ? params.get("bedroom") : "",
      sqaureMeter: params.get("sqaureMeter") ? params.get("sqaureMeter") : "",
      status: params.get("status") ? params.get("status") : "",
      fromdate: params.get("days") ? fromDateMap[`${params.get("days")}`] : "",
      todate:
        params.get("days") != "" &&
        params.get("days") != "No preference" &&
        typeof params.get("days") != "object" &&
        today,
      user_id: state_id,
      property_type: params.get("propertyType")
        ? params.get("propertyType")
        : "",
      construction_year: params.get("constructionYear")
        ? params.get("constructionYear")
        : "",
      page: 1,
      per_page: pathName.includes("/view-organisation") ? 4 : 12,
      distance: parseFloat(searchData.distance.replace("km", "")),
      country_id : JSON.parse(searchData.countryId)
    };
    if (pathName.includes("/view-organisation")) {
      data.agency_id = OrgID.id;
    }

    dispatch(actionLatestPropertyListingHomeApiCall(data));
    dispatch(actionGetPropertyAllDataApiCall(data));
  };
  const searchHandler = (e) => {
    dispatch(actionSearchStatus("enter"));
    e.preventDefault();

    dispatch(
      actionSaveSearchBackgroundImageUrl({
        id: searchData.address_data?.value?.id,
        name: searchData.address_data?.value?.name,
        type: searchData.address_data?.value?.type,
        image: searchData.address_data?.value?.image,
        city: searchData.address_data?.value?.city,
      })
    );

    dispatch(actionSavePropertyType(""));
    if (searchData.maxPrice !== "" && searchData.minPrice !== "") {
      if (parseInt(searchData.maxPrice) <= parseInt(searchData.minPrice)) {
        toast.error("Maximum price should be greater than minimum price");
      } else {
        const queryParams = [
          params.get("page") ? `page=${1}` : `page=${1}`,
          searchData.address_data?.value?.id || searchData?.region_id
            ? `region_id=${state?.saveSearchBackground?.id}`
            : "",
          searchData.address_data?.value?.type || searchData?.region
            ? `region=${state?.saveSearchBackground?.type}`
            : "",
          searchData.address_data?.value?.name ||
          state?.saveSearchBackground?.name
            ? `address=${
                searchData.address_data?.value?.name
                  ? searchData.address_data?.value?.name
                  : state?.saveSearchBackground?.name
              }`
            : "",
          searchData.distance ? `distance=${searchData.distance}` : "",
          searchData.minPrice ? `minPrice=${searchData.minPrice}` : "",
          searchData.maxPrice ? `maxPrice=${searchData.maxPrice}` : "",
          params.get("listingType") || savePropertyType
            ? `listingType=${
                params.get("listingType")
                  ? params.get("listingType")
                  : savePropertyType
                  ? savePropertyType
                  : ""
              }`
            : "",
          params.get("energyClass")
            ? `energyClass=${encodeURIComponent(params.get("energyClass"))}`
            : "",
          params.get("bedroom") ? `bedroom=${params.get("bedroom")}` : "",
          params.get("sqaureMeter")
            ? `sqaureMeter=${params.get("sqaureMeter")}`
            : "",
          params.get("status") ? `status=${params.get("status")}` : "",
          params.get("description")
            ? `description=${params.get("description")}`
            : "",
          params.get("days") ? `days=${params.get("days")}` : "",
          params.get("propertyType")
            ? `propertyType=${params.get("propertyType")}`
            : "",
          params.get("constructionYear")
            ? `constructionYear=${params.get("constructionYear")}`
            : "",
          `countryId=${searchData.countryId || params.get("countryId") || props.country || "1"}`
        ];
        const queryString = queryParams.filter(Boolean).join("&");
        pathName.includes("/view-organisation")
          ? navigate(
              `/view-organisation/${OrgID.city}/${OrgID.orgname}/${OrgID.id}?${queryString}`
            )
          : navigate(`/search?${queryString}`);
        searchFilterData();
      }
    } else {
      const queryParams = [
        params.get("page") ? `page=${1}` : `page=${1}`,
        searchData.address_data?.value?.name ||
        state?.saveSearchBackground?.name
          ? `address=${
              searchData.address_data?.value?.name
                ? searchData.address_data?.value?.name
                : state?.saveSearchBackground?.name
            }`
          : "",
        searchData.distance ? `distance=${searchData.distance}` : "",
        searchData.minPrice ? `minPrice=${searchData.minPrice}` : "",
        searchData.maxPrice ? `maxPrice=${searchData.maxPrice}` : "",
        params.get("listingType") || savePropertyType
          ? `listingType=${
              params.get("listingType")
                ? params.get("listingType")
                : savePropertyType
                ? savePropertyType
                : ""
            }`
          : "",
        params.get("energyClass")
          ? `energyClass=${encodeURIComponent(params.get("energyClass"))}`
          : "",
        params.get("bedroom") ? `bedroom=${params.get("bedroom")}` : "",
        params.get("sqaureMeter")
          ? `sqaureMeter=${params.get("sqaureMeter")}`
          : "",
        params.get("status") ? `status=${params.get("status")}` : "",
        params.get("description")
          ? `description=${params.get("description")}`
          : "",
        params.get("days") ? `days=${params.get("days")}` : "",
        params.get("propertyType")
          ? `propertyType=${params.get("propertyType")}`
          : "",
        params.get("constructionYear")
          ? `constructionYear=${params.get("constructionYear")}`
          : "",
        `countryId=${searchData.countryId || params.get("countryId") || props.country || "1"}`
      ];
      const queryString = queryParams.filter(Boolean).join("&");
      pathName.includes("/view-organisation")
        ? navigate(
            `/view-organisation/${OrgID.city}/${OrgID.orgname}/${OrgID.id}?${queryString}`
          )
        : navigate(`/search?${queryString}`);
      searchFilterData();
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchHandler(event);
    }
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  
  return (
    <div className={classes.BoxMain}>
       <div className={`${classes.MainWrap} ${isHomePage ? classes.isHomePage : ''}`}>
        <div className={classes.LeftSide}>
          <div className={classes.SearchBox}>
            {/* <input
              type="search"
              placeholder={t("area_neighbourhood_address_etc")}
              className={classes.searchInput}
              style={{ background: backgroundColor }}
              name="address"
              value={searchData.address}
              onKeyDown={handleKeyPress}
              onChange={handleChange}
            /> */}
            <Select
              className="basic-single"
              classNamePrefix="select"
              styles={customStyles}
              placeholder={screenWidth > 768 ? t("area_neighbourhood_address_etc") : "Area, Neighbour.." }
              defaultValue={addressSearch}
              value={addressSearch}
              onFocus={() => dispatch(actionSearchStatus(""))}
              style={{ background: backgroundColor }}
              isClearable={false}
              isSearchable={true}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              name="address_data"
              onInputChange={(data) => {
                setAddressSearch(data);
                dispatch(actionSearchStatus(""));
              }}
              options={
                state?.saveCityInfoData && state?.saveCityInfoData?.length > 0
                  ? state?.saveCityInfoData?.map((p) => {
                      return {
                        label:
                          p.type == "NEIGHBOURHOOD" || p.type == "DISTRICT"
                            ? `${p.name}(${t(p.type)}, ${p?.city?.name})`
                            : `${p.name}(${t(p.type)})`,
                        value: {
                          id: p.id,
                          type: p.type,
                          image: p.slider_image,
                          name: p.name,
                          city: p.city ? p.city : null,
                        },
                      };
                    })
                  : []
              }
              onChange={(option) => {
                setSearchData({
                  ...searchData,
                  address_data: option,
                });

                setAddressSearch(option);
                dispatch(
                  actionSaveSearchBackgroundImageUrl({
                    id: option?.value?.id,
                    name: option?.value?.name,
                    type: option?.value?.type,
                    image: option?.value?.image,
                    city: option?.value?.city,
                  })
                );
              }}
            />
            <select
              id="distance"
              name="distance"
              className={classes.selectHomeBgColor}
              value={searchData.distance}
              onChange={handleChange}
              style={{ background: backgroundColor }}
            >
              {array.map((item, index) => {
                return <option value={item}>+ {item}</option>;
              })}
            </select>
          </div>
          {/* <div className={classes.LatestSearch}>
            <span>{t("latest_search")}</span>
            <p>
              {state?.saveSearchBackground?.name
                ? state?.saveSearchBackground?.name
                : searchData.address_data?.value?.name
                ? searchData.address_data?.value?.name
                : ""}{" "}
              {searchData.minPrice ? "," + "€" + searchData.minPrice : ""}
              {searchData.maxPrice ? "-" + "€" + searchData.maxPrice : ""}
              {searchData.maxPrice ||
              searchData.minPrice ||
              searchData.address ? (
                <span>&gt;</span>
              ) : null}
            </p>
          </div> */}
        </div>
        <div className={classes.RigthSide}>
          {/* {(pathName == "/home" || pathName == "/") && (
            <div className={classes.PriceRangeHome}>
              <p>{t("price_range")}</p>
            </div>
          )} */}
          <div className={classes.PriceRangeBox}>
            <div className={classes.PriceRangeWrap}>
              <div className={classes.inputBox}>
                <input
                  type="number"
                  placeholder={t("from_minprice")}
                  className={classes.searchInput}
                  style={{ background: backgroundColor }}
                  min="1"
                  name="minPrice"
                  value={searchData.minPrice}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.inputBox}>
                <input
                  type="number"
                  placeholder={t("till_no_max")}
                  className={classes.searchInput}
                  style={{ background: backgroundColor }}
                  min="1"
                  name="maxPrice"
                  value={searchData.maxPrice}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={classes.inputBox}>
              <button onClick={searchHandler}>{t("Search") + "!"}</button>
              <img src={SearchIocn} alt="search" />
            </div>
          </div>
          {!(pathName === "/home" || pathName === "/" || pathName.startsWith("/home/")) && (
            <div className={classes.PriceRange}>
              <p>{t("price_range")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PropartySearchBar;

