import React, { useEffect, useState } from "react";
import classes from "./filterssection.module.scss";
import { useTranslation } from "react-i18next";
import {
  TYPE_OF_PROPERTY,
  DAYS,
  SQUARE_METERS,
  NO_OF_BEDROOMS,
  ENERGY_LABEL,
} from "../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddSavedFilterApiCall,
  actionLatestPropertyListingHomeApiCall,
  actionSavePropertyType,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import fromDateMap from "../../../utils/fromDateMap";
import Openarrow from "../../../assets/icons/Openarrow.svg";
import Closearrow from "../../../assets/icons/Closearrow.svg";

const FiltersSection = (props) => {
  const { t } = useTranslation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state_id = localStorage.getItem("user_id");
  const savePropertyType = useSelector(
    (state) => state.CommonReducer.savePropertyType
  );
  const state = useSelector((state) => state.CommonReducer);

  const [filterData, setFilterData] = useState({
    typeOfListing: params.get("listingType")
      ? params.get("listingType")
      : savePropertyType
      ? savePropertyType
      : "",
    daysOnWoning: params.get("days") ? params.get("days") : "",
    status: params.get("status") ? params.get("status") : "",
    plotArea: params.get("sqaureMeter") ? params.get("sqaureMeter") : "",
    noOfBedroom: params.get("bedroom") ? params.get("bedroom") : "",
    energyClass: params.get("energyClass") ? params.get("energyClass") : "",
    searchDescription: params.get("description")
      ? params.get("description")
      : "",
    countryID : params.get("countryId") ?  params.get("countryId") : 1 
  });
  const [saveFilterGetData, setSaveFilterGetData] = useState({
    propertyType: params.get("propertyType") ? params.get("propertyType") : "",
    constructionYear: params.get("constructionYear")
      ? params.get("constructionYear")
      : "",
  });
  const [filterCount, setFilterCount] = useState();
  // const [isFilterWrapCollapsed, setIsFilterWrapCollapsed] = useState(true);
  // const [isDaysFilterWrapCollapsed, setIsDaysFilterWrapCollapsed] =
  //   useState(true);
  const [isFilterWrapCollapsed, setIsFilterWrapCollapsed] = useState({
    openFilter1: false,
    openFilter2: false,
    openFilter3: false,
    openFilter4: false,
    openFilter5: false,
  });
  const today = moment().format("YYYY-MM-DD");
  useEffect(() => {
    FilterDataHandler();
    setFilterCount(Object.values(filterData).filter((val) => val != "").length);
  }, [props.currentPage, filterData]);
  function handleOptionChange(event) {
    props.changeToDefaultPage();
    switch (event.target.name) {
      case "TypeOfListing":
        dispatch(actionSavePropertyType(event.target.value));
        return setFilterData({
          ...filterData,
          typeOfListing:
            filterData.typeOfListing == event.target.value
              ? ""
              : event.target.value,
        });
      case "Days":
        return setFilterData({
          ...filterData,
          daysOnWoning:
            filterData.daysOnWoning == event.target.value
              ? ""
              : event.target.value,
        });
      case "Status":
        return setFilterData({
          ...filterData,
          status:
            filterData.status === event.target.value ? "" : event.target.value,
        });
      case "Meters":
        return setFilterData({
          ...filterData,
          plotArea:
            filterData.plotArea === event.target.value
              ? ""
              : event.target.value,
        });
      case "NumberOfBedroom":
        return setFilterData({
          ...filterData,
          noOfBedroom:
            filterData.noOfBedroom === event.target.value
              ? ""
              : event.target.value,
        });
      case "EnergyLabel":
        return setFilterData({
          ...filterData,
          energyClass:
            filterData.energyClass === event.target.value
              ? ""
              : event.target.value,
        });
      case "SearchDescription":
        return setFilterData({
          ...filterData,
          searchDescription: event.target.value,
        });
      default:
        return;
    }
  }
  let Address = params.get("address");
  let Distance = params.get("distance");
  let minPrice = params.get("minPrice");
  let maxPrice = params.get("maxPrice");
  const FilterDataHandler = async () => {
    let data = {
      page: props.currentPage,
      per_page: 12,
      area: Address && Address,
      region: state?.saveSearchBackground?.type,
      region_id: state?.saveSearchBackground?.id,
      distance: Distance && parseFloat(Distance.replace("km", "")),
      minPrice: minPrice,
      maxPrice: maxPrice,
      listingType: filterData.typeOfListing,
      energyClass: filterData.energyClass,
      description: filterData.searchDescription,
      bedroom: filterData.noOfBedroom,
      sqaureMeter: filterData.plotArea,
      status: filterData.status,
      fromdate:
        filterData.daysOnWoning && fromDateMap[`${filterData.daysOnWoning}`],
      todate:
        filterData.daysOnWoning != "" &&
        filterData.daysOnWoning !== "No preference" &&
        filterData.daysOnWoning !== "Geen voorkeur" &&
        today,
      user_id: state_id,
      property_type: saveFilterGetData.propertyType,
      construction_year: saveFilterGetData.constructionYear,
      country_id: filterData.countryID
    };
    dispatch(actionLatestPropertyListingHomeApiCall(data));
    const queryParams = [
      `page=${props.currentPage}`,
      Address ? `address=${Address}` : "",
      Distance ? `distance=${Distance}` : "",
      minPrice ? `minPrice=${minPrice}` : "",
      maxPrice ? `maxPrice=${maxPrice}` : "",
      filterData.typeOfListing ? `listingType=${filterData.typeOfListing}` : "",
      filterData.energyClass
        ? `energyClass=${encodeURIComponent(filterData.energyClass)}`
        : "",
      filterData.noOfBedroom ? `bedroom=${filterData.noOfBedroom}` : "",
      filterData.plotArea ? `sqaureMeter=${filterData.plotArea}` : "",
      filterData.status ? `status=${filterData.status}` : "",
      filterData.searchDescription
        ? `description=${filterData.searchDescription}`
        : "",
      filterData.daysOnWoning ? `days=${filterData.daysOnWoning}` : "",
      saveFilterGetData.propertyType
        ? `propertyType=${saveFilterGetData.propertyType}`
        : "",
      saveFilterGetData.constructionYear
        ? `constructionYear=${saveFilterGetData.constructionYear}`
        : "",
    ];
    const queryString = queryParams.filter(Boolean).join("&");
    navigate(`/search?${queryString}`);
  };
  const SaveFilterHandler = () => {
    let isSell;
    let isRent;
    if (filterData.typeOfListing === "sell") {
      isSell = 1;
      isRent = 0;
    } else if (filterData.typeOfListing === "rent") {
      isSell = 0;
      isRent = 1;
    } else if (filterData.typeOfListing === "both") {
      isSell = 1;
      isRent = 1;
    }
    let data = {
      user_id: state_id,
      energy_class: filterData.energyClass,
      is_sell: isSell,
      is_rent: isRent,
      price_from: minPrice,
      price_to: maxPrice,
      description: filterData.searchDescription,
      bedroom: filterData.noOfBedroom,
      sqaureMeter: filterData.plotArea,
      status: filterData.status,
      fromdate:
        filterData.daysOnWoning && fromDateMap[`${filterData.daysOnWoning}`],
      todate: filterData.daysOnWoning && today,
    };
    const body = { data, navigate };
    dispatch(actionAddSavedFilterApiCall(body));
  };
  const ClearFilterHandler = () => {
    setFilterData({
      typeOfListing: "",
      daysOnWoning: "",
      status: "",
      plotArea: "",
      noOfBedroom: "",
      energyClass: "",
      searchDescription: "",
    });
  };
  const toggleFilterWrap = (open) => {
    setIsFilterWrapCollapsed({
      ...isFilterWrapCollapsed,
      [open]: !isFilterWrapCollapsed[open],
    });
  };
  return (
    <React.Fragment>
      <div className={classes.FiltersSectionMain}>
        <div className={classes.FilterContainer}>
          <div className={classes.FilterCount}>
            <div className={classes.NumberOfFilters}>
              <p>
                <span>{filterCount}</span>
                {t("filters")}
              </p>
              <p onClick={ClearFilterHandler} className={classes.ClearFilter}>
                {t("clear_filters")}
              </p>
            </div>
            <button onClick={SaveFilterHandler}>{t("Save filter")}</button>
            <button onClick={props.handleClose} className={classes.FilterButton}>{t("Filter")}</button>
          </div>
          <div className={classes.FilterWrap}>
            <div>
              <h5 onClick={() => toggleFilterWrap("openFilter1")}>
                {t("type_of_listing")}
              </h5>
              {isFilterWrapCollapsed.openFilter1 &&
                TYPE_OF_PROPERTY.map((item, index) => {
                  return (
                    <>
                      <div className={classes.inputbox}>
                        <input
                          type="radio"
                          id={item.id}
                          name="TypeOfListing"
                          className={classes.radioCustom}
                          value={item.value}
                          checked={filterData.typeOfListing === item.value}
                          onClick={handleOptionChange}
                        />
                        <label
                          className={classes.radioCustomLabel}
                          htmlFor={item.id}
                        >
                          <span>{" " + t(item.name)}</span>
                        </label>
                      </div>
                    </>
                  );
                })}
            </div>
            <div
              className={classes.arrowWrap}
              onClick={() => toggleFilterWrap("openFilter1")}
            >
              <img
                src={isFilterWrapCollapsed.openFilter1 ? Openarrow : Closearrow}
              />
            </div>
          </div>
          <div className={classes.FilterWrap}>
            <div>
              <h5 onClick={() => toggleFilterWrap("openFilter2")}>
                {t("days_on_woningmarkt")}
              </h5>
              {isFilterWrapCollapsed.openFilter2 &&
                DAYS.map((item, index) => {
                  return (
                    <div className={classes.inputbox}>
                      <input
                        type="radio"
                        id={item.id}
                        name="Days"
                        className={classes.radioCustom}
                        value={t(item.name)}
                        checked={filterData.daysOnWoning === t(item.name)}
                        onClick={handleOptionChange}
                      />
                      <label
                        className={classes.radioCustomLabel}
                        htmlFor={item.id}
                      >
                        <span>{" " + t(item.name)}</span>
                      </label>
                    </div>
                  );
                })}
            </div>
            <div
              className={classes.arrowWrap}
              onClick={() => toggleFilterWrap("openFilter2")}
            >
              <img
                src={isFilterWrapCollapsed.openFilter2 ? Openarrow : Closearrow}
              />
            </div>
          </div>
          <div className={classes.FilterWrap}>
            <div>
              <h5 onClick={() => toggleFilterWrap("openFilter3")}>
                {t("square_meters")}
              </h5>
              {isFilterWrapCollapsed.openFilter3 &&
                SQUARE_METERS.map((item, index) => {
                  return (
                    <div className={classes.inputbox}>
                      <input
                        type="radio"
                        id={item.id}
                        name="Meters"
                        className={classes.radioCustom}
                        value={item.name}
                        checked={filterData.plotArea === item.name}
                        onClick={handleOptionChange}
                      />
                      <label
                        className={classes.radioCustomLabel}
                        htmlFor={item.id}
                      >
                        <span>{` ${item.name} m² +`}</span>
                      </label>
                    </div>
                  );
                })}
            </div>
            <div
              className={classes.arrowWrap}
              onClick={() => toggleFilterWrap("openFilter3")}
            >
              <img
                src={isFilterWrapCollapsed.openFilter3 ? Openarrow : Closearrow}
              />
            </div>
          </div>
          <div className={classes.FilterWrap}>
            <div>
              <h5 onClick={() => toggleFilterWrap("openFilter4")}>
                {t("number_of_bedrooms")}
              </h5>
              {isFilterWrapCollapsed.openFilter4 &&
                NO_OF_BEDROOMS.map((item, index) => {
                  return (
                    <div className={classes.inputbox}>
                      <input
                        type="radio"
                        id={item.id}
                        name="NumberOfBedroom"
                        className={classes.radioCustom}
                        value={item.name}
                        checked={filterData.noOfBedroom === item.name}
                        onClick={handleOptionChange}
                      />
                      <label
                        className={classes.radioCustomLabel}
                        htmlFor={item.id}
                      >
                        <span>{" " + item.name} +</span>
                      </label>
                    </div>
                  );
                })}
            </div>
            <div
              className={classes.arrowWrap}
              onClick={() => toggleFilterWrap("openFilter4")}
            >
              <img
                src={isFilterWrapCollapsed.openFilter4 ? Openarrow : Closearrow}
              />
            </div>
          </div>
          <div className={classes.FilterWrap}>
            <div>
              <h5 onClick={() => toggleFilterWrap("openFilter5")}>
                {t("energy_label")}
              </h5>
              {isFilterWrapCollapsed.openFilter5 &&
                ENERGY_LABEL.map((item, index) => {
                  return (
                    <div className={classes.inputbox}>
                      <input
                        type="radio"
                        id={item.id}
                        name="EnergyLabel"
                        className={classes.radioCustom}
                        value={item.name}
                        checked={filterData.energyClass === item.name}
                        onClick={handleOptionChange}
                      />
                      <label
                        className={classes.radioCustomLabel}
                        htmlFor={item.id}
                      >
                        <span>{" " + item.name}</span>
                      </label>
                    </div>
                  );
                })}
            </div>
            <div
              className={classes.arrowWrap}
              onClick={() => toggleFilterWrap("openFilter5")}
            >
              <img
                src={isFilterWrapCollapsed.openFilter5 ? Openarrow : Closearrow}
              />
            </div>
          </div>
          <div className={classes.searchDescription}>
            <h5>{t("search_in_description")}</h5>
            <input
              type="search"
              placeholder={t("keywords")}
              name="SearchDescription"
              value={filterData.searchDescription}
              onChange={handleOptionChange}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FiltersSection;
