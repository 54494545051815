import React from "react";
import AddMagazineMain from "../../components/AddMagazineMain";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";

const AddMagazinePage = () => {
  return (
    <React.Fragment>
      <Header />
      <AddMagazineMain />
      <Footer />
    </React.Fragment>
  );
};

export default AddMagazinePage;
