import React from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import ViewAllBlogsMain from "../../components/ViewAllBlogsMain";

const ViewAllBlogsPage = () => {
  return (
    <React.Fragment>
      <Header />
      <ViewAllBlogsMain/>
      <Footer />
    </React.Fragment>
  );
};

export default ViewAllBlogsPage;
