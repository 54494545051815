import axios from "axios";
import APIStrapiUrl from "./APIStrapiUrl";
class APIStrapi {
  instance;
  constructor() {
    this.instance = axios.create({
      baseURL: APIStrapiUrl.backendUrl,
    });
  }

  APICALL(methods, url, body) {
    try {
      let response;
      if (methods == "post") {
        response = this.instance.post(
          `${APIStrapiUrl.backendUrl}/${url}`,
          body
        );
      } else if (methods == "get") {
        response = this.instance.get(`${APIStrapiUrl.backendUrl}/${url}`);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
}
const apiStrapiInstance = new APIStrapi();

export const HomeStrapiApiCall = async (selectedLanguage, title) => {
  return await apiStrapiInstance.APICALL(
    "get",
    `${title}?locale=${selectedLanguage}&populate=*`
  );
};

export const FooterStrapiApiCall = async (selectedLanguage, title) => {
  return await apiStrapiInstance.APICALL(
    "get",
    `menus?locale=${selectedLanguage}&filters[Position][$eq]=${title}&sort=Order&populate[Items][populate]=*`
  );
};

export const GetSlugDataStrapiApiCall = async (selectedLanguage, slug) => {
  return await apiStrapiInstance.APICALL(
    "get",
    `cms-pages/${slug}?locale=${selectedLanguage}&populate[Banner]=*&populate[Section][populate][0]=Items.Icon&populate[Section][populate][1]=Image`
  );
};
export const GetLatestBlogsApiCall = async (country, title, lang) => {
  const titleFilter = title ? `&filters[Title][$startsWith]=${title}` : '';

  return await apiStrapiInstance.APICALL(
    "get",
    `blogs?locale=${lang}&populate=*&filters[Country][$eq]=${country}${titleFilter}`
  );
}

export const GetSingleBlogApiCall = async (blogId) => {
  return await apiStrapiInstance.APICALL(
    "get",
    `blogs/${blogId}?populate=*`
  )
}
export default apiStrapiInstance;
