import React from "react";
export const DropDownSelector = React.forwardRef((props, ref) => {
  return (
    <>
      <div>
        <p>{props.heading}</p>
        <select
          type="text"
          name={props.name}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className={props.className ? props.className : ""}
          ref={ref}
          style={{ width: props.width }}
        >
          <option disabled value="">
            {typeof props.heading === "undefined" || props.heading === ""
              ? props.placeholder
              : props.heading}
          </option>
          {props?.data?.length > 0 &&
            props.data.map((item, index) => {
              return (
                <option
                  key={index}
                  value={
                    props.name === "city" || props.name === "ad_kind"
                      ? item.id
                      : item.name
                  }
                >
                  {item.name
                    ? item.name
                    : item.translation
                    ? item.translation
                    : "Empty Value"}
                </option>
              );
            })}
        </select>
      </div>
    </>
  );
});
