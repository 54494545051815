// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nodatafoundmain_NoDataFound__iVVEo {
  text-align: center;
  padding-top: 42px;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/NoDataFound/nodatafoundmain.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;AACF","sourcesContent":[".NoDataFound {\n  text-align: center;\n  padding-top: 42px;\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NoDataFound": `nodatafoundmain_NoDataFound__iVVEo`
};
export default ___CSS_LOADER_EXPORT___;
