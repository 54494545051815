import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyMagazineForm from "../layouts/MyMagazineForm";
import classes from "./editmagazinemain.module.scss";

const EditMagazineMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.EditBannerContainer}>
          <div className={classes.EditBannerHeading}>
            <h1>{t("edit_magazine")}</h1>
          </div>
          <MyMagazineForm />
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default EditMagazineMain;
