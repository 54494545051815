import React from "react";
import classes from "./accountmain.module.scss";
import ConnectionsIcon from "../../../assets/icons/ConnectionIcon.svg";
import ConnectionsIconPink from "../../../assets/icons/ConnectionLogo(Pink).svg";
import ChartIcon from "../../../assets/icons/Chart.svg";
import ChartIconPink from "../../../assets/icons/ChartPink.svg";
import Flag from "../../../assets/icons/Flag.svg";
import MagazineIcon from "../../../assets/icons/Magazine.svg";
import MagazinePinkIcon from "../../../assets/icons/MagazinePink.svg";
import { useDispatch, useSelector } from "react-redux";
import { actionLogoutApiCall, actionResetState } from "../../../Redux/Actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const AccountMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );
  const loginInformation = useSelector(
    (state) => state.CommonReducer.loginInfo
  );
  const logoutHandler = () => {
    let body = { navigate };
    dispatch(actionLogoutApiCall(body));
    dispatch(actionResetState());
  };

  const platform = localStorage.getItem("Platform");

  return (
    <div className={classes.AccountSection}>
      <h2>{t("my_account")}</h2>
      <div className={classes.LinkWrap}>
        <ul>
          <li
            className={
              location.pathname === "/change-account-info" ? classes.active : ""
            }
            onClick={() => navigate("/change-account-info")}
          >
            {t("account_information")}
          </li>
          <li
            className={
              location.pathname === "/favourites" ? classes.active : ""
            }
            onClick={() => navigate("/favourites")}
          >
            {t("favourites")}
          </li>
          <li
            className={
              location.pathname === "/my-listing" ? classes.active : ""
            }
            onClick={() => navigate("/my-listing")}
          >
            {t("my_account_listings")}
          </li>
          <li
            className={
              location.pathname === "/saved-filter" ? classes.active : ""
            }
            onClick={() => navigate("/saved-filter")}
          >
            {t("saved_filter")}
          </li>
          <li
            className={
              location.pathname === "/my-organisations" ? classes.active : ""
            }
            onClick={() => navigate("/my-organisations")}
          >
            {t("my_organisations")}
          </li>
          <li
            className={
              location.pathname === "/connections" ? classes.active : ""
            }
            onClick={() => navigate("/connections")}
          >
            <img
              src={
                location.pathname === "/connections"
                  ? ConnectionsIconPink
                  : ConnectionsIcon
              }
              alt=""
            />
            {t("connections")}
          </li>
          <li
            className={
              location.pathname === "/my-banners" ? classes.active : ""
            }
            onClick={() => navigate("/my-banners")}
          >
            <img
              src={
                location.pathname === "/my-banners" ? ChartIconPink : ChartIcon
              }
              alt=""
            />
            {t("my_account_banners")}
          </li>
          <li
            className={
              location.pathname.indexOf("/my-magazines") >= 0
                ? classes.active
                : ""
            }
            onClick={() => navigate("/my-magazines")}
          >
            <img
              src={
                location.pathname.indexOf("/my-magazines") >= 0
                  ? MagazinePinkIcon
                  : MagazineIcon
              }
              height={20}
              alt=""
            />
            {t("my_magazines")}
          </li>
          {(platform === "DEV" ||
            platform === "UAT" ||
            platform === "PROD" ||
            loginInformation.user_type === "Admin") && (
            <li
              className={
                location.pathname === "/my-invoices" ? classes.active : ""
              }
              onClick={() => navigate("/my-invoices")}
            >
              {t("my_invoices")}
            </li>
          )}
          <li onClick={logoutHandler}>{t("logout")}</li>
          <li>
            <img
              src={
                selectedLanguage?.flag_image
                  ? selectedLanguage.flag_image
                  : Flag
              }
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountMain;
