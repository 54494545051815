import React, { useState } from "react";
import MainContainer from "../../layouts/MainContainer";
import PropartySearchBar from "../../layouts/PropartySearchBar";
import classes from "./searchmain.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionSavePropertyType } from "../../../Redux/Actions";
import searchBanner from "../../../assets/images/searchBanner.png";
import defaultImage from "../../../assets/images/defaultImage.png";
import { useEffect } from "react";

const SearchMain = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [propertyType, setPropertyType] = useState("both");
  // const [searchBackgroudImage, setSearchBackgroundImage] = useState();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.CommonReducer);

  // useEffect(() => {
  //   if (state?.saveSearchBackground) {
  //     setSearchBackgroundImage(state?.saveSearchBackground?.image);
  //   }
  // }, [state?.saveSearchBackground]);
  return (
    <div
      className={classes.PropartySearchContainer}
      style={{
        backgroundImage: `url(${
          state?.saveSearchBackground?.image ? state?.saveSearchBackground?.image : ""
        })`,
        // backgroundImage: `url("https://woningmarkt.s3.amazonaws.com/uploads/development/slider_image/JA/zxsw_1700041651992_240084175.jpeg")`,
      }}
    >
      <div className={classes.SearchSection}>
        <MainContainer>
          <div className={classes.SearchWrap}>
            <div className={classes.ButtonWrap}>
              <button
                className={
                  propertyType === "sell"
                    ? classes.BtnItemActive
                    : classes.BtnItem
                }
                onClick={() => {
                  setPropertyType("sell");
                  dispatch(actionSavePropertyType("sell"));
                }}
              >
                {t("buy")}
              </button>
              <button
                className={
                  propertyType === "rent"
                    ? classes.BtnItemActive
                    : classes.BtnItem
                }
                onClick={() => {
                  setPropertyType("rent");
                  dispatch(actionSavePropertyType("rent"));
                }}
              >
                {t("rent")}
              </button>
              <button
                className={
                  propertyType === "both"
                    ? classes.BtnItemActive
                    : classes.BtnItem
                }
                onClick={() => {
                  setPropertyType("both");
                  dispatch(actionSavePropertyType("both"));
                }}
              >
                {t("both")}
              </button>
            </div>
            <div>
              <PropartySearchBar
                // HandleImageChange={HandleImageChange}
                page={params.get("page") ? params.get("page") : 1}
                address={params.get("address") && params.get("address")}
                distance={params.get("distance") && params.get("distance")}
                minPrice={params.get("minPrice") && params.get("minPrice")}
                maxPrice={params.get("maxPrice") && params.get("maxPrice")}
                listingType={
                  params.get("listingType") && params.get("listingType")
                }
                energyClass={
                  params.get("energyClass") && params.get("energyClass")
                }
                bedroom={params.get("bedroom") && params.get("bedroom")}
                sqaureMeter={
                  params.get("sqaureMeter") && params.get("sqaureMeter")
                }
                status={params.get("status") && params.get("status")}
                description={
                  params.get("description") && params.get("description")
                }
                days={params.get("days") && params.get("days")}
                propertyType={
                  params.get("propertyType") && params.get("propertyType")
                }
                constructionYear={
                  params.get("constructionYear") &&
                  params.get("constructionYear")
                }
                countryID={
                  params.get("countryId") &&
                  params.get("countryId")
                }
              />
            </div>
          </div>
        </MainContainer>
      </div>
    </div>
  );
};

export default SearchMain;
