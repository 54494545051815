import React, { useEffect, useState } from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import AdsMain from "../../components/HomeComponents/AdsMain";
import "./abroadpage.scss";
import MainContainer from "../../components/layouts/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllCountriesApiCall, actionGetCountryImageInfo } from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const AbroadPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const selectedLanguage = useSelector(
        (state) => state.CommonReducer.selectedLanguage
    );
    const dynamicBannerforabroad = useSelector(
        (state) => state.CommonReducer.saveCountryImageInfoData
      );
    const navigate = useNavigate();

    // Use optional chaining and provide a default empty array
    const rows = useSelector(
        (state) => state.CommonReducer.saveGetAllCountries?.rows || []
    );
    
    // Use optional chaining for loading state
    const loading = useSelector(
        (state) => state.CommonReducer.loading?.loading || false
    );
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionGetAllCountriesApiCall());
    }, [selectedLanguage,dispatch]);

    useEffect(() => {
        const filtered = searchQuery.trim() === ""
            ? rows
            : rows.filter((country) =>
                country?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        
        setFilteredRows(filtered || []);
    }, [searchQuery, rows]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const wrapper = document.querySelector(".destinations");
        if (!wrapper) return;

        const columns = filteredRows.length < 2 ? 1 : filteredRows.length < 3 ? 2 : 3;
        wrapper.classList.toggle("single-card", filteredRows.length === 1);
        wrapper.style.setProperty(
            "--grid-template-columns",
            `repeat(${columns}, 1fr)`
        );
    }, [filteredRows?.length]);

    const handleCardClick = (countryName, countryId) => {
        navigate(`/home/${countryName}?countryId=${countryId}`);
    };
    useEffect(() => {
        dispatch(
          actionGetCountryImageInfo({
            navigate,
            search:"Nederland",
            countryId: 1
          })
        );
      }, []);
    return (
        <React.Fragment>
            <Header />
            {loading ? (
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            ) : (
                <div className="dream-homes">
                    <div className="hero">
                        <div className="hero-content">
                            <h1>Altijd al gedroomd van wonen in het buitenland?</h1>
                            <p className="hero-subtext">Vind het op woningmarkt!</p>
                            <div className="hero-search-wrapper">
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder={t("Land") + " " + t("Search") + "..."}
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                    {/* <button>Zoeken!</button> */}
                                    <button>{t("Search")}!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MainContainer>
                        <div className="cards-wrapper">
                            <div className="destinations">
                                {filteredRows.length === 0 ? (
                                    <div className="no-data-message">No Data!</div>
                                ) : (
                                    filteredRows?.map((destination) => (
                                        <div 
                                            key={destination?.id} 
                                            onClick={() => handleCardClick(destination?.name, destination?.id)} 
                                            className="destination-card"
                                        >
                                            <div className="card-image">
                                                <img
                                                    src={destination?.slider_image}
                                                    alt={destination?.name}
                                                />
                                            </div>
                                            <div className="card-content">
                                                <h3>{destination?.name}</h3>
                                                <button className="more-info">
                                                    <svg viewBox="0 0 24 24" width="24" height="24">
                                                        <path
                                                            d="M9 18l6-6-6-6"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </MainContainer>
                </div>
            )}
            <AdsMain dynamicBannerforabroad={dynamicBannerforabroad} />
            <Footer />
        </React.Fragment>
    );
};

export default AbroadPage;