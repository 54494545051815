import React, { useState } from "react";
import classes from "./regioncardselectable.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../NoDataFound";
import { REGION_RADIUSES, SUPPLIER_BUDGETS } from "../../../Constants";
import SupplierCardSelectable from "../SupplierCardSelectable";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@awesome.me/kit-0ea32a987c/icons";

const RegionCardSelectable = ({
  regions,
  changeRadius,
  handleDeleteRegion,
  suppliers,
  handleChangeSupplier,
  handleChangeBudget,
}) => {
  const location = useLocation().pathname;
  const platform = localStorage.getItem("Platform");
  const { t } = useTranslation();

  const handleEditRegion = (event, region) => {
    event.preventDefault();
    console.log("Edit region", region);
    let editRegion = document.getElementById(`edit_${region.id}`);
    let nonEditRegion = document.getElementById(`non_edit_${region.id}`);
    let editIcon = document.getElementById(`editIcon_${region.id}`);
    let saveIcon = document.getElementById(`saveIcon_${region.id}`);
    if (editRegion.style.display === "none") {
      editRegion.style.display = "block";
      nonEditRegion.style.display = "none";
      editIcon.style.display = "none";
      saveIcon.style.display = "block";
    } else {
      editRegion.style.display = "none";
      nonEditRegion.style.display = "block";
      editIcon.style.display = "block";
      saveIcon.style.display = "none";
    }
  };

  return (
    <React.Fragment>
      <div className={classes.RegionListingWrap}>
        <div className={classes.RegionScrollContainer}>
          {regions && regions.length > 0
            ? regions.map((item, index) => (
                <div key={`region_${index}`} className={classes.selectedRegion}>
                  <div className={classes.regionTitle}>
                    <FontAwesomeIcon
                      id={`editIcon_${item.id}`}
                      icon={far.faEdit}
                      onClick={(event) => {
                        handleEditRegion(event, item);
                      }}
                      style={{ cursor: "pointer" }}
                    />{" "}
                    <FontAwesomeIcon
                      id={`saveIcon_${item.id}`}
                      icon={far.faFileCheck}
                      onClick={(event) => {
                        handleEditRegion(event, item);
                      }}
                      style={{ cursor: "pointer", display: "none" }}
                    />{" "}
                    <FontAwesomeIcon
                      icon={far.faTrash}
                      title={t("icons_delete_region")}
                      onClick={(event) => {
                        handleDeleteRegion(event, item.id);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    {"  "}
                    {item.name}{" "}
                    <span id={`non_edit_${item.id}`}>
                      (straal: {item.radius} km; budget: &euro; {item.budget},-)
                    </span>
                    <span id={`edit_${item.id}`} style={{ display: "none" }}>
                      <select
                        id={`radius_${item.id}`}
                        name={`radius_${item.id}`}
                        defaultValue={item.radius}
                        onChange={(event) => {
                          changeRadius(event);
                        }}
                      >
                        {REGION_RADIUSES.map((option) => (
                          <option key={option.id} value={option?.value}>
                            {option?.name}
                          </option>
                        ))}
                      </select>
                      {"  "}
                      <select
                        id={`region_budget_${item.id}`}
                        name={`region_budget_${item.id}`}
                        defaultValue={index === 0 ? 50 : item.budget}
                        onChange={(event) => {
                          handleChangeBudget(event, item);
                        }}
                      >
                        {SUPPLIER_BUDGETS.map((option) => (
                          <option key={option.id} value={option?.value}>
                            {option?.name}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>

                  {/* <div className={classes.InputFieldBoxWithMargin}>
                    
                  </div>
                  <div className={classes.InputFieldBoxWithMargin}>
                  </div> */}
                  <div className={classes.RegionCard}>
                    {suppliers && (
                      <SupplierCardSelectable
                        region={item.id}
                        suppliers={suppliers[item.id]}
                        changeSupplier={handleChangeSupplier}
                      />
                    )}
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegionCardSelectable;
