import React, { useEffect, useState } from "react";
import LatestListingsMain from "../../components/HomeComponents/LatestListingsMain";
import NeedHelpMain from "../../components/HomeComponents/NeedHelpMain";
import PropartySearchMain from "../../components/HomeComponents/PropartySearchMain";
import OrganisationHighlightMain from "../../components/HomeComponents/OrganisationHighlightMain";
import AdsMain from "../../components/HomeComponents/AdsMain";
import Footer from "../../components/layouts/Footer/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetAgencyBannerRecommendationsApiCall,
  actionGetCountryImageInfo,
  actionGetHomeBannerApiCall,
  actionHomeStrapiApiCall,
  actionSaveSearchBackgroundImageUrl,
  actionSaveSelctedLanguage,
} from "../../Redux/Actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languages = useSelector((state) => state.CommonReducer.languages);
  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );
  const { country } = useParams();
  console.log(country,"countryyy")
  const [searchParams] = useSearchParams();
  const [countryIdState, setCountryIdState] = useState(searchParams.get('countryId'));
  const countryId = searchParams.get('countryId');
  const dynamicBanner = useSelector(
    (state) => state.CommonReducer.saveCountryImageInfoData
  );
  const currentPath = window.location.pathname;
  const isRootOrHome = currentPath === "/" || currentPath === "/home";
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actionGetHomeBannerApiCall({ navigate }));
    dispatch(actionHomeStrapiApiCall({ navigate, title: "home-pages" }));
    dispatch(actionSaveSearchBackgroundImageUrl({}));
  }, []);

  useEffect(() => {
    let data = {
      bannerSizes: [
        { "Home Ad 1": "260*208" },
        { "Home Ad 2": "260*208" },
        { "Home Ad 3": "260*208" },
        { "Home Ad 4": "260*208" },
        { "Home Left": "613*415" },
        { "Home Right": "613*415" },
        { "Home Middle": "1306*442" },
      ],
      country_id: window.location.pathname === '/' || window.location.pathname === '/home' 
        ? 1 
        : JSON.parse(searchParams.get('countryId') )
    };

    if (data.country_id) {
      let body = {
        navigate,
        data,
      };
      dispatch(actionGetAgencyBannerRecommendationsApiCall(body));
    }
  }, [countryId]);

  useEffect(() => {
    if (languages) {
      if (!selectedLanguage?.language) {
        dispatch(
          actionSaveSelctedLanguage(languages?.find((l) => l.language == "nl"))
        );
      }
    }
  }, [languages]);
  return (
    <React.Fragment>
      <PropartySearchMain country={country} />
      <LatestListingsMain country={country} countryId={countryIdState} dynamicBanner={dynamicBanner} />
      <NeedHelpMain />
      <OrganisationHighlightMain />
      <AdsMain isRootOrHome={isRootOrHome} country={country} dynamicBanner={dynamicBanner} />
      <Footer />
    </React.Fragment>
  );
};

export default HomePage;
