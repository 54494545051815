import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "./mymagazineform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetMagazineApiCall,
  actionModifyPlacementApiCall,
  actionModifyMagazineTextApiCall,
} from "../../../Redux/Actions";
import PriceFormatter from "../../../utils/PriceFormatter";
import { DateTime } from "luxon";
import { DropDownSelector } from "../../DropDownSelector";
import {
  GetSummaryApiCall,
  MyListingApiCall,
  FillSummariesApiCall,
} from "../../../API/API";
import Loader from "../../Loader";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WordCount from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far, fat, fal, fab, fas } from "@awesome.me/kit-0ea32a987c/icons";

const MagazineForm = () => {
  const stripHTML = (htmlString) => {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract text content
    const textContent = doc.body.textContent || "";
    // Trim whitespace
    return textContent.trim();
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();
  let editorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const ITEMS_PER_PAGE = 20;
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [defaultText, setDefaultText] = useState("");
  const [defaultTextId, setDefaultTextId] = useState();
  const [defaultItem, setDefaultItem] = useState();
  const [maxChars, setMaxChars] = useState(100);
  const [isLimitExceeded, setIsLimitExceeded] = useState(
    stripHTML(defaultText)?.length > maxChars
  );
  const [isLimitClose, setIsLimitClose] = useState(
    !isLimitExceeded && stripHTML(defaultText)?.length > maxChars * 0.8
  );
  const [search, setSearch] = useState();

  const [mTexts, setMTexts] = useState([]);

  const urlArr = window.location.pathname.split("/");

  const id = urlArr[2];

  const magazineData = useSelector(
    (state) => state.CommonReducer.saveMagazineData
  );

  console.log("magazineData", magazineData);

  const name = magazineData?.name;
  const date = DateTime.fromISO(magazineData?.publication_date).toFormat(
    "dd-LL-yyyy"
  );
  const deadline = DateTime.fromISO(magazineData?.deadline).toFormat(
    "dd-LL-yyyy HH:mm"
  );
  const productionDate = DateTime.fromISO(
    magazineData?.production_date
  ).toFormat("dd-LL-yyyy");
  const pages = magazineData?.magazine_pages?.length;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    magazineData?.magazine_placements
      ? magazineData?.magazine_placements.map((item) => item.property_id)
      : []
  );
  const [selectedTypes, setSelectedTypes] = useState({});
  const [agencyIds, setAgencyIds] = useState(
    magazineData?.magazine_type?.magazine_members
      ? magazineData?.magazine_type?.magazine_members.map(
          (item) => item.agency_id
        )
      : []
  );
  const [myPropertyListingData, setMyPropertyListingData] = useState([]);

  const handleCheckboxChange = (event) => {
    console.log("handleCheckboxChange", event);
    const value = event.target.value;
    const typeInput = document.getElementById(`ak_${value}`);
    let data = {};
    if (event.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, parseInt(value)]);
      let tmpTypes = selectedTypes;
      if (typeof tmpTypes[parseInt(value)] === "undefined") {
        tmpTypes[parseInt(value)] = typeInput.options[1]?.value;
        setSelectedTypes(tmpTypes);
      }
      data = {
        checked: 1,
        property_id: value,
        ad_kind_id: typeInput.value,
        magazine_id: magazineData?.id,
      };
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== parseInt(value))
      );
      data = {
        checked: 0,
        property_id: value,
        ad_kind_id: typeInput.value,
        magazine_id: magazineData?.id,
      };
    }
    dispatch(actionModifyPlacementApiCall(data));
    console.log("data checkbox", data);
  };

  const onChangeHandler = (event) => {
    console.log(event.target.selectedOptions[0].value);
    setSelectedCheckboxes(
      selectedCheckboxes.filter(
        (item) => item !== parseInt(`${event.target.id.replace("ak_", "")}`)
      )
    );
    setSelectedCheckboxes([
      ...selectedCheckboxes,
      parseInt(`${event.target.id.replace("ak_", "")}`),
    ]);
    let tmpTypes = selectedTypes;
    tmpTypes[event.target.id.replace("ak_", "")] = parseInt(
      event.target.selectedOptions[0].value
    );
    console.log("tmpTypes", tmpTypes);
    setSelectedTypes(tmpTypes);
    const data = {
      checked: 1,
      property_id: event.target.id.replace("ak_", ""),
      ad_kind_id: event.target.selectedOptions[0].value,
      magazine_id: magazineData?.id,
    };
    dispatch(actionModifyPlacementApiCall(data));
    console.log("data", data);
    console.log("selectedTypes", selectedTypes);
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
    if (id && typeof id !== "undefined") {
      let body = { id: id };
      dispatch(actionGetMagazineApiCall(body));
      let tmpTypes = selectedTypes;
      for (let i = 0; i < magazineData?.magazine_placements?.length; i++) {
        tmpTypes[magazineData?.magazine_placements[i].property_id] =
          magazineData?.magazine_placements[i].ad_kind_id;
      }
      setSelectedTypes(tmpTypes);
      setSelectedCheckboxes(
        magazineData?.magazine_placements
          ? magazineData?.magazine_placements.map((item) => item.property_id)
          : []
      );
      if (myPropertyListingData?.length === 0) {
        handlePageChange(currentPage);
      }
    }
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count, currentPage, dispatch, id, navigate, selectedTypes]);

  const handlePageChange = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
    const body = {
      page: pageNumber,
      per_page: ITEMS_PER_PAGE,
      search: search,
    };
    const properties = await MyListingApiCall(body);
    setMyPropertyListingData(properties.data.data.propertyData);
    setLoading(false);
  };
  const pagination = () => {
    let ui = [];
    for (
      let i = activePage <= 4 ? 1 : activePage - 4;
      i <= (activePage <= 4 ? 8 : activePage + 4);
      i++
    ) {
      if (i <= pageNumber?.length) {
        ui.push(
          <div key={i} className={classes.page}>
            <button
              onClick={() => {
                handlePageChange(i);
              }}
              className={[
                classes.pagination_button,
                activePage === i ? classes.active : classes.in_active,
              ].join(" ")}
            >
              {i}
            </button>
          </div>
        );
      }
    }
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
    return ui;
  };
  const changeToDefaultPage = () => {
    setCurrentPage(1);
    setActivePage(1);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = () => {
    const text = editorRef.editor.getData();
    let data = {
      magazine_id: magazineData.id,
      property_id: defaultItem.id,
      ad_kind_id: selectedTypes[defaultItem.id],
      description: text,
    };
    if (typeof selectedTypes[defaultItem.id] === "undefined") {
      const adKinds = magazineData?.magazine_type?.magazine_ad_kinds;
      for (let i = 1; i < adKinds?.length; i++) {
        data.ad_kind_id = adKinds[i].id;
        dispatch(actionModifyMagazineTextApiCall(data));
      }
      data.ad_kind_id = adKinds[0].id;
    }
    console.log("data", data);
    dispatch(actionModifyMagazineTextApiCall(data));
    setShow(false);
  };

  const handleReset = async () => {
    const text = defaultItem.description;
    setDefaultText(text);
    editorRef.editor.setData(text);
  };

  const handleFillSummaries = async () => {
    const body = {
      magazine_id: magazineData.id,
    };
    const response = await FillSummariesApiCall(body);
    console.log("response", response);
    if (response.status === 200) {
      alert("Samenvattingen zijn gevuld");
    }
  };

  const editorConfig = {
    // licenseKey:
    //   "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NzA4NTQzOTksImp0aSI6ImZmNTYwOTNlLTkzMmItNGMwMy1hY2I3LTk3Y2YxYzdhNzNkYyIsImxpY2Vuc2VkSG9zdHMiOlsiMTI3LjAuMC4xIiwibG9jYWxob3N0IiwiMTkyLjE2OC4qLioiLCIxMC4qLiouKiIsIjE3Mi4qLiouKiIsIioudGVzdCIsIioubG9jYWxob3N0IiwiKi5sb2NhbCJdLCJ1c2FnZUVuZHBvaW50IjoiaHR0cHM6Ly9wcm94eS1ldmVudC5ja2VkaXRvci5jb20iLCJkaXN0cmlidXRpb25DaGFubmVsIjpbImNsb3VkIiwiZHJ1cGFsIl0sImxpY2Vuc2VUeXBlIjoiZGV2ZWxvcG1lbnQiLCJmZWF0dXJlcyI6WyJEUlVQIl0sInZjIjoiMDA1OThlYzgifQ.fvldaAi44hALgxZjqY2PLgD7rvC1l9R5Jy8zXlNW8XF6awqtveuUrETuAu0m2W8UcHklvCwg66EXfBy2xZsK3w",
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "numberedList",
        "bulletedList",
        "|",
        "undo",
        "redo",
      ],
      styles: {
        height: "500px",
      },
    },
    language: "nl",
    extraPlugins: [WordCount],
    wordCount: {
      onUpdate: (stats) => {
        // Prints the current content statistics.
        console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`);
      },
    },
  };

  const getClassName = (item) => {
    if (selectedCheckboxes.includes(item?.id)) {
      const selectedType = parseInt(selectedTypes[item?.id]);
      const ad_kind = magazineData?.magazine_type.magazine_ad_kinds.filter(
        (e) => e.id === selectedType
      )[0];
      console.log(magazineData?.magazine_placements);
      const properties = magazineData?.magazine_placements.filter(
        (mp) => mp.property?.id === item.id
      );
      if (properties && properties?.length > 0) {
        const property = properties[0].property;
        if (stripHTML(getText(property))?.length > ad_kind?.max_text_length) {
          return classes.PropertyRowOverflow;
        }
      }
      console.log(properties);
      return classes.PropertyRowActive;
    }
    return classes.PropertyRow;
  };

  const getText = (property) => {
    console.log("property", property);
    setDefaultItem(property);
    const placements = magazineData?.magazine_placements.filter(
      (item) => item.property_id === property.id
    );
    console.log("placements", placements);
    if (placements && placements?.length > 0) {
      const ad_kind = placements[0].ad_kind;
      console.log("ad_kind", ad_kind);
      setMaxChars(ad_kind.max_text_length);
      const pProperty = placements[0].property;
      console.log("pProperty", pProperty);
      const text =
        pProperty.property_magazine_texts &&
        pProperty.property_magazine_texts?.length > 0
          ? pProperty.property_magazine_texts.filter(
              (item) => item.ad_kind_id === ad_kind.id
            )[0].description
          : property.description;
      mTexts[property.id] = text;
      return text;
    } else {
      if (typeof mTexts[property.id] !== "undefined") {
        return mTexts[property.id];
      } else {
        mTexts[property.id] = property.description;
        return property.description;
      }
    }
  };
  const getTextId = (property) => {
    const placements = magazineData?.magazine_placements.filter(
      (item) => item.property_id === property.id
    );
    if (placements && placements?.length > 0) {
      const ad_kind = placements[0].ad_kind;
      const pProperty = placements[0].property;
      const mText = pProperty.property_magazine_texts.filter(
        (item) => item.ad_kind_id === ad_kind.id
      )[0];
      return mText?.id;
    }
  };

  const handleSummary = async () => {
    document.getElementById("summaryButton").disabled = true;
    const text = editorRef.editor.getData();
    const summary = stripHTML(text);
    const body = {
      text: summary,
      language: "Dutch",
      maxChars: maxChars,
    };
    const response = await GetSummaryApiCall(body);
    console.log("response", response.data.content);
    editorRef.editor.setData(response.data.content);
    document.getElementById("summaryButton").disabled = false;
  };

  return (
    <React.Fragment>
      <section className={classes.magazinetable}>
        <div className={classes.container}>
          <h2>{magazineData.name}</h2>
          <div className={[classes.info, classes.flex].join(" ")}>
            <div className={classes.data}>
              <span>Publicatiedatum</span>
              <span>{date}</span>
            </div>
            <div className={classes.data}>
              <span>Aanleverdeadline</span>
              <span>{deadline}</span>
            </div>
            <div className={classes.fill_summaries}>
              <button
                onClick={handleFillSummaries}
                className={classes.pinkbutton}
              >
                Samenvattingen vullen
              </button>
            </div>
            <div className={classes.search}>
              <input
                type="text"
                id="search-input"
                placeholder="Zoeken..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  handlePageChange(currentPage);
                }}
              />
              <button className={classes.search_button} id="search-button">
                <FontAwesomeIcon icon={fas.faSearch} />
              </button>
            </div>
          </div>
          <div className={classes.table} id="grid-container">
            {loading === true ? (
              <div className={classes.LoaderWrap}>
                <Loader />
              </div>
            ) : myPropertyListingData?.rows &&
              myPropertyListingData?.rows?.length > 0 ? (
              myPropertyListingData?.rows?.map((item, index) => (
                <div
                  className={[classes.grid_row, classes.item].join(" ")}
                  key={index}
                >
                  <div className={classes.checkbox_dropdown}>
                    <input
                      type="checkbox"
                      className={classes.domain_checkbox}
                      id={item.id}
                      name={item.name}
                      value={item.id}
                      onChange={handleCheckboxChange}
                      checked={magazineData?.magazine_placements
                        ?.map((item) => item.property_id)
                        .includes(item?.id)}
                    />
                    <DropDownSelector
                      width="160px"
                      name="ad_kind"
                      placeholder={t("choose_ad_kind")}
                      id={`ak_${item.id}`}
                      value={
                        selectedTypes[item.id] !== ""
                          ? selectedTypes[item.id]
                          : 0
                      }
                      onChange={onChangeHandler}
                      data={magazineData?.magazine_type?.magazine_ad_kinds?.map(
                        (kind) => {
                          return {
                            id: kind.id,
                            value: kind.id,
                            translation: kind.name,
                          };
                        }
                      )}
                      className={classes.dropdown}
                    />
                  </div>
                  <span className={classes.address}>{item.address}</span>
                  <span className={classes.city}>
                    {item.city
                      .split(" ")
                      .map(
                        (w) => w[0].toUpperCase() + w.substring(1).toLowerCase()
                      )
                      .join(" ")}
                  </span>
                  <span className={classes.price}>
                    {item.is_sell === 1 && (
                      <div>
                        {`€ ${PriceFormatter(item.sell_price, 2).replace(
                          ",00",
                          ",-"
                        )}`}{" "}
                        {t(item.sell_postfix)}
                      </div>
                    )}
                    {item.is_rent === 1 && (
                      <div>
                        {`€ ${PriceFormatter(item.rent_price, 2).replace(
                          ",00",
                          ",-"
                        )}`}{" "}
                        {t(item.rent_postfix)}
                      </div>
                    )}
                  </span>
                  <div className={classes.icons}>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        alert("Geen idee wat hierachter zou moeten komen?");
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={far.faEye} />
                    </a>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        const mText = getText(item);
                        const mTextId = getTextId(item);
                        setDefaultItem(item);
                        setDefaultTextId(mTextId);
                        setDefaultText(mText);
                        const l = stripHTML(mText)?.length;
                        setIsLimitExceeded(l > maxChars);
                        setIsLimitClose(l <= maxChars && l > maxChars * 0.8);
                        setShow(true);
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={far.faEdit} />
                    </a>
                  </div>
                </div>
              ))
            ) : (
              ""
            )}
          </div>
          <div className={classes.pagination} id="pagination">
            <div className={classes.pagination_numbers}>
              {pageNumber?.length >= 1 && (
                <span className={classes.pageText}>{t("page")}</span>
              )}
              {pageNumber?.length > 0 && pagination()}
              {pageNumber?.length > 8 && (
                <div className={classes.page}>
                  {" "}
                  <span className={classes.pageText}>{t("of")}</span>
                  <button
                    onClick={() => {
                      handlePageChange(pageNumber?.length);
                    }}
                    className={[
                      classes.pagination_button,
                      activePage === pageNumber?.length
                        ? classes.active
                        : classes.in_active,
                    ].join(" ")}
                  >
                    {pageNumber?.length}
                  </button>
                </div>
              )}
            </div>
            <div className={classes.pagination_buttons}>
              {currentPage > 1 && currentPage <= totalPages && (
                <a
                  href="#"
                  className={[classes.button, classes.vorige_pagina].join(" ")}
                  style={{ display: `none` }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (activePage !== 1) {
                      setCurrentPage(currentPage - 1);
                      setActivePage(currentPage - 1);
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  Vorige pagina
                </a>
              )}
              {currentPage < totalPages && (
                <a
                  href="#"
                  className={[classes.button, classes.volgende_pagina].join(
                    " "
                  )}
                  style={{ display: `inline-block` }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (activePage !== pageNumber?.length) {
                      setCurrentPage(+currentPage + 1);
                      setActivePage(+currentPage + 1);
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  {t("next_page")}
                </a>
              )}
            </div>
          </div>
          <button
            onClick={(e) => (window.location = "/my-magazines")}
            className={classes.pinkbutton}
          >
            Opslaan!
          </button>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Magazine tekst bewerken</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.DemoUpdate} id="DemoUpdate">
            {editorLoaded ? (
              <CKEditor
                ref={(el) => (editorRef = el)}
                name="description"
                editor={ClassicEditor}
                config={editorConfig}
                onReady={(editor) => {
                  const charactersBox = document.getElementById(
                    "DemoUpdateChartCharacters"
                  );
                  charactersBox.textContent = isLimitExceeded
                    ? `-${stripHTML(defaultText)?.length - maxChars}`
                    : stripHTML(defaultText)?.length;
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log(data?.length, data);
                  const container = document.getElementById("DemoUpdate");
                  const progressCircle = document.getElementById(
                    "DemoUpdateChartCircle"
                  );
                  const charactersBox = document.getElementById(
                    "DemoUpdateChartCharacters"
                  );
                  const circleCircumference = Math.floor(
                    2 * Math.PI * progressCircle.getAttribute("r")
                  );
                  const l = stripHTML(data)?.length;
                  const charactersProgress =
                    (l / maxChars) * circleCircumference;
                  setIsLimitExceeded(l > maxChars);
                  setIsLimitClose(l <= maxChars && l > maxChars * 0.8);
                  const circleDashArray = Math.min(
                    charactersProgress,
                    circleCircumference
                  );

                  // Set the stroke of the circle to show how many characters were typed.
                  progressCircle.setAttribute(
                    "stroke-dasharray",
                    `${circleDashArray},${circleCircumference}`
                  );

                  // Display the number of characters in the progress chart. When the limit is exceeded,
                  // display how many characters should be removed.
                  if (l > maxChars) {
                    charactersBox.textContent = `-${
                      stripHTML(data)?.length - maxChars
                    }`;
                  } else {
                    charactersBox.textContent = stripHTML(data)?.length;
                  }
                }}
                data={defaultText || ""}
              />
            ) : (
              ""
            )}
            <div className={classes.DemoUpdateControls} id="DemoUpdateControls">
              <svg
                id="DemoUdateChart"
                className={classes.DemoUdateChart}
                viewBox="0 0 40 40"
                width="40"
                height="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  stroke="hsl(0, 0%, 93%)"
                  stroke-width="3"
                  fill="none"
                  cx="20"
                  cy="20"
                  r="17"
                ></circle>
                <circle
                  id="DemoUpdateChartCircle"
                  className={
                    isLimitExceeded
                      ? classes.DemoUpdateChartCircleLimitExceeded
                      : isLimitClose
                      ? classes.DemoUpdateChartCircleLimitClose
                      : classes.DemoUpdateChartCircle
                  }
                  stroke="hsl(202, 92%, 59%)"
                  stroke-width="3"
                  stroke-dasharray="106,106"
                  stroke-linecap="round"
                  fill="none"
                  cx="20"
                  cy="20"
                  r="17"
                ></circle>
                <text
                  id="DemoUpdateChartCharacters"
                  className={classes.DemoUpdateChartCharacters}
                  x="50%"
                  y="50%"
                  dominant-baseline="central"
                  text-anchor="middle"
                ></text>
              </svg>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReset}>
            Tekst opnieuw instellen
          </Button>
          <Button
            variant="secondary"
            onClick={handleSummary}
            id="summaryButton"
          >
            Samenvatting
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Opslaan
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default MagazineForm;
