import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyMagazineForm from "../layouts/MyMagazineForm";
import classes from "./addmagazine.module.scss";

const AddMagazineMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.AddBannerContainer}>
          <div className={classes.AddBannerHeading}>
            <h1>{t("add_magazine_title")}</h1>
          </div>
          <MyMagazineForm />
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default AddMagazineMain;
