import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./mymagazinesmain.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionGetMagazinesApiCall } from "../../Redux/Actions";
import { DateTime } from "luxon";

const MyMagazinesMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user_id = localStorage.getItem("user_id");

  const magazinesData = useSelector(
    (state) => state.CommonReducer.saveMagazinesData
  );
  useEffect(() => {
    let body = { id: user_id };
    dispatch(actionGetMagazinesApiCall(body));
  }, [dispatch, user_id]);

  return (
    <React.Fragment>
      <MainLayout>
        <div className={classes.ConnectionContainer}>
          <div className={classes.TitleWrap}>
            <h1>{t("my_magazines")}</h1>
          </div>
          <div>
            {magazinesData?.length > 0 &&
              magazinesData?.map((item, index) => {
                return (
                  <>
                    {index === 0 && (
                      <div key={"titel"} className={classes.MagazineContainer}>
                        <div>
                          <p>{t("magazine_id")}</p>
                        </div>
                        <div>
                          <p>{t("magazine_name")}</p>
                        </div>
                        <div>
                          <p>{t("magazine_date")}</p>
                        </div>
                        <div>
                          <p>{t("magazine_deadline")}</p>
                        </div>
                        <div>
                          <p>{t("magazine_placements")}</p>
                        </div>
                        <div className={classes.IconWrapper}>&nbsp;</div>
                      </div>
                    )}
                    <div key={item.id} className={classes.MagazineContainer}>
                      <div>
                        <p>{item.id}</p>
                      </div>
                      <div>
                        <p>
                          <a href={`/my-magazines/${item.id}/edit`}>
                            {item.name}
                          </a>
                        </p>
                      </div>
                      <div>
                        <p>
                          {item.publication_date === null
                            ? t("magazine_unknown_date")
                            : DateTime.fromISO(item.publication_date).toFormat(
                                "dd-LL-yyyy"
                              )}
                        </p>
                      </div>
                      <div>
                        <p>
                          {item.deadline === null
                            ? t("magazine_unknown_date")
                            : DateTime.fromISO(item.deadline).toFormat(
                                "dd-LL-yyyy HH:mm"
                              )}
                        </p>
                      </div>
                      <div>
                        <p>{item.magazine_placements.length}</p>
                      </div>
                      <div className={classes.IconWrapper}></div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className={classes.IconWrapper}></div>
        </div>
      </MainLayout>
    </React.Fragment>
  );
};

export default MyMagazinesMain;
