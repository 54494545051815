import React, { useState } from "react";
import MainContainer from "../../layouts/MainContainer";
import PropartySearchBar from "../../layouts/PropartySearchBar";
import classes from "./searchmainwithoutbg.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionSavePropertyType } from "../../../Redux/Actions";
import searchBanner from "../../../assets/images/searchBanner.png";
import defaultImage from "../../../assets/images/defaultImage.png";

const SearchMainWithoutBg = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [propertyType, setPropertyType] = useState("both");
  const [searchBackgroudImage, setSearchBackgroundImage] = useState();
  const dispatch = useDispatch();

  const HandleImageChange = (data) => {
    setSearchBackgroundImage(data?.value?.image);
  };
  return (
    <div className={classes.SearchSection}>
      <MainContainer>
        <PropartySearchBar
          HandleImageChange={HandleImageChange}
          page={params.get("page") ? params.get("page") : 1}
          address={params.get("address") && params.get("address")}
          distance={params.get("distance") && params.get("distance")}
          minPrice={params.get("minPrice") && params.get("minPrice")}
          maxPrice={params.get("maxPrice") && params.get("maxPrice")}
          listingType={params.get("listingType") && params.get("listingType")}
          energyClass={params.get("energyClass") && params.get("energyClass")}
          bedroom={params.get("bedroom") && params.get("bedroom")}
          sqaureMeter={params.get("sqaureMeter") && params.get("sqaureMeter")}
          status={params.get("status") && params.get("status")}
          description={params.get("description") && params.get("description")}
          days={params.get("days") && params.get("days")}
          propertyType={
            params.get("propertyType") && params.get("propertyType")
          }
          constructionYear={
            params.get("constructionYear") && params.get("constructionYear")
          }
          countryID={params.get("countryID") && params.get("countryID")}
        />
      </MainContainer>
    </div>
  );
};

export default SearchMainWithoutBg;
