import React from "react";
import Header from "../../layouts/Header/Index";
import PropartySearchBar from "../../layouts/PropartySearchBar";
import classes from "./propartysearchmain.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MainContainer from "../../layouts/MainContainer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetCountryImageInfo,
  actionMolliePaymentApiCall,
  actionSavePropertyType,
} from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";

const PropartySearchMain = ({ country,CountryId }) => {
  console.log("the countryId in PropartySearchMain is",CountryId);
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(country, "countryryryry");
  const [propertyType, setPropertyType] = useState("both");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [homeImage, setHomeImage] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.CommonReducer.loading);
  const saveHomeBanner = useSelector(
    (state) => state.CommonReducer.saveHomeBanner
  );
  const dynamicBanner = useSelector(
    (state) => state.CommonReducer.saveCountryImageInfoData
  );
  const currentPath = window.location.pathname;
  const isRootOrHome = currentPath === "/" || currentPath === "/home";

  useEffect(() => {
    dispatch(
      actionGetCountryImageInfo({
        navigate,
        search: isRootOrHome ? "Nederland" : country,
        countryId: CountryId || 1
      })
    );
  }, [country]);

  // useEffect(() => {
  //   if (saveHomeBanner?.rows?.length > 0) {
  //     saveHomeBanner.rows.find((item) => {
  //       if (item.position === "Home Top") {
  //         const currentPath = window.location.pathname;
  //         const isSpecificHomePath = /^\/home\/[^/]+$/.test(currentPath);

  //         if (isSpecificHomePath) {
  //           setHomeImage(dynamicBanner?.map((item)=>item?.slider_image) || item.url_original_file);
  //         } else {
  //           setHomeImage(item.url_original_file);
  //         }
  //       }
  //     });
  //   }
  // }, [saveHomeBanner]);
  useEffect(() => {
    setHomeImage(dynamicBanner?.map((item) => item?.slider_image));
  }, [dynamicBanner]);
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    dispatch(actionSavePropertyType("both"));
  }, []);

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <>
      {loading === true ? (
        <div className={classes.LoaderMain}>
          <Loader />
        </div>
      ) : (
        <div
          className={classes.PropartySearchContainer}
          style={{
            "--home-image-url": `url(${homeImage})`,
          }}
        >
          <div className={classes.BackgroundShadow}>
            <Header />
          </div>
          <MainContainer>
            <div className={classes.mainsearchContainerwrapper}>
              <div className={classes.SearchContainerwrapper}>
                <div order="1" className={classes.SearchContainerdynamictexts}>
                  <p className={classes.SearchContainerdynamictextssubheading}>
                    {dynamicBanner?.map((item, index) => (
                      <span key={index}>
                        {truncateText(item?.sub_header, 23)}
                      </span>
                    ))}
                  </p>
                  <h3 className={classes.SearchContainerdynamictextsheading}>
                    {dynamicBanner?.map((item, index) => (
                      <span key={index}>{truncateText(item?.header, 36)}</span>
                    ))}
                  </h3>
                  <p className={classes.SearchContainerdynamictextsdetails}>
                    {screenWidth > 420 && (
                      <>
                        {dynamicBanner?.map((item, index) => (
                          <span key={index}>
                            {truncateText(item?.short_text, 147)}
                          </span>
                        ))}
                      </>
                    )}
                  </p>
                  <div className={classes.searchbuttonwrapper}>
                    {dynamicBanner?.map((item, index) => (
                      <button
                        key={`btn1-${index}`}
                        className={classes.searchbutton1}
                      >
                        <a
                          href={item?.button1_link || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncateText(item?.button1_text, 22)}
                        </a>
                      </button>
                    ))}
                    {dynamicBanner?.map((item, index) => (
                      <button
                        key={`btn2-${index}`}
                        className={classes.searchbutton2}
                      >
                        <a
                          href={item?.button2_link || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncateText(item?.button2_text, 22)}
                        </a>
                      </button>
                    ))}
                  </div>
                </div>
                <div order="2" className={classes.SearchSection}>
                  <div className={classes.SearchWrap}>
                    <div className={classes.ButtonWrap}>
                      <button
                        className={
                          propertyType === "sell"
                            ? classes.BtnItemActive
                            : classes.BtnItem
                        }
                        onClick={() => {
                          setPropertyType("sell");
                          dispatch(actionSavePropertyType("sell"));
                        }}
                      >
                        {t("buy")}
                      </button>
                      <button
                        className={
                          propertyType === "rent"
                            ? classes.BtnItemActive
                            : classes.BtnItem
                        }
                        onClick={() => {
                          setPropertyType("rent");
                          dispatch(actionSavePropertyType("rent"));
                        }}
                      >
                        {t("rent")}
                      </button>
                      <button
                        className={
                          propertyType === "both"
                            ? classes.BtnItemActive
                            : classes.BtnItem
                        }
                        onClick={() => {
                          setPropertyType("both");
                          dispatch(actionSavePropertyType("both"));
                        }}
                      >
                        {t("both")}
                      </button>
                    </div>
                    <div className={classes.propertyrelativewrapper}>
                    <div className={classes.SearchPropertyWrapper}>
                      <PropartySearchBar
                        country={country}
                        listingType={propertyType}
                        CountryId={CountryId}
                      />
                    </div>
                    </div>
                  </div>
                  <div className={classes.TextWrap}>
                    {/* {!localStorage.getItem("authToken") && (
              <p onClick={() => navigate("/create-organisation-account-step1")}>
                {t(
                  "want_to_start_selling_a_listing_start_by_creating_an_account_here"
                )}
              </p>
            )} */}
                  </div>
                </div>
              </div>
            </div>
          </MainContainer>
        </div>
      )}
    </>
  );
};
export default PropartySearchMain;
