import React, { useEffect, useState } from "react";
import classes from "./mylistinglayout.module.scss";
import { useTranslation } from "react-i18next";
import FileUpload from "../../../assets/icons/FileUploadIcon.svg";
import { ENERGY_LABEL } from "../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddPropertyApiCall,
  actionApprovedOrganisationsApiCall,
  actionGetApplicableOptionsApiCall,
  // actionGetCityListApiCall,
  actionGetCitiesApiCall,
  actionGetPropertyTypeApiCall,
  actionGetRentPostfixApiCall,
  actionGetRentPrefixApiCall,
  actionGetSellPostfixApiCall,
  actionGetSellPrefixApiCall,
  actionSaveMyOrganisationListing,
  actionViewSinglePropertyDetailsApiCall,
} from "../../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Validate from "../../../utils/Validation";
import { AiOutlineClose } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useRef } from "react";
import PdfIcon from "../../../assets/icons/PdfIcon.svg";
import VideoPlayIcon from "../../../assets/icons/VideoPlayIcon.svg";
const MyListingLayout = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [uploadImages, setUploadImages] = useState([]);
  const [listOfImages, setListOfImages] = useState([]);
  const [singleImages, setSingleImages] = useState(null);
  const [indexId, setIndexId] = useState(
    Object.keys(listOfImages).length > 0 ? 0 : ""
  );
  const state_id = localStorage.getItem("user_id");
  const organisationListData = useSelector(
    (state) => state.CommonReducer.approvedOrganisationData
  );
  const singlePropertyDetails = useSelector(
    (state) => state.CommonReducer.singlePropertyDetails
  );
  const loading = useSelector((state) => state.CommonReducer.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [removeImageIdsData, setRemoveImageIdsData] = useState([]);
  const [removePriceIdsData, setRemovePriceIdsData] = useState([]);
  const selectedLanguage = useSelector(
    (state) => state.CommonReducer.selectedLanguage
  );
  const stateData = useSelector((state) => state.CommonReducer);
  let savePropertyType = stateData.savePropertyTypeList;
  let saveRentPrefix = stateData.saveRentPrefix;
  let saveRentPostfix = stateData.saveRentPostfix;
  let saveSellPrefix = stateData.saveSellPrefix;
  let saveSellPostfix = stateData.saveSellPostfix;
  let saveApplicableOptions = stateData.saveApplicableOptions;
  let saveCities = stateData.saveCities;
  const inputRefs = useRef([]);
  const [uploadFloorPlan, setUploadFloorPlan] = useState([]);
  const [uploadFloorPlanFile, SetUploadFloorPlanFile] = useState([]);
  const [uploadbrochure, SetUploadBrochure] = useState([]);
  const [uploadbrochureFile, SetUploadBrochureFile] = useState([]);
  const [addPropertyData, setAddPropertyData] = useState({
    property_for: organisationListData?.length > 0 ? "" : "myself",
    address: "",
    postcode: "",
    city: "",
    propertyAvailableDate: "",
    bedrooms: 0,
    applicableOptions: [],
    propertyType: "",
    constructionYear: "",
    livableArea: "",
    homePlotArea: "",
    energyClass: "",
    quote: "",
    description: "",
    email: "",
    phoneno: "",
    property_type: "",
    agencyId: "",
    sell_price: "",
    sell_postfix: "",
    sell_prefix: "",
    rent_price: "",
    rent_postfix: "",
    rent_prefix: "",
  });
  const [addPropertyDataDescription, setAddPropertyDataDescription] =
    useState("");
  const [error, setError] = useState();
  const [previousFile, setPreviousFile] = useState(null);
  const [previousFloorPlanFile, setPreviousFloorPlanFile] = useState(null);
  const [previousBrochureFile, setPreviousBrochureFile] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    let body = { navigate };
    dispatch(actionApprovedOrganisationsApiCall(body));
    // dispatch(actionGetCityListApiCall({ navigate }));
  }, []);
  useEffect(() => {
    dispatch(actionGetApplicableOptionsApiCall({ navigate }));
    dispatch(actionGetCitiesApiCall({ navigate }));
    dispatch(actionGetPropertyTypeApiCall({ navigate }));
    dispatch(actionGetRentPrefixApiCall({ navigate }));
    dispatch(actionGetRentPostfixApiCall({ navigate }));
    dispatch(actionGetSellPrefixApiCall({ navigate }));
    dispatch(actionGetSellPostfixApiCall({ navigate }));
  }, [selectedLanguage]);
  useEffect(() => {
    if (params && params.id) {
      let data = {
        property_id: params.id,
      };
      let body = { data, navigate };
      dispatch(actionViewSinglePropertyDetailsApiCall(body));
    }
  }, [params]);
  useEffect(() => {
    if (params.id) {
      let uploadImageData;
      const singleData = {
        property_for: singlePropertyDetails.property_for,
        address: singlePropertyDetails.address,
        postcode: singlePropertyDetails.postcode,
        city: singlePropertyDetails.city_id,
        propertyAvailableDate: singlePropertyDetails.available_date,
        bedrooms: singlePropertyDetails.no_bedroom,
        propertyType:
          singlePropertyDetails.is_rent == 1 &&
          singlePropertyDetails.is_sell == 0
            ? "rent"
            : singlePropertyDetails.is_rent == 0 &&
              singlePropertyDetails.is_sell == 1
            ? "sell"
            : "both",
        constructionYear: singlePropertyDetails.construction_year,
        livableArea: singlePropertyDetails.livable_area,
        homePlotArea: singlePropertyDetails.home_plot_area,
        energyClass: singlePropertyDetails.energy_class,
        quote: singlePropertyDetails.quote,
        description: singlePropertyDetails.description,
        email: singlePropertyDetails.email,
        phoneno: singlePropertyDetails.phone,
        property_type: singlePropertyDetails.property_type,
        agencyId: singlePropertyDetails.agencyId,
        property_id: singlePropertyDetails.id,
        sell_price: singlePropertyDetails.sell_price
          ? singlePropertyDetails.sell_price
          : "",
        sell_postfix: singlePropertyDetails.sell_postfix
          ? singlePropertyDetails.sell_postfix
          : "",
        sell_prefix: singlePropertyDetails.sell_prefix
          ? singlePropertyDetails.sell_prefix
          : "",
        rent_price: singlePropertyDetails.rent_price
          ? singlePropertyDetails.rent_price
          : "",
        rent_postfix: singlePropertyDetails.rent_postfix
          ? singlePropertyDetails.rent_postfix
          : "",
        rent_prefix: singlePropertyDetails.rent_prefix
          ? singlePropertyDetails.rent_prefix
          : "",
      };
      setAddPropertyData(singleData);
      setAddPropertyDataDescription(singlePropertyDetails.description);
      setSelectedCheckboxes(
        singlePropertyDetails?.property_area_type?.map(
          (property) => property.applicable_options
        )
      );
      uploadImageData = singlePropertyDetails?.image_list
        ?.filter((val) => val.type == "VIDEO" || val.type == "PHOTO")
        ?.map((imageVal, index) => {
          return {
            previewUrls: imageVal.url_thumb_file,
            original_file_name: imageVal.original_file_name,
            id: imageVal.id,
            type: imageVal.type,
            sub_type: imageVal.sub_type,
          };
        });
      setUploadImages(uploadImageData);
      setSingleImages(
        singlePropertyDetails?.image_list &&
          singlePropertyDetails?.image_list?.filter(
            (val) =>
              val.type?.toLowerCase() != "video" &&
              val.type?.toLowerCase() != "FLOORPLAN" &&
              val.type?.toLowerCase() != "BROCHURE" &&
              val.sub_type === "MAIN_PHOTO"
          )[0]?.original_file_name
      );
      setIndexId(
        uploadImageData
          ?.filter((value) => !value.type?.toLowerCase()?.includes("video"))
          ?.findIndex((image) => image.sub_type === "MAIN_PHOTO")
      );
      setUploadFloorPlan(
        singlePropertyDetails?.image_list
          ?.filter((val) => val.type == "FLOORPLAN")
          ?.map((imageVal, index) => {
            return {
              previewUrls: imageVal.url_thumb_file,
              original_file_name: imageVal.original_file_name,
              id: imageVal.id,
            };
          })
      );
      SetUploadBrochure(
        singlePropertyDetails?.image_list
          ?.filter((val) => val.type == "BROCHURE")
          ?.map((imageVal, index) => {
            return {
              previewUrls: imageVal.url_thumb_file,
              original_file_name: imageVal.original_file_name,
              id: imageVal.id,
            };
          })
      );
    }
  }, [singlePropertyDetails]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddPropertyData({
      ...addPropertyData,
      [name]: value,
    });
    let error = Validate(name, value);
    setError(error);
  };
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value)
      );
    }
    let error = Validate("applicableOptions", value);
    setError(error);
  };
  const AddPropertyListingHandler = () => {
    let validateData = {
      property_for: addPropertyData.property_for,
      propertyType: addPropertyData.propertyType,
      address: addPropertyData.address,
      postcode: addPropertyData.postcode,
      city: addPropertyData.city,
      propertyAvailableDate: addPropertyData.propertyAvailableDate,
      bedrooms: addPropertyData.bedrooms,
      // applicableOptions: selectedCheckboxes,
      property_type: addPropertyData.property_type,
      constructionYear: addPropertyData.constructionYear,
      livableArea: addPropertyData.livableArea,
      homePlotArea: addPropertyData.homePlotArea,
      // energyClass: addPropertyData.energyClass,
      // quote: addPropertyData.quote,
      description: addPropertyDataDescription,
      email: addPropertyData.email,
      phoneno: addPropertyData.phoneno,
      images: uploadImages,
      sell_price: addPropertyData.sell_price,
      sell_postfix: addPropertyData.sell_postfix,
      sell_prefix: addPropertyData.sell_prefix,
      rent_price: addPropertyData.rent_price,
      rent_postfix: addPropertyData.rent_postfix,
      rent_prefix: addPropertyData.rent_prefix,
    };
    if (addPropertyData.propertyType == "sell") {
      for (let i in validateData) {
        if (i == "rent_price" || i == "rent_prefix" || i == "rent_postfix") {
          delete validateData[i];
        }
      }
    } else if (addPropertyData.propertyType == "rent") {
      for (let i in validateData) {
        if (i === "sell_price" || i == "sell_prefix" || i == "sell_postfix") {
          delete validateData[i];
        }
      }
    }

    let error = Validate("", "", true, validateData);
    setError(error);
    inputRefs.current.some((inputRef) => {
      if (Object.keys(error).includes(inputRef?.name)) {
        inputRef.focus();
        return true;
      }
      return false;
    });
    if (Object.values(error).every((x) => x == null || x == "")) {
      let formData = new FormData();
      for (let i of listOfImages) {
        formData.append("files", i);
      }
      for (let i of uploadFloorPlanFile) {
        formData.append("floorplan", i);
      }
      for (let i of uploadbrochureFile) {
        formData.append("brochure", i);
      }
      if (addPropertyData?.property_id) {
        formData.append("property_id", addPropertyData?.property_id);
      }
      formData.append(
        "main_img",
        singleImages
          ? singleImages
          : uploadImages.length > 0 &&
              uploadImages.filter(
                (val, index) => !val.type.includes("video")
              )[0].name
      );
      if (addPropertyData.propertyType === "sell") {
        formData.append("sell_price", addPropertyData.sell_price);
        formData.append("sell_prefix", addPropertyData.sell_prefix);
        formData.append("sell_postfix", addPropertyData.sell_postfix);
      }
      if (addPropertyData.propertyType === "rent") {
        formData.append("rent_price", addPropertyData.rent_price);
        formData.append("rent_prefix", addPropertyData.rent_prefix);
        formData.append("rent_postfix", addPropertyData.rent_postfix);
      }
      if (addPropertyData.propertyType === "both") {
        formData.append("sell_price", addPropertyData.sell_price);
        formData.append("sell_prefix", addPropertyData.sell_prefix);
        formData.append("sell_postfix", addPropertyData.sell_postfix);
        formData.append("rent_price", addPropertyData.rent_price);
        formData.append("rent_prefix", addPropertyData.rent_prefix);
        formData.append("rent_postfix", addPropertyData.rent_postfix);
      }
      formData.append("user_id", state_id);
      formData.append("property_for", addPropertyData.property_for);
      formData.append("address", addPropertyData.address);
      formData.append("postcode", addPropertyData.postcode);
      // formData.append("city", Number(addPropertyData.city));

      formData.append("city_id", Number(addPropertyData.city));
      formData.append("zipcode_id", 1);

      formData.append("available_date", addPropertyData.propertyAvailableDate);
      formData.append("no_bedroom", addPropertyData.bedrooms);
      formData.append("applicable_options", JSON.stringify(selectedCheckboxes));
      formData.append("property_type", addPropertyData.property_type);
      formData.append("construction_year", addPropertyData.constructionYear);
      formData.append("livable_area", addPropertyData.livableArea);
      formData.append("home_plot_area", addPropertyData.homePlotArea);
      formData.append("energy_class", addPropertyData.energyClass);
      formData.append("quote", addPropertyData.quote);
      formData.append("description", addPropertyDataDescription);
      formData.append("email", addPropertyData.email);
      formData.append("phone", addPropertyData.phoneno);
      if (addPropertyData.agencyId) {
        formData.append("agency_id", addPropertyData.agencyId);
      }
      formData.append(
        "is_rent",
        addPropertyData.propertyType === "rent"
          ? 1
          : addPropertyData.propertyType === "both"
          ? 1
          : 0
      );
      formData.append(
        "is_sell",
        addPropertyData.propertyType === "sell"
          ? 1
          : addPropertyData.propertyType === "both"
          ? 1
          : 0
      );
      let imageData = {
        user_id: state_id,
        property_id: addPropertyData?.property_id,
        image_id: removeImageIdsData,
      };
      let priceData = {
        user_id: state_id,
        property_id: addPropertyData?.property_id,
        price_id: removePriceIdsData,
      };
      let body = {
        formData,
        navigate,
        imageData,
        priceData,
      };
      dispatch(actionAddPropertyApiCall(body));
    }
  };
  const organisationClickHandler = () => {
    if (params.id) {
      setAddPropertyData((prevState) => ({
        ...prevState,
        property_for: "organisation",
      }));
      dispatch(
        actionSaveMyOrganisationListing(singlePropertyDetails.agency_detail)
      );
    } else {
      setAddPropertyData((prevState) => ({
        ...prevState,
        property_for: "organisation",
      }));
    }
  };
  const handleFileSelect = (event) => {
    const files = event.target.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = listOfImages.map((image) => image.name);
    let duplicateFound = false;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024 && file.type.includes("image")) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({
          previewUrls: reader.result,
          name: file.name,
          type: file.type,
        });
        setUploadImages([...uploadImages, ...previewUrls]);
      };
      if (file.type.includes("video")) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsDataURL(file);
      }
      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousFile(validFiles[validFiles.length - 1]);
    }
    setListOfImages([...listOfImages, ...validFiles]);
  };
  const handleFloorPlanFileSelect = (event) => {
    const files = event.target.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = uploadFloorPlanFile.map((image) => image.name);
    let duplicateFound = false;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024 && file.type.includes("image")) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({
          previewUrls: reader.result,
          name: file.name,
          type: file.type,
        });
        setUploadFloorPlan([...uploadFloorPlan, ...previewUrls]);
      };
      reader.readAsDataURL(file);
      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousFloorPlanFile(validFiles[validFiles.length - 1]);
    }
    SetUploadFloorPlanFile([...uploadFloorPlanFile, ...validFiles]);
  };
  const handleBrochureFileSelect = (event) => {
    const files = event.target.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = uploadbrochureFile.map((image) => image.name);
    let duplicateFound = false;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({
          previewUrls: reader.result,
          name: file.name,
          type: file.type,
        });
        SetUploadBrochure([...uploadbrochure, ...previewUrls]);
      };
      reader.readAsDataURL(file);
      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousBrochureFile(validFiles[validFiles.length - 1]);
    }
    SetUploadBrochureFile([...uploadbrochureFile, ...validFiles]);
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0") - 1;
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      event.preventDefault();
    }
  };
  const handleKeyPressForNumber = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const isCtrlV = (event.ctrlKey || event.metaKey) && charCode === 86; // Ctrl+V or Command+V

    if (
      !isCtrlV &&
      charCode !== 8 &&
      charCode !== 9 &&
      (charCode < 48 || charCode > 57)
    ) {
      event.preventDefault();
    }
  };
  const removeImageHandler = (id, imageUrl, title) => {
    if (title === "upload") {
      if (id) {
        setUploadImages((prevData) =>
          prevData.filter((item) => item.id !== id)
        );
        setRemoveImageIdsData((prevData) => [...prevData, id]);
      } else {
        let newData = uploadImages.filter((item) => item.name !== imageUrl);
        setUploadImages(newData);
        const filteredBinaryFiles = listOfImages?.filter(
          (file) => !file.name.includes(imageUrl)
        );
        setListOfImages(filteredBinaryFiles);
      }
    }
    if (title === "floorplan") {
      if (id) {
        setUploadFloorPlan((prevData) =>
          prevData.filter((item) => item.id !== id)
        );
        setRemoveImageIdsData((prevData) => [...prevData, id]);
      } else {
        let newData = uploadFloorPlan.filter((item) => item.name !== imageUrl);
        setUploadFloorPlan(newData);
        const filteredBinaryFiles = uploadFloorPlanFile?.filter(
          (file) => !file.name.includes(imageUrl)
        );
        SetUploadFloorPlanFile(filteredBinaryFiles);
      }
    }
    if (title === "brochure") {
      if (id) {
        SetUploadBrochure((prevData) =>
          prevData.filter((item) => item.id !== id)
        );
        setRemoveImageIdsData((prevData) => [...prevData, id]);
      } else {
        let newData = uploadbrochure.filter((item) => item.name !== imageUrl);
        SetUploadBrochure(newData);
        const filteredBinaryFiles = uploadbrochureFile?.filter(
          (file) => !file.name.includes(imageUrl)
        );
        SetUploadBrochureFile(filteredBinaryFiles);
      }
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    const previewUrls = [];
    const validFiles = [];
    const selectedNames = listOfImages.map((image) => image.name);
    let duplicateFound = false;
    for (let i = 0; i < files.length; i++ && files.type.includes("image")) {
      const file = files[i];
      if (file.size > 2 * 1024 * 1024) {
        toast.error("image can be of a max of 2 MB ");
        continue;
      }
      if (selectedNames.includes(file.name)) {
        if (!duplicateFound) {
          console.error("Image already selected");
          duplicateFound = true;
        }
        continue;
      }
      const reader = new FileReader();
      reader.onload = () => {
        previewUrls.push({ previewUrls: reader.result, name: file.name });
        setUploadImages([...uploadImages, ...previewUrls]);
      };
      if (file.type.includes("video")) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsDataURL(file);
      }
      validFiles.push(file);
    }
    if (validFiles.length > 0) {
      setPreviousFile(validFiles[validFiles.length - 1]);
    }
    setListOfImages([...listOfImages, ...validFiles]);
  };
  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "numberedList",
        "bulletedList",
        "|",
        "undo",
        "redo",
      ],
      styles: {
        height: "500px",
      },
    },
    language: "en",
  };
  return (
    <div className={classes.MyListingLayoutMain}>
      {organisationListData?.length > 0 && (
        <div className={classes.AddListingOption}>
          <p>{t("i_would_like_to_add_a_listing_as")}</p>
          <div className={classes.AddListingOptionBtnWrap}>
            <button
              className={
                addPropertyData.property_for !== "myself"
                  ? classes.MySelfBtn
                  : classes.OrganisationBtn
              }
              ref={(el) => (inputRefs.current[0] = el)}
              name="property_for"
              value={addPropertyData.property_for}
              onClick={() => {
                setAddPropertyData((prevState) => ({
                  ...prevState,
                  property_for: "myself",
                }));
              }}
            >
              {t("my_self")}
            </button>
            <button
              className={
                addPropertyData.property_for !== "organisation"
                  ? classes.MySelfBtn
                  : classes.OrganisationBtn
              }
              value={addPropertyData.property_for}
              name="property_for"
              onClick={() => organisationClickHandler()}
              ref={(el) => (inputRefs.current[0] = el)}
            >
              {t("an_organisation")}
            </button>
          </div>
          {error?.property_for && (
            <span className={`${classes.errorText}`}>{error.property_for}</span>
          )}
        </div>
      )}
      {addPropertyData.property_for === "organisation" && (
        <div className={classes.OrgSelect}>
          <p>{t("please_select_which_organisation")}</p>
          {organisationListData?.length > 0 ? (
            organisationListData.map((item) => {
              return (
                <div className={classes.OrganizationContainer}>
                  <p
                    onClick={() => {
                      setId(item?.id);
                      setAddPropertyData((prevState) => ({
                        ...prevState,
                        agencyId: item?.id,
                      }));
                    }}
                    className={
                      id === item?.id ? classes.OrgActive : classes.Org
                    }
                  >
                    {item?.name},{item?.city}
                  </p>
                </div>
              );
            })
          ) : (
            <>
              <div className={classes.OrganizationContainer}>
                <p
                  onClick={() => {
                    setId(organisationListData?.id);
                    setAddPropertyData((prevState) => ({
                      ...prevState,
                      agencyId: organisationListData?.id,
                    }));
                  }}
                >
                  {organisationListData &&
                    `${organisationListData?.name},${organisationListData?.city}`}
                </p>
              </div>
            </>
          )}
        </div>
      )}
      <div className={classes.SellPropertyOptions}>
        <p className={classes.TypeOfProperty}>
          {t("are_you_looking_to_sell_rent_out_your_property")}
        </p>
        <div className={classes.SellPropertyOptionsBtn}>
          <button
            onClick={() => {
              setAddPropertyData((prevState) => ({
                ...prevState,
                propertyType: "sell",
                sell_price: "",
                sell_prefix: "",
                sell_postfix: "",
                rent_price: "",
                rent_prefix: "",
                rent_postfix: "",
              }));
            }}
            ref={(el) => (inputRefs.current[1] = el)}
            value={addPropertyData.propertyType}
            className={
              addPropertyData.propertyType === "sell"
                ? classes.RentBtnActive
                : classes.RentBtn
            }
          >
            {t("sell")}
          </button>
          <button
            onClick={() => {
              setAddPropertyData((prevState) => ({
                ...prevState,
                propertyType: "rent",
                sell_price: "",
                sell_prefix: "",
                sell_postfix: "",
                rent_price: "",
                rent_prefix: "",
                rent_postfix: "",
              }));
            }}
            ref={(el) => (inputRefs.current[1] = el)}
            value={addPropertyData.propertyType}
            className={
              addPropertyData.propertyType === "rent"
                ? classes.RentBtnActive
                : classes.RentBtn
            }
          >
            {t("rent")}
          </button>
          <button
            onClick={() => {
              setAddPropertyData((prevState) => ({
                ...prevState,
                propertyType: "both",
                sell_price: "",
                sell_prefix: "",
                sell_postfix: "",
                rent_price: "",
                rent_prefix: "",
                rent_postfix: "",
              }));
            }}
            ref={(el) => (inputRefs.current[1] = el)}
            value={addPropertyData.propertyType}
            className={
              addPropertyData.propertyType === "both"
                ? classes.RentBtnActive
                : classes.RentBtn
            }
          >
            {t("both")}
          </button>
        </div>
        {error?.propertyType && (
          <span className={`${classes.errorText}`}>{error.propertyType}</span>
        )}
      </div>
      <div className={classes.PropertyFormMain}>
        <div className={classes.PropertyAddress}>
          <p>{t("what_is_the_address_of_the_property")}</p>
          <div className={classes.InputFildBox}>
            <p>{t("address")}</p>
            <input
              type="text"
              name="address"
              value={addPropertyData.address}
              onChange={onChangeHandler}
              placeholder={t("address")}
              ref={(el) => (inputRefs.current[2] = el)}
            />
            {error?.address && (
              <span className={`${classes.errorText}`}>{error.address}</span>
            )}
          </div>
          <div className={classes.flexInput}>
            <div className={classes.InputFildBox}>
              <p>{t("postal_code")}</p>
              <input
                type="text"
                name="postcode"
                id="postcode"
                value={addPropertyData.postcode}
                onChange={onChangeHandler}
                placeholder={t("postal_code")}
                ref={(el) => (inputRefs.current[3] = el)}
                maxLength={6}
              />
              {error?.postcode && (
                <span className={`${classes.errorText}`}>{error.postcode}</span>
              )}
            </div>
            <div className={classes.InputFildBox}>
              <p>{t("city")}</p>
              <select
                type="text"
                name="city"
                value={addPropertyData.city}
                onChange={onChangeHandler}
                placeholder={t("city")}
                ref={(el) => (inputRefs.current[4] = el)}
              >
                <option value="" disabled selected>
                  {`${t("select_price_type")}`}
                </option>
                {stateData?.cityList?.length > 0 &&
                  stateData.cityList.map((item, index) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
              </select>
              {error?.city && (
                <span className={`${classes.errorText}`}>{error.city}</span>
              )}
            </div>
          </div>
        </div>
        <div className={classes.PropertyAvailable}>
          <p>{t("when_will_this_property_become_available")}</p>
          <input
            type="date"
            name="propertyAvailableDate"
            value={addPropertyData.propertyAvailableDate}
            onChange={onChangeHandler}
            min={disablePastDate()}
            ref={(el) => (inputRefs.current[5] = el)}
          />
        </div>
        {error?.propertyAvailableDate && (
          <span className={`${classes.errorText}`}>
            {error.propertyAvailableDate}
          </span>
        )}
        <div className={classes.PropertyBedroomNum}>
          <div>
            <p>{t("how_many_bedrooms_does_the_property_have")}</p>
            <input
              type="text"
              name="bedrooms"
              value={addPropertyData.bedrooms}
              onChange={onChangeHandler}
              onKeyDown={handleKeyPressForNumber}
              maxLength={2}
              ref={(el) => (inputRefs.current[6] = el)}
            />
            <span
              className={classes.BedroomRnge}
              onClick={() => {
                if (addPropertyData.bedrooms > 0) {
                  setAddPropertyData((prevState) => ({
                    ...prevState,
                    bedrooms: addPropertyData.bedrooms - 1,
                  }));
                }
              }}
            >
              -
            </span>
            <span
              className={classes.BedroomRnge}
              onClick={() => {
                setAddPropertyData((prevState) => ({
                  ...prevState,
                  bedrooms: addPropertyData.bedrooms + 1,
                }));
              }}
            >
              +
            </span>
          </div>
          {error?.bedrooms && (
            <span className={`${classes.errorText}`}>{error.bedrooms}</span>
          )}
        </div>
        {(addPropertyData.propertyType === "sell" ||
          addPropertyData.propertyType === "both") && (
          <div className={classes.PriceContainer}>
            <p className={classes.pricetitle}>
              {t("sell") + " " + t("price").toLowerCase()}
            </p>
            <div className={classes.MainPriceWrap}>
              <div className={classes.PriceWrap}>
                <div className={classes.PropertyPriceWrap}>
                  <div className={classes.InputFildBox}>
                    <p>{t("price")}</p>
                    <input
                      type="number"
                      name="sell_price"
                      value={addPropertyData.sell_price}
                      min="1"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={onChangeHandler}
                      ref={(el) => (inputRefs.current[7] = el)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                          evt.key
                        ) && evt.preventDefault()
                      }
                    />
                    {error?.sell_price && (
                      <span className={`${classes.errorText}`}>
                        {error.sell_price}
                      </span>
                    )}
                  </div>
                  <div className={classes.InputFildBox}>
                    <p>{t("price_type")}</p>
                    <select
                      type="text"
                      name="sell_postfix"
                      value={addPropertyData.sell_postfix}
                      onChange={onChangeHandler}
                      onKeyDown={handleKeyPress}
                      ref={(el) => (inputRefs.current[8] = el)}
                    >
                      <option value="" disabled selected>
                        {`${t("select_price_type")}`}
                      </option>
                      {saveSellPostfix?.length > 0 &&
                        saveSellPostfix.map((item, index) => {
                          return (
                            <option value={item.value}>
                              {item.translation}
                            </option>
                          );
                        })}
                    </select>
                    {error?.sell_postfix && (
                      <span className={`${classes.errorText}`}>
                        {error.sell_postfix}
                      </span>
                    )}
                  </div>
                  <div className={classes.InputFildBox}>
                    <p>{t("price_info")}</p>
                    <select
                      type="text"
                      name="sell_prefix"
                      value={addPropertyData.sell_prefix}
                      onChange={onChangeHandler}
                      onKeyDown={handleKeyPress}
                      ref={(el) => (inputRefs.current[9] = el)}
                    >
                      <option value="" disabled selected>
                        {`${t("select_price_info")}`}
                      </option>
                      {saveSellPrefix?.length > 0 &&
                        saveSellPrefix.map((item, index) => {
                          return (
                            <option value={item.value}>
                              {item.translation}
                            </option>
                          );
                        })}
                    </select>
                    {error?.sell_prefix && (
                      <span className={`${classes.errorText}`}>
                        {error.sell_prefix}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(addPropertyData.propertyType === "rent" ||
          addPropertyData.propertyType === "both") && (
          <div className={classes.PriceContainer}>
            <p className={classes.pricetitle}>
              {t("rent") + " " + t("price").toLocaleLowerCase()}
            </p>
            <div className={classes.MainPriceWrap}>
              <div className={classes.PriceWrap}>
                <div className={classes.PropertyPriceWrap}>
                  <div className={classes.InputFildBox}>
                    <p>{t("price")}</p>
                    <input
                      type="number"
                      name="rent_price"
                      value={addPropertyData.rent_price}
                      min="1"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={onChangeHandler}
                      ref={(el) => (inputRefs.current[10] = el)}
                    />
                    {error?.rent_price && (
                      <span className={`${classes.errorText}`}>
                        {error.rent_price}
                      </span>
                    )}
                  </div>
                  <div className={classes.InputFildBox}>
                    <p>{t("price_type")}</p>
                    <select
                      type="text"
                      name="rent_postfix"
                      value={addPropertyData.rent_postfix}
                      onChange={onChangeHandler}
                      onKeyDown={handleKeyPress}
                      ref={(el) => (inputRefs.current[11] = el)}
                    >
                      <option value="" disabled selected>
                        {`${t("select_price_type")}`}
                      </option>
                      {saveRentPostfix?.length > 0 &&
                        saveRentPostfix.map((item, index) => {
                          return (
                            <option value={item.value}>
                              {item.translation}
                            </option>
                          );
                        })}
                    </select>
                    {error?.rent_postfix && (
                      <span className={`${classes.errorText}`}>
                        {error.rent_postfix}
                      </span>
                    )}
                  </div>
                  <div className={classes.InputFildBox}>
                    <p>{t("price_info")}</p>
                    <select
                      type="text"
                      name="rent_prefix"
                      value={addPropertyData.rent_prefix}
                      onChange={onChangeHandler}
                      onKeyDown={handleKeyPress}
                      ref={(el) => (inputRefs.current[12] = el)}
                    >
                      <option value="" disabled selected>
                        {`${t("select_price_info")}`}
                      </option>
                      {saveRentPrefix?.length > 0 &&
                        saveRentPrefix.map((item, index) => {
                          return (
                            <option value={item.value}>
                              {item.translation}
                            </option>
                          );
                        })}
                    </select>
                    {error?.rent_prefix && (
                      <span className={`${classes.errorText}`}>
                        {error.rent_prefix}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={classes.ApplicableOption}>
          <p>{t("choose_which_options_are_applicable")}</p>
          {saveApplicableOptions?.length > 0 &&
            saveApplicableOptions?.map((item, index) => {
              return (
                <div className={classes.PropertyOptionLabel}>
                  <input
                    type="checkbox"
                    id={item.id}
                    name={item?.value}
                    className={classes.radioCustom}
                    value={t(item?.value)}
                    onChange={handleCheckboxChange}
                    checked={selectedCheckboxes?.includes(t(item?.value))}
                    ref={(el) => (inputRefs.current[13] = el)}
                  />
                  <label className={classes.radioCustomLabel} htmlFor={item.id}>
                    <span>{" " + item?.translation}</span>
                  </label>
                </div>
              );
            })}
          {error?.applicableOptions && (
            <span className={`${classes.errorText}`}>
              {error.applicableOptions}
            </span>
          )}
        </div>
        <div className={classes.PropertyDetailWrap}>
          <div className={classes.InputWrap}>
            <div className={classes.InputFildBox}>
              <p>{t("select_the_type_of_property")}</p>
              <select
                id="property_type"
                name="property_type"
                value={addPropertyData.property_type}
                onChange={onChangeHandler}
                ref={(el) => (inputRefs.current[14] = el)}
              >
                <option value="" disabled selected>
                  {`${t("select_property_type")}`}
                </option>
                {savePropertyType?.length > 0 &&
                  savePropertyType.map((option) => (
                    <option key={option.id} value={option?.value}>
                      {option?.translation}
                    </option>
                  ))}
              </select>
              {error?.property_type && (
                <span className={`${classes.errorText}`}>
                  {error.property_type}
                </span>
              )}
            </div>
            <div className={classes.InputFildBox}>
              <p>
                {t("when_was_the_property_built")} ({t("4_digit_year")})
              </p>
              <input
                type="text"
                name="constructionYear"
                value={addPropertyData.constructionYear}
                onChange={onChangeHandler}
                onKeyDown={handleKeyPressForNumber}
                maxLength={4}
                ref={(el) => (inputRefs.current[15] = el)}
              />
              {error?.constructionYear && (
                <span className={`${classes.errorText}`}>
                  {error.constructionYear}
                </span>
              )}
            </div>
          </div>
          <div className={classes.InputWrap}>
            <div className={classes.InputFildBox}>
              <p>
                {t("property_size_in")} m<sup>2</sup>
              </p>
              <input
                type="text"
                name="livableArea"
                value={addPropertyData.livableArea}
                onChange={onChangeHandler}
                onKeyDown={handleKeyPressForNumber}
                maxLength={8}
                ref={(el) => (inputRefs.current[16] = el)}
              />
              {error?.livableArea && (
                <span className={`${classes.errorText}`}>
                  {error.livableArea}
                </span>
              )}
            </div>
            <div className={classes.InputFildBox}>
              <p>
                {t("plot_size_in")} m<sup>2</sup>
              </p>
              <input
                type="text"
                name="homePlotArea"
                value={addPropertyData.homePlotArea}
                onChange={onChangeHandler}
                onKeyDown={handleKeyPressForNumber}
                maxLength={8}
                ref={(el) => (inputRefs.current[17] = el)}
              />
              {error?.homePlotArea && (
                <span className={`${classes.errorText}`}>
                  {error.homePlotArea}
                </span>
              )}
            </div>
          </div>
          <div className={classes.InputFildBox}>
            <p>{t("energy_label")}</p>
            <select
              className={classes.energyLabel}
              id="cars"
              name="energyClass"
              value={addPropertyData.energyClass}
              onChange={onChangeHandler}
              ref={(el) => (inputRefs.current[18] = el)}
            >
              <option value="" disabled selected>
                {`${t("select")}`}
              </option>
              {ENERGY_LABEL.map((option) => (
                <option key={option.id} value={t(option?.name)}>
                  {t(option?.name)}
                </option>
              ))}
            </select>
            {error?.energyClass && (
              <span className={`${classes.errorText}`}>
                {error.energyClass}
              </span>
            )}
          </div>
        </div>
        <div className={classes.AddQuote}>
          <div className={classes.InputFildBox}>
            <p>{t("what_quote_would_you_like_to_add")}</p>
            <textarea
              type="text"
              name="quote"
              value={addPropertyData.quote}
              onChange={onChangeHandler}
              ref={(el) => (inputRefs.current[19] = el)}
              maxLength={250}
            />
            {error?.quote && (
              <span className={`${classes.errorText}`}>{error.quote}</span>
            )}
          </div>
        </div>
        <div className={classes.PropertyDescription}>
          <div className={classes.InputFildBox}>
            <p className={classes.DescriptionTitle}>
              {t("please_provide_a_full_description_of_the_property_here")}
            </p>
            <div>
              <CKEditor
                ref={(el) => (inputRefs.current[20] = el)}
                name="description"
                editor={ClassicEditor}
                config={editorConfig}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setAddPropertyDataDescription(data);
                  let error = Validate("description", data);
                  setError(error);
                }}
                data={addPropertyDataDescription || ""}
              />
            </div>
            {error?.description && (
              <span className={`${classes.errorText}`}>
                {error.description}
              </span>
            )}
          </div>
        </div>
        <div className={classes.UserInfodatails}>
          <div className={classes.InputFildBox}>
            <p>{t("email")}</p>
            <input
              type="text"
              name="email"
              value={addPropertyData.email}
              onChange={onChangeHandler}
              ref={(el) => (inputRefs.current[21] = el)}
            />
            {error?.email && (
              <span className={`${classes.errorText}`}>{error.email}</span>
            )}
          </div>
          <div className={classes.InputFildBox}>
            <p>{t("phonenumber_whatsapp")}</p>
            <input
              type="text"
              name="phoneno"
              value={addPropertyData.phoneno}
              onChange={onChangeHandler}
              maxLength="10"
              minLength="8"
              inputMode="numeric"
              pattern="[0-9]*"
              onKeyDown={handleKeyPressForNumber}
              ref={(el) => (inputRefs.current[22] = el)}
            />
            {error?.phoneno && (
              <span className={`${classes.errorText}`}>{error.phoneno}</span>
            )}
          </div>
        </div>
        <div className={classes.divWrap}>
          <div className={classes.SectionTitleWrap}>
            <p>{t("upload_images_of_listing")}</p>
            <span>{t("each_image_size")}</span>
          </div>
          <div
            className={classes.uploadMain}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              ref={(el) => (inputRefs.current[23] = el)}
              type="file"
              id="upload"
              name="images"
              hidden
              multiple
              accept=".jpg, .jpeg, .png, .mp3, .mp4, .youtube, .mv ,.webp"
              onChange={handleFileSelect}
            />
            <label htmlFor="upload">
              <div
                className={
                  uploadImages?.length > 0
                    ? classes.DisplayAfterImageUpload
                    : classes.PhotoUpload
                }
              >
                <div className={classes.AllImgWrap}>
                  {uploadImages?.length > 0 ? (
                    <>
                      {uploadImages.map((item, index) => {
                        return (
                          <div className={classes.UploadImg} key={index}>
                            {item?.type?.toLowerCase().includes("video") ? (
                              <div className={classes.VideoWrap}>
                                <video
                                  src={item.previewUrls}
                                  className={
                                    item.previewUrls
                                      ? classes.ImgWrap
                                      : classes.LogoWrap
                                  }
                                />
                                <span>
                                  <img src={VideoPlayIcon} alt="Video" />
                                </span>
                              </div>
                            ) : (
                              <img
                                src={item.previewUrls}
                                className={
                                  item.previewUrls
                                    ? classes.ImgWrap
                                    : classes.LogoWrap
                                }
                                key={index}
                              />
                            )}
                            <span>
                              <AiOutlineClose
                                onClick={(e) => {
                                  e.preventDefault();
                                  removeImageHandler(
                                    item.id,
                                    item.name,
                                    "upload"
                                  );
                                }}
                              />
                            </span>
                          </div>
                        );
                      })}
                      <img
                        src={FileUpload}
                        className={classes.AfterUploadImageIcon}
                      />
                    </>
                  ) : (
                    <img src={FileUpload} alt="File upload" />
                  )}
                </div>
              </div>
            </label>
            {error?.images && (
              <span className={`${classes.errorText}`}>{error.images}</span>
            )}
          </div>
        </div>
        <div className={classes.SelectMainImg}>
          <p>{t("select_main_image")}</p>
          <div className={classes.SelectImgWrap}>
            <div className={classes.MainImgWrap}>
              {uploadImages?.length > 0 && (
                <>
                  {uploadImages
                    .filter(
                      (value) => !value.type?.toLowerCase()?.includes("video")
                    )
                    .map((item, index) => {
                      return (
                        <img
                          src={item.previewUrls}
                          key={index}
                          className={
                            index == indexId
                              ? classes.imgActive
                              : classes.imgDeactive
                          }
                          onClick={() => {
                            setIndexId(index);
                            setSingleImages(
                              item.name ? item.name : item.original_file_name
                            );
                          }}
                        />
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classes.UploadContent}>
          <div className={classes.UploadWrap}>
            <p>{t("upload_floorplan")}</p>
            <div className={classes.MainWrap}>
              <input
                type="file"
                id="upload_floorplan"
                name="upload_floorplan"
                hidden
                multiple
                accept=".jpg,.jpeg,.png,.webp"
                onChange={handleFloorPlanFileSelect}
              />
              <label htmlFor="upload_floorplan">
                <div
                  className={
                    uploadFloorPlan?.length > 0
                      ? classes.DisplayAfterImageUpload
                      : classes.PhotoUpload
                  }
                >
                  <div className={classes.AllImgWrap}>
                    {uploadFloorPlan?.length > 0 ? (
                      <>
                        {uploadFloorPlan.map((item, index) => {
                          return (
                            <>
                              <div className={classes.UploadImg}>
                                <img
                                  src={item.previewUrls}
                                  className={
                                    item.previewUrls
                                      ? classes.ImgWarp
                                      : classes.LogoWrap
                                  }
                                  key={index}
                                />
                                <span>
                                  <AiOutlineClose
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeImageHandler(
                                        item.id,
                                        item.name,
                                        "floorplan"
                                      );
                                    }}
                                  />
                                </span>
                              </div>
                            </>
                          );
                        })}
                        <img
                          src={FileUpload}
                          className={classes.AfterUploadImageIcon}
                        />
                      </>
                    ) : (
                      <img src={FileUpload} alt="File upload" />
                    )}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className={classes.UploadWrap}>
            <p>{t("upload_brochure")}</p>
            <div className={classes.MainWrap}>
              <input
                type="file"
                id="upload_brochure"
                name="upload_brochure"
                hidden
                multiple
                accept=".pdf"
                onChange={handleBrochureFileSelect}
              />
              <label htmlFor="upload_brochure">
                <div
                  className={
                    uploadbrochure?.length > 0
                      ? classes.DisplayAfterImageUpload
                      : classes.PhotoUpload
                  }
                >
                  <div className={classes.AllImgWrap}>
                    {uploadbrochure?.length > 0 ? (
                      <>
                        {uploadbrochure?.map((item, index) => {
                          return (
                            <div className={classes.UploadImg}>
                              <img src={PdfIcon} alt="Pdf upload" />
                              <span>
                                <AiOutlineClose
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeImageHandler(
                                      item.id,
                                      item.name,
                                      "brochure"
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          );
                        })}
                        <img
                          src={FileUpload}
                          className={classes.AfterUploadImageIcon}
                        />
                      </>
                    ) : (
                      <img src={FileUpload} alt="File upload" />
                    )}
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <button
          className={classes.saveButton}
          onClick={() => AddPropertyListingHandler()}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            t("post_listing")
          )}
        </button>
      </div>
    </div>
  );
};
export default MyListingLayout;
