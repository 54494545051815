import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, fal, far } from "@awesome.me/kit-0ea32a987c/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionSaveBannerDetails,
  actionViewBannerDetailsApiCall,
  actionDeletePropertyBannerApiCall,
  actionMakeBannerStopApiCall,
} from "../../../Redux/Actions";
import ConfirmationModal from "../../layouts/ConfirmationModal";
import ModalMain from "../../common/Modal";

import classes from "./propertybanneroverview.module.scss";

const PropertyBannerOverview = ({ propertyBanners, properties }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModel, setShowModel] = React.useState(false);
  const [showInfoScreen, setShowInfoScreen] = React.useState(false);
  const [showContent, setShowContent] = React.useState("");
  const [showTitle, setShowTitle] = React.useState("");
  const [id, setId] = React.useState("");

  const RemovePropertyBannerHandler = () => {
    let data = {
      id: id,
    };
    let body = { data, navigate };
    dispatch(actionDeletePropertyBannerApiCall(body));
  };
  const getCount = (item, type) => {
    let count = 0;
    for (let i = 0; i < item.campaign_banners.length; i++) {
      const cp = item.campaign_banners[i];
      if (type === "click_count") {
        console.log(item.id, cp.property_banner.click_count);
        count += parseInt(cp.property_banner.click_count);
      } else {
        count += parseInt(cp.property_banner.view_count);
      }
    }
    console.log("count", count);
    return isNaN(count) ? 0 : count;
  };

  return (
    <React.Fragment>
      <div className={classes.grid}>
        <a href="/add-banner" className={classes.addnewitem}>
          <div className={classes.content}>
            <FontAwesomeIcon icon={far.faRocket} />
            <p>Nieuw campagne toevoegen</p>
          </div>
        </a>
        {propertyBanners?.length > 0 &&
          propertyBanners.map((item) => {
            return (
              <a
                href={item.active ? `/edit-banner/${item.id}` : ``}
                onClick={(e) => {
                  if (!item.active) {
                    e.preventDefault();
                  }
                }}
                className={classes.item}
                style={
                  item.active
                    ? {}
                    : {
                        backgroundColor: "rgb(247, 247, 247)",
                        cursor: "not-allowed",
                        border: "1px solid #ccc",
                      }
                }
              >
                <div className={classes.image}>
                  {item.listing_auto_select === false &&
                    item.banner_campaign_listing &&
                    item.banner_campaign_listing.length > 0 &&
                    item.banner_campaign_listing.map((property) => {
                      if (
                        property?.listing_property &&
                        property?.listing_property?.image_list.length > 0
                      ) {
                        return (
                          <img
                            className={classes.BannerImage}
                            src={
                              property.listing_property.image_list[0]
                                .url_thumb_file
                            }
                            alt={
                              property.listing_property.image_list[0]
                                .original_file_name
                            }
                          />
                        );
                      } else {
                        return "";
                      }
                    })}
                  {item.listing_auto_select === true &&
                    properties?.rows
                      ?.filter(
                        (property) => property.agency_id === item.agency_id
                      )
                      .filter((property) => property.image_list.length > 0)
                      .slice(0, 1)
                      .map((property) => {
                        return (
                          <img
                            className={classes.BannerImage}
                            src={property.image_list[0].url_thumb_file}
                            alt={property.image_list[0].original_file_name}
                          />
                        );
                      })}{" "}
                  <div className={classes.tags}>
                    <div className={classes.tag}>
                      <FontAwesomeIcon icon={fal.faArrowPointer} />
                      <span>
                        <span className={classes.number}>
                          {getCount(item, "click_count") || 0}
                        </span>
                        <span className={classes.label}>CTR</span>
                      </span>
                    </div>
                    <div className={classes.tag}>
                      <FontAwesomeIcon icon={fal.faEye} />
                      <span>
                        <span className={classes.number}>
                          {getCount(item, "view_count") || 0}
                        </span>
                        <span className={classes.label}>views</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.banner_title}>
                  <p>{item.title.replace("Doorlopende campagne ", "")}</p>
                </div>
                <div className={classes.content}>
                  <div className={classes.flex}>
                    {/* <div className={classes.socialicons}>
                      <FontAwesomeIcon icon={fal.faDesktop} />
                      <FontAwesomeIcon icon={fab.faFacebook} />
                      <FontAwesomeIcon icon={fab.faInstagram} />
                      <FontAwesomeIcon icon={fab.faLinkedin} />
                    </div> */}

                    <a
                      href="#"
                      className={classes.button}
                      onClick={(e) => {
                        e.preventDefault();
                        let body = {
                          data: { id: item.id },
                        };
                        dispatch(actionMakeBannerStopApiCall(body));
                      }}
                      disabled={!item.active}
                    >
                      <FontAwesomeIcon
                        icon={item.active ? fal.faHand : fas.faHand}
                      />{" "}
                      {item.active ? t("banners_stop") : t("banners_stopped")}
                    </a>
                    {item.active && (
                      <a
                        href="#"
                        className={classes.button}
                        onClick={(e) => {
                          e.preventDefault();
                          let data = { id: item.id };
                          dispatch(actionSaveBannerDetails(null));
                          dispatch(actionViewBannerDetailsApiCall(data));
                          navigate(`/edit-banner/${item.id}`);
                        }}
                      >
                        <FontAwesomeIcon icon={fal.faPenToSquare} />{" "}
                        {t("banners_edit")}
                      </a>
                    )}
                  </div>
                </div>
              </a>
            );
          })}
      </div>

      <ModalMain
        showmodel={showInfoScreen}
        handleClose={() => setShowInfoScreen(false)}
        dialogClassName={classes.ModaleSize}
        title={showTitle}
        children={showContent}
      ></ModalMain>
      <ConfirmationModal
        showmodel={showModel}
        handleClose={() => setShowModel(false)}
        size={"sm"}
        onClickYesButton={RemovePropertyBannerHandler}
        title={`${t("are_you_sure_you_want_to_remove")}?`}
      />
    </React.Fragment>
  );
};

export { PropertyBannerOverview };
