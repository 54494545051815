import MainContainer from "../layouts/MainContainer";
import classes from "./showblog.module.scss";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetLatestBlogsApiCall, actionGetSingleBlogApiCall } from "../../Redux/Actions";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const ShowBlogMain = () => {
    // This would typically come from props or a data source
    // const blogData = {
    //     title: "Sample Blog Title",
    //     author: "John Doe",
    //     date: "April 15, 2024",
    //     shortDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
    //     backgroundImage: searchBannerImg,
    // };

    // this is for showing the single blog page for the same
    const navigate = useNavigate(),
        dispatch = useDispatch();
    const location = useLocation();
    const { slug } = useParams();
    const blogId = location.state?.blogId,
        country = location.state?.country;
    const loading = useSelector((state) => state.CommonReducer.loading);
    const [displayCount, setDisplayCount] = useState(3);
    console.log("The country inside the showBlogMain is", country)

    const SingleBlogData = useSelector(
        (state) => state.CommonReducer.SaveSingleBlogData
    );
    const LatestBlogs = useSelector(
        (state) => state.CommonReducer.SaveLatestBlogsData
    );
    console.log("the latestBlogs is", LatestBlogs);

    useEffect(() => {
        const handleResize = () => {
            setDisplayCount(window.innerWidth > 1440 ? 4 : 3);
        };

        // Set initial value
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        //calling latest blogsAPI
        dispatch(actionGetLatestBlogsApiCall({
            navigate,
            search: country,
        }))

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (blogId) {
            dispatch(actionGetSingleBlogApiCall({ navigate, blogId }));
        }
    }, [blogId, dispatch, navigate]);

    useEffect(() => {
        if (location.state?.blogId) {
            dispatch(actionGetSingleBlogApiCall({ 
                navigate, 
                blogId: location.state.blogId 
            }));
        }
    }, [location.state?.blogId]);

    // Add this useEffect to scroll to top when blog content changes
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [SingleBlogData]); // This will trigger when blog data changes

    const formatUrlTitle = (title) => {
        return title
            .toLowerCase()
            .replace(/\s+/g, '-')        // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '')     // Remove special characters
            .replace(/--+/g, '-');       // Replace multiple hyphens with single hyphen
    };

    return (
        <>
            {
                loading ? (
                    <div className={classes.LoaderWrapSingleBlog}>
                        <Loader />

                    </div>
                ) : (
                    <>
                        <article className={classes.blogPost}>
                            <div
                                className={classes.heroSection}
                                style={{
                                    backgroundImage: `linear-gradient(180deg, 
                                    rgba(0, 0, 0, 0.3),
                                     rgba(0, 0, 0, 0.5)), 
                                     url(${SingleBlogData?.attributes?.Image?.data?.attributes?.url})`
                                }}
                            >
                                <div className={classes.heroContent}>
                                    <h1>{SingleBlogData?.attributes?.Title}</h1>
                                    <div className={classes.meta}>
                                        <span className={classes.author}>By {SingleBlogData?.attributes?.Author}</span>
                                        <span className={classes.date}>Op {SingleBlogData?.attributes?.Date}</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <MainContainer>
                            <div className={classes.descriptionContainer}>
                                <p className={classes.description}>{SingleBlogData?.attributes?.summary}</p>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: SingleBlogData?.attributes?.Description
                                    }}
                                >
                                </div>
                            </div>
                        </MainContainer>
                    </>

                )
            }
            {/* <LatestBlogs country={country} title="Ook interessant om te lezen" /> */}
            <div className={classes.showBlogSection}>
                <div className={classes.blogSection}>
                    <div className={classes.headerWrapper}>
                        <h1 className={classes.title}>Ook interessant om te lezen</h1>
                        <Link to={`/view-blogs/${country}`} className={classes.readMore}>Lees meer {'>'}</Link>
                    </div>
                    <div className={classes.blogGrid}>
                        {LatestBlogs && LatestBlogs?.slice(0, displayCount)?.map((blog) => (
                            <div key={blog.id} className={classes.blogCard}>
                                <div className={classes.imageWrapper}>
                                    <img src={blog?.attributes?.Image?.data?.attributes?.url} alt={blog.title} />
                                </div>
                                <div className={classes.content}>
                                    <h2>{blog?.attributes?.Title}</h2>
                                    <p>
                                        {blog?.attributes?.summary?.length > 100
                                            ? `${blog?.attributes?.summary.substring(0, 150)}...`
                                            : blog?.attributes?.summary}
                                    </p>
                                    <Link
                                        to={`/blog/${formatUrlTitle(blog?.attributes?.Title)}`}
                                        state={{ blogId: blog.id, country: country }}
                                        className={classes.cardReadMore}
                                        replace={true}
                                    >
                                        Lees meer {'>'}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShowBlogMain;