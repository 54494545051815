import React, { useEffect, useState } from "react";
import classes from "./latestlistingsmain.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetPropertyAllDataApiCall,
  actionLatestPropertyListingHomeApiCall,
  actionSaveSinglePropertyDetails,
} from "../../../Redux/Actions";
import ListingImg from "../../../assets/images/listingImg.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layouts/MainContainer";
import displayPrice from "../../../utils/displayPrice";
import defaultImage from "../../../assets/images/defaultImage.png";

const LatestListingsMain = (props) => {
  console.log(props?.countryId, "propsssss");
  const dynamicBanner = useSelector(
    (state) => state.CommonReducer.saveCountryImageInfoData
  );
  console.log(dynamicBanner, "dynamicBannerrrrrr1234");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const homePropertyData = useSelector(
    (state) => state.CommonReducer.homePropertyData
  );
  console.log(
    homePropertyData?.rows?.filter((val) => val?.image_list?.length),
    "homePropertyData"
  );
  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  const location = window.location.pathname;
  useEffect(() => {
    if (location === "/" || location === "/home" || props?.countryId) {
      let userId = localStorage.getItem("user_id");
      let data = {
        type: "home",
        country_id:
          location === "/" || location === "/home"
            ? ""
            : JSON.parse(props?.countryId),
        // country_id: JSON.parse(props?.countryId),
        user_id: userId,
        offset: 0,
        page: 1,
        per_page: 20,
      };
      dispatch(actionLatestPropertyListingHomeApiCall(data));
    }
  }, [props?.countryId, dispatch]);
  return (
    <React.Fragment>
      <MainContainer>
        <div className={classes.LatestListingSection}>
          {screenWidth > 500 &&
            homePropertyData?.rows?.length > 0 &&
            homePropertyData?.rows?.filter(
              (val) => val?.image_list?.length > 0
            ) && (
              <h1>
                {t("latest_listings")} in{" "}
                <span>
                  <b>{dynamicBanner?.map((item) => item?.name)}</b>
                </span>
              </h1>
            )}
          <div className={classes.ListingCards}>
            {homePropertyData?.rows?.length > 0 &&
              homePropertyData?.rows
                ?.filter((val) => val?.image_list?.length > 0)
                .map((item, index) => {
                  return (
                    <>
                      {index <= 4 && (
                        <div
                          className={classes.CardMain}
                          onClick={() => {
                            actionSaveSinglePropertyDetails("");
                          }}
                        >
                          <div
                            className={classes.CardImg}
                            onClick={() =>
                              navigate(
                                `/view-listing/${
                                  item.city &&
                                  item.city
                                    .toLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/[^\w-]+/g, "")
                                }/${
                                  item.address
                                    ? item.address
                                        .toLowerCase()
                                        .replace(/ /g, "-")
                                        .replace(/[^\w-]+/g, "")
                                    : `${item.street ? item.street + "-" : ""}${
                                        item.housenumber
                                          ? item.housenumber + "-"
                                          : ""
                                      }${
                                        item.postcode ? item.postcode + "-" : ""
                                      }${item.district ? item.district : ""}`
                                        .toLowerCase()
                                        .replace(/[^\w-]+/g, "")
                                }/${item.id}`
                              )
                            }
                          >
                            {item.status &&
                              item.status.toUpperCase() !== "AVAILABLE" && (
                                <div className={classes.PropertyStatus}>
                                  {t(item.status)}
                                </div>
                              )}
                            {item.image_list.length > 0 ? (
                              item.image_list.map((val) => {
                                return (
                                  <img
                                    src={val.url_thumb_file}
                                    alt={val.original_file_name}
                                  />
                                );
                              })
                            ) : (
                              <img src={defaultImage} alt="Property" />
                            )}
                          </div>
                          <div className={classes.CardContentWrap}>
                            <h5
                              onClick={() =>
                                navigate(
                                  `/view-listing/${
                                    item.city &&
                                    item.city
                                      .toLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/[^\w-]+/g, "")
                                  }/${
                                    item.address
                                      ? item.address
                                          .toLowerCase()
                                          .replace(/ /g, "-")
                                          .replace(/[^\w-]+/g, "")
                                      : `${
                                          item.street ? item.street + "-" : ""
                                        }${
                                          item.housenumber
                                            ? item.housenumber + "-"
                                            : ""
                                        }${
                                          item.postcode
                                            ? item.postcode + "-"
                                            : ""
                                        }${item.district ? item.district : ""}`
                                          .toLowerCase()
                                          .replace(/[^\w-]+/g, "")
                                  }/${item.id}`
                                )
                              }
                            >
                              {item?.address
                                ? item?.address
                                : `${item?.street} ${item?.housenumber}`}
                            </h5>
                            <span
                              onClick={() =>
                                navigate(
                                  `/view-listing/${
                                    item.city &&
                                    item.city
                                      .toLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/[^\w-]+/g, "")
                                  }/${
                                    item.address
                                      ? item.address
                                          .toLowerCase()
                                          .replace(/ /g, "-")
                                          .replace(/[^\w-]+/g, "")
                                      : `${
                                          item.street ? item.street + "-" : ""
                                        }${
                                          item.housenumber
                                            ? item.housenumber + "-"
                                            : ""
                                        }${
                                          item.postcode
                                            ? item.postcode + "-"
                                            : ""
                                        }${item.district ? item.district : ""}`
                                          .toLowerCase()
                                          .replace(/[^\w-]+/g, "")
                                  }/${item.id}`
                                )
                              }
                            >
                              {item.postcode} {item.city}
                            </span>
                            <p>
                              {displayPrice(
                                item.is_sell,
                                item.is_rent,
                                item.rent_price,
                                item.rent_postfix,
                                item.sell_price,
                                item.sell_postfix,
                                t
                              )}
                            </p>

                            {item?.agency_detail?.name && (
                              <p
                                className={classes.CardLink}
                                onClick={() => {
                                  navigate(
                                    `/view-organisation/${item?.agency_detail?.city
                                      .toLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(
                                        /[^\w-]+/g,
                                        ""
                                      )}/${item?.agency_detail?.name
                                      .toLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/[^\w-]+/g, "")}/${
                                      item.agency_id
                                    }`
                                  );
                                }}
                              >
                                {item?.agency_detail?.name}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
          </div>
        </div>
      </MainContainer>
    </React.Fragment>
  );
};

export default LatestListingsMain;
