import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "./mymagazineform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetMagazineApiCall,
  actionModifyPlacementApiCall,
} from "../../../Redux/Actions";
import PriceFormatter from "../../../utils/PriceFormatter";
import { DateTime } from "luxon";
import { DropDownSelector } from "../../DropDownSelector";
import { MyListingApiCall } from "../../../API/API";
import Loader from "../../Loader";

const MagazineForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();
  const ITEMS_PER_PAGE = 20;
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [myPropertyListingData, setMyPropertyListingData] = useState([]);

  const urlArr = window.location.pathname.split("/");

  const id = urlArr[2];

  const magazineData = useSelector(
    (state) => state.CommonReducer.saveMagazineData
  );

  const name = magazineData.name;
  const date = DateTime.fromISO(magazineData.publication_date).toFormat(
    "dd-LL-yyyy"
  );
  const deadline = DateTime.fromISO(magazineData.deadline).toFormat(
    "dd-LL-yyyy HH:mm"
  );
  const productionDate = DateTime.fromISO(
    magazineData.production_date
  ).toFormat("dd-LL-yyyy");
  const pages = magazineData.magazine_pages.length;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    magazineData.magazine_placements
      ? magazineData.magazine_placements.map((item) => item.property_id)
      : []
  );
  const [selectedTypes, setSelectedTypes] = useState({});

  const handleCheckboxChange = (event) => {
    console.log("handleCheckboxChange", event);
    const value = event.target.value;
    const typeInput = document.getElementById(`ak_${value}`);
    let data = {};
    if (event.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, parseInt(value)]);
      let tmpTypes = selectedTypes;
      if (typeof tmpTypes[parseInt(value)] === "undefined") {
        tmpTypes[parseInt(value)] = 1;
        setSelectedTypes(tmpTypes);
      }
      data = {
        checked: 1,
        property_id: value,
        ad_kind_id: typeInput.value,
        magazine_id: magazineData.id,
      };
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== parseInt(value))
      );
      data = {
        checked: 0,
        property_id: value,
        ad_kind_id: typeInput.value,
        magazine_id: magazineData.id,
      };
    }
    dispatch(actionModifyPlacementApiCall(data));
    console.log("data checkbox", data);
  };

  const onChangeHandler = (event) => {
    console.log(event.target.selectedOptions[0].value);
    setSelectedCheckboxes(
      selectedCheckboxes.filter(
        (item) => item !== parseInt(`${event.target.id.replace("ak_", "")}`)
      )
    );
    setSelectedCheckboxes([
      ...selectedCheckboxes,
      parseInt(`${event.target.id.replace("ak_", "")}`),
    ]);
    let tmpTypes = selectedTypes;
    tmpTypes[event.target.id.replace("ak_", "")] = parseInt(
      event.target.selectedOptions[0].value
    );
    console.log("tmpTypes", tmpTypes);
    setSelectedTypes(tmpTypes);
    const data = {
      checked: 1,
      property_id: event.target.id.replace("ak_", ""),
      ad_kind_id: event.target.selectedOptions[0].value,
      magazine_id: magazineData.id,
    };
    dispatch(actionModifyPlacementApiCall(data));
    console.log("data", data);
    console.log("selectedTypes", selectedTypes);
  };

  useEffect(() => {
    if (myPropertyListingData.length === 0) {
      handlePageChange(currentPage);
    }
    if (id && typeof id !== "undefined") {
      let body = { id: id };
      console.log("body", body);
      dispatch(actionGetMagazineApiCall(body));
      let tmpTypes = selectedTypes;
      console.log("tmpTypes 1", tmpTypes);
      for (let i = 0; i < magazineData.magazine_placements.length; i++) {
        tmpTypes[magazineData.magazine_placements[i].property_id] =
          magazineData.magazine_placements[i].ad_kind_id;
      }
      console.log("tmpTypes 2", tmpTypes);

      setSelectedTypes(tmpTypes);
    }
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count, currentPage, dispatch, id, navigate, selectedTypes]);

  const handlePageChange = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
    const body = {
      page: pageNumber,
      per_page: ITEMS_PER_PAGE,
    };
    const properties = await MyListingApiCall(body);
    console.log("properties", properties);
    setMyPropertyListingData(properties.data.data.propertyData);
    setLoading(false);
  };
  const pagination = () => {
    let ui = [];
    for (
      let i = activePage <= 4 ? 1 : activePage - 4;
      i <= (activePage <= 4 ? 8 : activePage + 4);
      i++
    ) {
      if (i <= pageNumber.length) {
        ui.push(
          <div key={i} className={classes.page}>
            <span
              onClick={() => {
                handlePageChange(i);
              }}
              className={activePage === i ? classes.Active : classes.InActive}
            >
              {i}
            </span>
          </div>
        );
      }
    }
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
    return ui;
  };
  const changeToDefaultPage = () => {
    setCurrentPage(1);
    setActivePage(1);
  };

  return (
    <React.Fragment>
      <div className={classes.ConnectionContainer}>
        <div>
          {magazineData && (
            <div className={classes.MagazineContainer}>
              <div>
                <p>{t("magazine_name")}</p>
                <p>{name}</p>
              </div>
              <div>
                <p>{t("magazine_date")}</p>
                <p>{date}</p>
              </div>
              <div>
                <p>{t("magazine_deadline")}</p>
                <p>{deadline}</p>
              </div>
              <div>
                <p>{t("magazine_production_date")}</p>
                <p>{productionDate}</p>
              </div>
              <div>
                <p>{t("magazine_status")}</p>
                <p>{magazineData.status}</p>
              </div>
              <div>
                <p>{t("magazine_pages")}</p>
                <p>{pages}</p>
              </div>
            </div>
          )}
          <div className={classes.PropertiesContainer}>
            {loading === true ? (
              <div className={classes.LoaderWrap}>
                <Loader />
              </div>
            ) : myPropertyListingData?.rows &&
              myPropertyListingData?.rows?.length > 0 ? (
              myPropertyListingData?.rows?.map((item) => (
                <div
                  key={item.id}
                  className={
                    selectedCheckboxes.includes(item?.id)
                      ? classes.PropertyRowActive
                      : classes.PropertyRow
                  }
                >
                  <div>
                    {" "}
                    <div className={classes.inputbox}>
                      <input
                        type="checkbox"
                        id={item.id}
                        name={item.name}
                        className={classes.radioCustom}
                        value={item.id}
                        onChange={handleCheckboxChange}
                        checked={selectedCheckboxes.includes(item?.id)}
                      />
                      <label
                        className={classes.radioCustomLabel}
                        htmlFor={item.id}
                      >
                        <span> </span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <DropDownSelector
                      width="160px"
                      name="ad_kind"
                      placeholder={t("choose_ad_kind")}
                      id={`ak_${item.id}`}
                      value={
                        selectedTypes[item.id] !== ""
                          ? selectedTypes[item.id]
                          : 0
                      }
                      onChange={onChangeHandler}
                      data={magazineData?.magazine_type?.magazine_ad_kinds?.map(
                        (kind) => {
                          return {
                            id: kind.id,
                            value: kind.id,
                            translation: kind.name,
                          };
                        }
                      )}
                      className={classes.select}
                    />
                  </div>
                  <div>{item.address}</div>
                  <div>
                    {item.city
                      .split(" ")
                      .map(
                        (w) => w[0].toUpperCase() + w.substring(1).toLowerCase()
                      )
                      .join(" ")}
                  </div>
                  {item.is_sell === 1 && (
                    <div>
                      {`€ ${PriceFormatter(item.sell_price, 2).replace(
                        ",00",
                        ",-"
                      )}`}{" "}
                      {t(item.sell_postfix)}
                    </div>
                  )}
                  {item.is_rent === 1 && (
                    <div>
                      {`€ ${PriceFormatter(item.rent_price, 2).replace(
                        ",00",
                        ",-"
                      )}`}{" "}
                      {t(item.rent_postfix)}
                    </div>
                  )}
                </div>
              ))
            ) : (
              ""
            )}
          </div>
          <div className={classes.Pagination}>
            <div className={classes.PageWrap}>
              {currentPage > 1 && currentPage <= totalPages && (
                <div
                  className={
                    activePage !== 1
                      ? classes.NextPageActive
                      : classes.NextPageInActive
                  }
                  onClick={() => {
                    if (activePage !== 1) {
                      setCurrentPage(currentPage - 1);
                      setActivePage(currentPage - 1);
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  {"<"} <span>{t("prev_page")}</span>
                </div>
              )}
              {pageNumber?.length >= 1 && (
                <span className={classes.pageText}>{t("page")}</span>
              )}
              {pageNumber?.length > 0 && pagination()}
              {pageNumber?.length > 8 && (
                <div className={classes.page}>
                  {" "}
                  <span className={classes.pageText}>{t("of")}</span>
                  <span
                    onClick={() => {
                      handlePageChange(pageNumber.length);
                    }}
                    className={
                      activePage === pageNumber.length
                        ? classes.Active
                        : classes.InActive
                    }
                  >
                    {pageNumber.length}
                  </span>
                </div>
              )}
            </div>
            {currentPage < totalPages && (
              <div
                className={
                  activePage !== pageNumber.length - 1
                    ? classes.NextPageActive
                    : classes.NextPageInActive
                }
                onClick={() => {
                  if (activePage !== pageNumber.length) {
                    setCurrentPage(+currentPage + 1);
                    setActivePage(+currentPage + 1);
                    handlePageChange(currentPage + 1);
                  }
                }}
              >
                <span>{t("next_page")}</span> {">"}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MagazineForm;
