import React from "react";
import classes from "./adsmain.module.scss";
import MainContainer from "../../layouts/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import bannerClickHandler from "../../../utils/bannerClickHandler";

const AdsMain = (props) => {
  console.log("props123asd", props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const saveHomeStrapi = useSelector(
    (state) => state.CommonReducer.saveHomeStrapi
  );
  console.log("saveHomeStrapi", saveHomeStrapi);
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};
  const HomeAd1 = saveAgencyBannerRecommendations?.["Home Ad 1"];
  const HomeAd2 = saveAgencyBannerRecommendations?.["Home Ad 2"];
  const HomeAd3 = saveAgencyBannerRecommendations?.["Home Ad 3"];
  const HomeAd4 = saveAgencyBannerRecommendations?.["Home Ad 4"];

  const AdComponent = ({ adData }) => {
    const handleClick = () => {
      bannerClickHandler(
        adData?.agency_banner_id,
        adData?.id,
        adData?.agency_banners?.link_target,
        adData?.agency_banners?.redirection_link,
        navigate,
        dispatch
      );
    };
    return (
      <div className={classes.ImgWrap} onClick={handleClick}>
        {adData.url_original_file ? (
          <img src={adData.url_original_file} alt={adData.original_file_name} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: adData.iframe }}></div>
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      <MainContainer>
        <div className={classes.AdsSection}>
          <div className={classes.TextAds}>
            {props?.dynamicBannerforabroad ? <>
              {props?.dynamicBannerforabroad?.[0]?.html_editor_content && (
              <div
                className={classes.AdsWrap}
                dangerouslySetInnerHTML={{
                  __html: props.dynamicBannerforabroad[0].html_editor_content,
                }}
              />
            )}
            </>:
            <>
            {props?.dynamicBanner?.[0]?.html_editor_content && (
              <div
                className={classes.AdsWrap}
                dangerouslySetInnerHTML={{
                  __html: props.dynamicBanner[0].html_editor_content,
                }}
              />
            )}
            </>
            }
          </div>
          {props?.isRootOrHome && (
            <div className={classes.ImgAds}>
              <div className={classes.ImgAdsWrap}>
                {HomeAd1 && <AdComponent adData={HomeAd1} />}
                {HomeAd2 && <AdComponent adData={HomeAd2} />}
                {HomeAd3 && <AdComponent adData={HomeAd3} />}
                {HomeAd4 && <AdComponent adData={HomeAd4} />}
              </div>
            </div>
          )}
        </div>
      </MainContainer>
    </React.Fragment>
  );
};

export default AdsMain;
