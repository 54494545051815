// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displaycounter_ContainerRoom__d7bBW {
  margin: 15px;
  height: auto;
}
.displaycounter_ContainerRoom__d7bBW .displaycounter_BedroomRnge__PxLK9 {
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/DisplayCounter/displaycounter.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;AAIE;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EAIA,oBAAA;EACA,iBAAA;AAFJ","sourcesContent":[".ContainerRoom {\n  margin: 15px;\n  height: auto;\n  // p {\n  //   border: 1px solid black;\n  //   text-wrap: balance;\n  // }\n  .BedroomRnge {\n    font-size: 20px;\n    cursor: pointer;\n    margin-left: 10px;\n    -webkit-user-select: none;\n    -khtml-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    -o-user-select: none;\n    user-select: none;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContainerRoom": `displaycounter_ContainerRoom__d7bBW`,
	"BedroomRnge": `displaycounter_BedroomRnge__PxLK9`
};
export default ___CSS_LOADER_EXPORT___;
