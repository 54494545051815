import React from "react";
import { useTranslation } from "react-i18next";
import DashboardLayout from "../layouts/DashboardLayout";
import MyUpdateBannerLayout from "../layouts/MyUpdateBannerLayout";
import classes from "./editbannermain.module.scss";

const EditBannerMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MyUpdateBannerLayout />
    </React.Fragment>
  );
};

export default EditBannerMain;
