import classes from "./brokerDashboard.module.scss";
import {
  actionBannerCampaignsApiCall,
  actionGetTopMenuButtonApiCall,
  actionGetMainMenuButtonApiCall,
  actionGetDashboardSliderApiCall,
  actionGetMagazinesApiCall,
  actionGetAgencyBannerRecommendationsApiCall,
} from "../../Redux/Actions";
import { useEffect, useState } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import FacebookIcon from "../../assets/icons/bannerFacebookIcon.svg";
import InstagramIcon from "../../assets/icons/bannerInstagramIcon.svg";
import LinkedinIcon from "../../assets/icons/linkdinIcon.svg";
import ViewCountIcon from "../../assets/icons/viewIconBroker.svg";
import ClickIcon from "../../assets/icons/clickIconBroker.svg";
import GraphIcon from "../../assets/icons/graphIconBroker.svg";
import TvIcon from "../../assets/icons/TvIconBroker.svg";
import EditIcon from "../../assets/icons/EditIcon.svg";
import RocketIcon from "../../assets/icons/brokerRocketLargeIcon.svg";
import RocketIconSmall from "../../assets/icons/rocket_icon.svg";
import DashboardLayout from "../layouts/DashboardLayout";
import { PropertyBannerOverview } from "../overview/PropertyBanner";
import { MagazineOverview } from "../overview/Magazine";

const properties = [
  {
    id: 1,
    image:
      "https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid",
    price: 250,
    size: 180,
    rooms: 25,
  },
  {
    id: 2,
    image:
      "https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid",
    price: 250,
    size: 180,
    rooms: 25,
  },
  {
    id: 1,
    image:
      "https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid",
    price: 250,
    size: 180,
    rooms: 25,
  },
  {
    id: 2,
    image:
      "https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid",
    price: 250,
    size: 180,
    rooms: 25,
  },
  // Add more properties as needed
];

const BrokerDashboardMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const handleSwipe = (direction) => {
    let newIndex;
    if (direction === "left" && currentIndex < Main_Menu.length - 1) {
      newIndex = currentIndex + 1;
    } else if (direction === "right" && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (direction === "left" && currentIndex === Main_Menu.length - 1) {
      newIndex = 0; // Loop to first slide
    } else if (direction === "right" && currentIndex === 0) {
      newIndex = Main_Menu.length - 1; // Loop to last slide
    } else {
      return;
    }

    setCurrentIndex(newIndex);
    handleSetData(Main_Menu[newIndex]);
  };

  // Touch handlers
  const handleTouchStart = (e) => {
    setAutoPlay(false);
    setTouchStart(e.touches[0].clientX);
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const swipeThreshold = 50;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > swipeThreshold) {
      if (diff > 0) {
        handleSwipe("left");
      } else {
        handleSwipe("right");
      }
    }
    setAutoPlay(true);
  };

  // Mouse handlers
  const handleMouseDown = (e) => {
    setAutoPlay(false);
    setIsDragging(true);
    setStartX(e.pageX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const diff = startX - e.pageX;
    setTouchStart(startX);
    setTouchEnd(e.pageX);
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);

    const swipeThreshold = 50;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > swipeThreshold) {
      if (diff > 0) {
        handleSwipe("left");
      } else {
        handleSwipe("right");
      }
    }
    setAutoPlay(true);
  };
  const bannerCampaignsData = useSelector(
    (state) => state.CommonReducer.myBannerCampaignsData
  );

  const magazinesData = useSelector(
    (state) => state.CommonReducer.saveMagazinesData
  );
  const saveAgencyBannerRecommendations =
    useSelector(
      (state) => state.CommonReducer.saveAgencyBannerRecommendations
    ) || {};

  const Dashboard1 = saveAgencyBannerRecommendations?.["Dashboard 1"],
    Dashboard2 = saveAgencyBannerRecommendations?.["Dashboard 2"],
    Dashboard3 = saveAgencyBannerRecommendations?.["Dashboard 3"];

  console.log("Dashboard faddfare", Dashboard1, Dashboard2, Dashboard3);

  const Top_Menu = useSelector(
    (state) => state.CommonReducer.SaveTopMenuButton
  );
  const Main_Menu = useSelector(
    (state) => state.CommonReducer.SaveMainMenuButton
  );
  // const Dashboard_Slider = useSelector(
  //     (state) => state.CommonReducer.SaveSliderDashboardData
  // )

  const [sliderdata, setSliderdata] = useState({});

  const user_id = localStorage.getItem("user_id");

  // const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let body = { navigate };
    const data = {
      bannerSizes: [
        {
          "Dashboard 1": "1595*346",
        },
        {
          "Dashboard 2": "1595*346",
        },
        {
          "Dashboard 3": "1595*346",
        },
      ],
    };
    dispatch(actionBannerCampaignsApiCall(body));
    dispatch(actionGetTopMenuButtonApiCall({ navigate }));
    dispatch(actionGetMainMenuButtonApiCall({ navigate }));
    body = { id: user_id };
    dispatch(actionGetMagazinesApiCall(body));
    // dispatch(actionGetDashboardSliderApiCall({ navigate }))
    dispatch(actionGetAgencyBannerRecommendationsApiCall({ data }));
  }, []);

  useEffect(() => {
    let slideInterval;

    if (autoPlay) {
      slideInterval = setInterval(() => {
        let newIndex;
        if (currentIndex < Main_Menu?.length - 1) {
          newIndex = currentIndex + 1;
        } else {
          newIndex = 0; // Reset to first slide
        }
        setCurrentIndex(newIndex);
        handleSetData(Main_Menu[newIndex]);
      }, 3000); // 1 second interval
    }

    return () => {
      if (slideInterval) {
        clearInterval(slideInterval);
      }
    };
  }, [currentIndex, autoPlay, Main_Menu]);

  const handlennavigate = (id) => {
    navigate(`/edit-banner/${id}`);
  };

  const handleSetData = (data) => {
    setSliderdata(data);
  };

  useEffect(() => {
    setSliderdata(Main_Menu[0]);
  }, []);

  console.log("data", bannerCampaignsData);

  return (
    <DashboardLayout>
      <div className={classes.campaignListing}>
        <div className={classes.sectionTitle}>
          <h3>Woning campagnes</h3>
          <div className={classes.actionButtons}>
            <button
              className={classes.actionBtn}
              onClick={() => navigate("/my-banners")}
            >
              <img src={ViewCountIcon} /> Alle resultaten
            </button>
            <button
              className={classes.actionBtn}
              onClick={() => navigate("/add-banner")}
            >
              <img src={RocketIconSmall} /> Start nieuwe campagne
            </button>
          </div>
        </div>
        <PropertyBannerOverview
          propertyBanners={
            bannerCampaignsData.length > 7
              ? bannerCampaignsData.slice(0, 7)
              : bannerCampaignsData
          }
          properties={properties}
        />
      </div>
      <div className={classes.campaignListing}>
        <div className={classes.sectionTitle}>
          <h3>Magazines</h3>
          <div className={classes.actionButtons}>
            <button
              className={classes.actionBtn}
              onClick={() => navigate("/my-magazines")}
            >
              <img src={ViewCountIcon} /> Alle resultaten
            </button>
            {/* <button
              className={classes.actionBtn}
              // onClick={()=>navigate("/add-banner")}
            >
              <img src={RocketIconSmall} /> Start nieuwe campagne
            </button> */}
          </div>
        </div>
        <MagazineOverview
          magazines={
            magazinesData.length > 10
              ? magazinesData.slice(0, 10)
              : magazinesData
          }
        />
      </div>
    </DashboardLayout>
  );
};

export default BrokerDashboardMain;
