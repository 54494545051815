import React from "react";
import MyMagazineForm from "../layouts/MyMagazineForm";
import DashboardLayout from "../layouts/DashboardLayout";

const EditMagazineMain = () => {
  return (
    <React.Fragment>
      <DashboardLayout>
        <MyMagazineForm />
      </DashboardLayout>
    </React.Fragment>
  );
};

export default EditMagazineMain;
