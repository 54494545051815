import React, { useEffect, useState } from "react";
import MainContainer from "../../layouts/MainContainer";
import classes from './latestblogs.module.scss'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetLatestBlogsApiCall } from "../../../Redux/Actions";

const LatestBlogs = ({ country, title }) => {
    const [displayCount, setDisplayCount] = useState(3);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const LatestBlogs = useSelector(
        (state) => state.CommonReducer.SaveLatestBlogsData
    );
    console.log("the latestBlogs is", LatestBlogs);

    useEffect(() => {
        const handleResize = () => {
            setDisplayCount(window.innerWidth > 1440 ? 4 : 3);
        };

        // Set initial value
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        //calling latest blogsAPI
        dispatch(actionGetLatestBlogsApiCall({
            navigate,
            search: country,
        }))

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatUrlTitle = (title) => {
        return title
            .toLowerCase()
            .replace(/\s+/g, '-')        // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '')     // Remove special characters
            .replace(/--+/g, '-');       // Replace multiple hyphens with single hyphen
    };

    return (
        <React.Fragment>
            <MainContainer>
                <div className={classes.blogSection}>
                    <div className={classes.headerWrapper}>
                        <h1 className={classes.title}>{title ? title : "Laatste blogs"}</h1>
                        <Link to={`/view-blogs/${country}`} className={classes.readMore}>Lees meer {'>'}</Link>
                    </div>
                    <div className={classes.blogGrid}>
                        {LatestBlogs && LatestBlogs?.slice(0, displayCount)?.map((blog) => (
                            <div key={blog.id} className={classes.blogCard}>
                                <div className={classes.imageWrapper}>
                                    <img src={blog?.attributes?.Image?.data?.attributes?.url} alt={blog.title} />
                                </div>
                                <div className={classes.content}>
                                    <h2>{blog?.attributes?.Title}</h2>
                                    <p>
                                        {blog?.attributes?.summary?.length > 100
                                            ? `${blog?.attributes?.summary.substring(0, 150)}...`
                                            : blog?.attributes?.summary}
                                    </p>
                                    <Link
                                        to={`/blog/${formatUrlTitle(blog?.attributes?.Title)}`}
                                        state={{ blogId: blog.id, country: country }}
                                        className={classes.cardReadMore}
                                        replace={true}
                                    >
                                        Lees meer {'>'}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </MainContainer>
        </React.Fragment>
    );
}
export default LatestBlogs;