import React, { useState } from "react";
import classes from "./templatecardselectable.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../NoDataFound";

const TemplateCardSelectable = ({
  choosenTemplateId,
  chooseTemplate,
  properties,
  type,
  quote,
  id,
}) => {
  const [templateId, setTemplateId] = useState(choosenTemplateId);
  const myTemplatesData = useSelector(
    (state) => state.CommonReducer.myBannerTemplatesData
  );

  const sizes = {
    Leaderboard: [
      { title: "Leaderboard", size: [728, 90] },
      { title: "Billboard", size: [970, 250] },
    ],
    Mobile: [
      { title: "Medium rectangle", size: [300, 250] },
      { title: "Half page", size: [300, 600] },
    ],
  };

  const location = useLocation().pathname;
  const platform = localStorage.getItem("Platform");
  return (
    <React.Fragment>
      <div className={classes.TemplateListingWrap} id={id}>
        <div className={classes.TemplateScrollContainer}>
          <div className={classes.TemplateGridScroll}>
            {location === "/add-banner" || location.includes("/edit-banner") ? (
              sizes[type]?.length > 0 ? (
                sizes[type]?.map((item) => (
                  <div className={classes.TemplateCard}>
                    <div>{item.title}</div>
                    <div
                      className={classes.TemplatePreview}
                      min-height={`${item.size[1]}px`}
                      min-width={`${item.size[0]}px`}
                    >
                      <iframe
                        title="preview"
                        src={`https://woningmarkt.s3.amazonaws.com/${
                          platform !== "PROD" ? "development" : "production"
                        }/templates/${
                          type === "Leaderboard" ? 1 : 6
                        }/index.html?ids=${properties}&quote=${quote}`}
                        height={`${item.size[1]}px`}
                        width={`${item.size[0]}px`}
                      ></iframe>
                    </div>
                  </div>
                ))
              ) : (
                <NoDataFound />
              )
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TemplateCardSelectable;
