import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DashboardLayout from "../layouts/DashboardLayout";
import classes from "./mymagazinesmain.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionGetMagazinesApiCall } from "../../Redux/Actions";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far, fat, fal, fab } from "@awesome.me/kit-0ea32a987c/icons";
import { MagazineOverview } from "../overview/Magazine";
const MyMagazinesMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user_id = localStorage.getItem("user_id");

  const magazinesData = useSelector(
    (state) => state.CommonReducer.saveMagazinesData
  );
  useEffect(() => {
    let body = { id: user_id };
    dispatch(actionGetMagazinesApiCall(body));
  }, [dispatch, user_id]);

  return (
    <React.Fragment>
      <DashboardLayout>
        <section className={classes.woningcampagnes}>
          <div className={classes.container}>
            <div className={classes.flex}>
              <h3>Magazines</h3>
              <div className={classes.action_buttons}>
                <a className={classes.button} href="/my-magazines">
                  <FontAwesomeIcon icon={far.faEye} /> Alle bekijken
                </a>
                {/* <a className={classes.button} href="/add-banner">
                  <FontAwesomeIcon icon={far.faRocket} /> Start nieuw campagne
                </a> */}
              </div>
            </div>
            <MagazineOverview magazines={magazinesData} />
            <div className={classes.IconWrapper}></div>
          </div>
        </section>
      </DashboardLayout>
    </React.Fragment>
  );
};

export default MyMagazinesMain;
