import React, { useState } from "react";
import classes from "./subscriptionsignup.module.scss";
import { useTranslation } from "react-i18next";
import Validate from "../../utils/Validation";
import {
  actionCreateAccountWithSubscriptionApiCall,
  actionGetEmployeeJobTitleApiCall,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { actionCreateOrgAccountWithoutLoginApiCall } from "../../Redux/Actions";

const SubscriptionSignup = (props) => {
  const { t } = useTranslation();
  const [accountDetails, setaccountDetails] = useState({
    email: "",
    password: "",
  });
  const platform = localStorage.getItem("Platform");

  const [error, setError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setaccountDetails({
      ...accountDetails,
      [name]: value,
    });

    let error = Validate(name, value);
    setError(error);

    // let error;
    // if (name == "password") {
    //   error = Validate(name, value, false, {}, accountDetails.confirmPassword);
    //   setError(error);
    // } else if (name == "confirmPassword") {
    //   error = Validate(name, value, false, {}, accountDetails.password);
    //   setError(error);
    // } else {
    //   error = Validate(name, value);
    //   setError(error);
    // }
  };

  const AddOrganisationClickHandler = (e) => {
    e.preventDefault();
    let validateData = {
      email: accountDetails.email,
      password: accountDetails.password,
    };
    let error = Validate("", "", true, validateData);
    setError(error);
    if (Object.values(error).every((x) => x == null || x == "")) {
      let accountData = {
        email: accountDetails.email,
        password: accountDetails.password,
      };
      let data = {
        id: props.id,
        productId: props.productId,
        discountExtraId: props.discountExtraId,
      };
      let body = { accountData, data, navigate };
      dispatch(actionCreateAccountWithSubscriptionApiCall(body));
    }
  };

  return (
    <React.Fragment>
      <div className={classes.CreateOrgAccountMain}>
        <h1>{t("create_account")}</h1>
        <div className={classes.FormTab}>
          <form>
            <div className={classes.SectionPart}>
              <div className={classes.InputFildBox}>
                <p>{t("email")}</p>
                <input
                  type="email"
                  name="email"
                  value={accountDetails.email}
                  onChange={handleChange}
                  placeholder={t(
                    "placeholder_of_contact_email_of_organisation"
                  )}
                />
                {error?.email && (
                  <span className={`${classes.errorText}`}>{error.email}</span>
                )}
              </div>
              <div className={classes.InputFildBox}>
                <p>{t("password")}</p>
                <input
                  type="password"
                  name="password"
                  value={accountDetails.password}
                  onChange={handleChange}
                  placeholder={t("password")}
                />
                {error?.password && (
                  <span className={`${classes.errorText}`}>
                    {error.password}
                  </span>
                )}
              </div>
            </div>
            <p>{t("mail_receive")}</p>
            <button
              onClick={AddOrganisationClickHandler}
              disabled={props.id ? false : true}
              className={props.id ? classes.ActivePayNow : classes.PayNow}
            >
              {t("pay_now")}
              {/* {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>{t("pay_now")}</>
              )} */}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionSignup;
