import React from "react";
import classes from "./propertycardselectable.module.scss";
import { useSelector } from "react-redux";
import ListingImg from "../../../assets/images/listingImg.svg";
import { useLocation, useNavigate } from "react-router-dom";
import NoDataFound from "../../NoDataFound";
import PriceFormatter from "../../../utils/PriceFormatter";

const PropertyCardSelectable = ({
  selectedProperties,
  changeSelectedProperties,
  agency_id,
}) => {
  const myPropertyListingData = useSelector(
    (state) => state.CommonReducer.myPropertyListingData
  );
  const location = useLocation().pathname;
  const IMAGE_BASE_URL = "https://api-test.woningmarkt.nl/uploads/";
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className={classes.CardListingWrap}>
        {location === "/add-banner" || location.includes("/edit-banner") ? (
          myPropertyListingData?.rows?.length > 0 ? (
            myPropertyListingData?.rows
              ?.filter((item) => item.agency_id === agency_id)
              .map((item) => (
                <div
                  className={
                    item.hide_property === 1 && location === "/my-listing"
                      ? `${classes.InvisibleCard} ${classes.PropertyCard}`
                      : classes.PropertyCard
                  }
                  key={item.id}
                >
                  <div className={classes.CardSelect}>
                    <input
                      type="checkbox"
                      id={item?.id}
                      name={item?.name}
                      value={item?.id}
                      className={classes.radioCustom}
                      onChange={(event) => {
                        changeSelectedProperties(event);
                      }}
                      checked={selectedProperties.includes(item?.id)}
                    />
                    <label
                      className={classes.radioCustomLabel}
                      htmlFor={item.id}
                    ></label>
                  </div>
                  <div
                    className={classes.PropertyImg}
                    onClick={() => navigate(`/view-listing/${item.id}`)}
                  >
                    {item.image_list.length > 0 ? (
                      item.image_list.map((val) => {
                        return (
                          <img
                            alt=""
                            src={
                              val.url_thumb_file.includes("http")
                                ? val.url_thumb_file
                                : `${IMAGE_BASE_URL}thumb/${val.url_thumb_file}`
                            }
                          />
                        );
                      })
                    ) : (
                      <img alt="" src={ListingImg} />
                    )}
                  </div>
                  <div className={classes.MainContentWrap}>
                    <div className={classes.PropertyContent}>
                      <h5>{item.address}</h5>
                    </div>
                    <div className={classes.PropertyContent}>
                      <span>{item.city}</span>
                    </div>
                    <div className={classes.PropertyContent}>
                      <div className={classes.PriceText}>
                        {" "}
                        {item?.PropertyPrice?.length > 0 ? (
                          <p>
                            € {PriceFormatter(item.PropertyPrice[0].price)}{" "}
                            {item.PropertyPrice[0].price_type}
                          </p>
                        ) : (
                          <p>
                            €{" "}
                            {item.is_sell === 1
                              ? `${PriceFormatter(item.sell_price)} ${
                                  item.sell_price_unit
                                    ? item.sell_price_unit
                                    : ""
                                }`
                              : item.is_rent === 1 &&
                                `${PriceFormatter(item.rent_price)}${
                                  item.rent_price_type
                                    ? item.rent_price_type
                                    : ""
                                }`}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <NoDataFound />
          )
        ) : (
          <NoDataFound />
        )}
      </div>
    </React.Fragment>
  );
};

export default PropertyCardSelectable;
