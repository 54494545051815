import React, { useEffect, useState } from "react";
import AuthLayout from "../AuthLayout";
import classes from "./dashboardlayout.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetTopMenuButtonApiCall,
  actionGetMainMenuButtonApiCall,
  actionGetAgencyBannerRecommendationsApiCall,
} from "../../../Redux/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@awesome.me/kit-0ea32a987c/icons";
import Header from "../Header/Index";
import Footer from "../Footer/Index";

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(
    localStorage.getItem("currentIndex") || 0
  );
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const location = useLocation().pathname;

  const handleSwipe = (direction) => {
    let newIndex;
    if (direction === "left" && currentIndex < Main_Menu.length - 1) {
      newIndex = currentIndex + 1;
    } else if (direction === "right" && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else {
      return;
    }

    setCurrentIndex(newIndex);
    localStorage.setItem("currentIndex", newIndex);
    handleSetData(Main_Menu[newIndex]);
  };

  // Touch handlers
  const handleTouchStart = (e) => {
    setAutoPlay(false);
    setTouchStart(e.touches[0].clientX);
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const swipeThreshold = 50;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > swipeThreshold) {
      if (diff > 0) {
        handleSwipe("left");
      } else {
        handleSwipe("right");
      }
    }
    setAutoPlay(true);
  };

  // Mouse handlers
  const handleMouseDown = (e) => {
    setAutoPlay(false);
    setIsDragging(true);
    setStartX(e.pageX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setTouchStart(startX);
    setTouchEnd(e.pageX);
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);

    const swipeThreshold = 50;
    const diff = touchStart - touchEnd;

    if (Math.abs(diff) > swipeThreshold) {
      if (diff > 0) {
        handleSwipe("left");
      } else {
        handleSwipe("right");
      }
    }
    setAutoPlay(true);
  };
  const Top_Menu = useSelector(
    (state) => state.CommonReducer.SaveTopMenuButton
  );
  const Main_Menu = useSelector(
    (state) => state.CommonReducer.SaveMainMenuButton
  );
  // const Dashboard_Slider = useSelector(
  //     (state) => state.CommonReducer.SaveSliderDashboardData
  // )

  const [sliderdata, setSliderdata] = useState({});

  // const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let body = { navigate };
    const data = {
      bannerSizes: [
        {
          "Dashboard 1": "1595*346",
        },
        {
          "Dashboard 2": "1595*346",
        },
        {
          "Dashboard 3": "1595*346",
        },
      ],
    };
    dispatch(actionGetTopMenuButtonApiCall({ navigate }));
    dispatch(actionGetMainMenuButtonApiCall({ navigate }));
    // dispatch(actionGetDashboardSliderApiCall({ navigate }))
    dispatch(actionGetAgencyBannerRecommendationsApiCall({ data }));
  }, []);

  useEffect(() => {
    let slideInterval;
    if (location.indexOf("broker-dashboard") > -1) {
      if (autoPlay) {
        slideInterval = setInterval(() => {
          let newIndex;
          if (currentIndex < Main_Menu?.length - 1) {
            newIndex = currentIndex + 1;
          } else {
            newIndex = 0; // Reset to first slide
          }
          setCurrentIndex(newIndex);
          localStorage.setItem("currentIndex", newIndex);
          handleSetData(Main_Menu[newIndex]);
        }, 2000); // 1 second interval
      }
    }
    return () => {
      if (slideInterval) {
        clearInterval(slideInterval);
      }
    };
  }, [currentIndex, autoPlay, Main_Menu]);

  const handlennavigate = (id) => {
    navigate(`/edit-banner/${id}`);
  };

  const handleSetData = (data) => {
    setSliderdata(data);
  };

  useEffect(() => {
    setSliderdata(Main_Menu[0]);
  }, []);

  return (
    <React.Fragment>
      <main>
        <section className={classes.subheader}>
          <div className={classes.container}>
            <div className={classes.buttons}>
              {Top_Menu &&
                Top_Menu?.map((item) => (
                  <Link
                    to={item.link}
                    className={classes.button}
                    style={{
                      cursor: item.isActive ? "pointer" : "not-allowed",
                      opacity: item.isActive ? 1 : 0.5,
                    }}
                  >
                    <img src={item.icon} width={18} height={16} />
                    {item.name}
                  </Link>
                ))}

              {/* <Link to="/creative-suite" className={classes.button}>
                            <i className="fa-regular fa-bullhorn"></i>
                            Creative suite
                        </Link>
                        <Link to="/manage-offer" className={classes.button}>
                            <i className="fa-regular fa-location-dot"></i>
                            Jouw aanbod beheren
                        </Link>
                        <Link to="/deleted" className={classes.button}>
                            <i className="fa-regular fa-trash"></i>
                            Verwijderd
                        </Link> */}
            </div>
            {/* <div className={classes.search}>
              <input type="text" placeholder="Zoeken..." />
              <button className={classes.searchButton}>
                <FontAwesomeIcon icon={far.faSearch} />
              </button>
            </div> */}
          </div>
        </section>

        <section className={classes.eyecandy}>
          <div
            className={classes.sliderWrapper}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <ul
              className={classes.lightSlider}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: isDragging ? "none" : "transform 1500ms ease",
              }}
            >
              {Main_Menu &&
                Main_Menu?.map((item, index) => (
                  <li key={index} style={{ width: "100%", flexShrink: 0 }}>
                    <div
                      className={classes.image}
                      style={{
                        backgroundImage: item.sliderImage
                          ? `url(${item.sliderImage})`
                          : "none",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "500px",
                      }}
                    >
                      <div className={classes.container}>
                        <div className={classes.content}>
                          <h2>{item.header}</h2>
                          <p>{item.text}</p>
                          {location.indexOf("edit") === -1 &&
                            location.indexOf("add") === -1 && (
                              <Link
                                to={item.ctaButtonLink}
                                className={classes.pinkbutton}
                              >
                                {item.ctaButtonText}
                              </Link>
                            )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          {location.indexOf("edit") === -1 &&
            location.indexOf("add") === -1 && (
              <div className={classes.bigButtons}>
                {Main_Menu &&
                  Main_Menu?.map((item, index) => (
                    <Link to={item.link}>
                      <div
                        key={index}
                        onClick={() => {
                          setCurrentIndex(index);
                          localStorage.setItem("currentIndex", index);
                          handleSetData(item);
                        }}
                        className={`${classes.bigbutton} bigbutton ${
                          currentIndex === index ? classes.active : ""
                        }`}
                        style={{
                          cursor: item.isActive ? "pointer" : "not-allowed",
                          opacity: item.isActive ? 1 : 0.5,
                          gap: "12px",
                          textAlign: "center",
                          textDecoration: "none",
                          display: "flex",
                          gap: "20px",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#FF5FB3")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "")
                        }
                      >
                        <img
                          src={item.icon}
                          width={50}
                          height="auto"
                          alt={item.name}
                        />
                        <span
                          style={{
                            whiteSpace: "normal",
                            lineHeight: "1.2",
                            textWrap: "wrap",
                            fontSize: "16.8px",
                            textAlign: "start",
                            wordBreak: "break-word",
                            width: "100%",
                            display: "block",
                          }}
                        >
                          {item.name}
                        </span>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
        </section>

        {children}
      </main>
    </React.Fragment>
  );
};

export default DashboardLayout;
