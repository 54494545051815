import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import classes from "./mybannerlayout.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUpdateBannerApiCall,
  actionMyListingApiCall,
  actionBannerTemplatesApiCall,
  actionApprovedOrganisationsApiCall,
  actionCityInfoApiCall,
  actionViewBannerDetailsApiCall,
  actionMakeBannerStopApiCall,
  actionSaveBannerDetails,
} from "../../../Redux/Actions";
import { GetZipcodeApiCall } from "../../../API/API";
import { useNavigate, useParams } from "react-router-dom";
import TemplateCardSelectable from "../../layouts/TemplateCardSelectable";
import RegionCardSelectable from "../../layouts/RegionCardSelectable";
import moment from "moment";
import { GoogleMap, useLoadScript, Circle } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Select from "react-select";
import { DateTime } from "luxon";
import { GetSuppliersApiCall } from "../../../API/API";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far, fas } from "@awesome.me/kit-0ea32a987c/icons";
import PriceFormatter from "../../../utils/PriceFormatter";
import { SUPPLIER_BUDGETS, REGION_RADIUSES } from "../../../Constants";
import DashboardLayout from "../DashboardLayout";
import CanvasJSReact from "@canvasjs/react-charts";

const MyUpdateBannerLayout = () => {
  const libraries = ["places"];
  const mapContainerStyle = {
    width: "75%",
    height: "20vw",
  };

  const platform = localStorage.getItem("Platform");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env[`REACT_APP_${platform}_GOOGLE_API_KEY`],
    libraries,
  });

  const [show, setShow] = useState(false);

  const mapRef = useRef(null);
  const circleRef = useRef(null);

  const { t } = useTranslation();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("searchParams", searchParams);
  const location = useLocation().pathname.split("/");
  console.log("location", location[location.length - 1]);
  const state_id = localStorage.getItem("user_id");
  const organisationListData = useSelector(
    (state) => state.CommonReducer.approvedOrganisationData
  );

  const bannerDetails = useSelector(
    (state) => state.CommonReducer.bannerDetails
  );
  console.log("bannerDetails", bannerDetails);

  const stateData = useSelector((state) => state.CommonReducer);
  const myPropertyListingData = useSelector(
    (state) => state.CommonReducer.myPropertyListingData
  );
  const [properties, setProperties] = useState(myPropertyListingData.rows);
  const [changedRadius, setChangedRadius] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [templateId, setTemplateId] = useState({ Leaderboard: 1, Mobile: 6 });
  const [id, setId] = useState();
  const [agencyId, setAgencyId] = useState();
  const [bannerBudget, setBannerBudget] = useState();
  const [duration, setDuration] = useState(14);

  const [submitted, setSubmitted] = useState(false);
  const stateRegions = useSelector(
    (state) => state.CommonReducer.defaultPropertyBannerRegion
  );
  const state = useSelector((state) => state.CommonReducer);
  const [regions, setRegions] = useState(
    searchParams.get("object") != null ? stateRegions : []
  );
  const loading = useSelector((state) => state.CommonReducer.loading);
  const [searchData, setSearchData] = useState({
    address: "",
    address_data: state?.saveSearchBackground?.name
      ? state?.saveSearchBackground?.name
      : "",
  });
  const [addressSearch, setAddressSearch] = useState("");

  const [title, setTitle] = useState();
  const [quote, setQuote] = useState();

  const [newRegion, setNewRegion] = useState();
  const [newRadius, setNewRadius] = useState(15);
  const [newBudget, setNewBudget] = useState(50);

  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  console.log("before setCenter 1");
  const [center, setCenter] = useState({
    lng:
      typeof regions != "undefined" && typeof regions[0] != "undefined"
        ? regions[0].lng
        : 5.1214201,
    lat:
      typeof regions != "undefined" && typeof regions[0] != "undefined"
        ? regions[0].lat
        : 52.0907374,
  });

  const [zoom, setZoom] = useState(9);

  const chooseTemplate = (id, type) => {
    let tmpId = templateId;
    tmpId[type] = id;
    setTemplateId(tmpId);
  };
  const [selectedProperties, setSelectedProperties] = useState(
    searchParams.get("object") != null ? [searchParams.get("object")] : []
  );

  const [addBannerData, setAddBannerData] = useState({
    firstTime: true,
    agency_id: myPropertyListingData?.rows
      ?.filter((item) => item.id === parseInt(selectedProperties[0]))
      .map((item) => item.agency_id)
      .join(""),
    title: `Doorlopende campagne ${
      typeof regions != "undefined" && typeof regions[0] != "undefined"
        ? regions[0].name
        : ""
    }`,
    quote: `Nieuw in de verkoop in ${myPropertyListingData?.rows
      ?.filter((item) => item.id === parseInt(selectedProperties[0]))
      .map((item) => item.city)
      .join("-")}`,
    region: "",
    radius: "",
    auto_select: true,
    template_id: 0,
    credits: 500,
    budgets: {},
    budget: 50,
    costs_per_day: 1.6,
    total_costs: 50,
    credit_period: "PER_DAY",
    selected_properties: [],
    banner_types: [],
    start_date: moment().format("Y-MM-DD"),
    end_date: moment().add(14, "d").format("Y-MM-DD"),
  });
  useEffect(() => {
    let data = { page: 1, per_page: "9999999" };
    let body = { data, navigate };
    dispatch(actionMyListingApiCall(body));
    dispatch(actionApprovedOrganisationsApiCall(body));
  }, [dispatch, navigate]);
  useEffect(() => {
    let data = { page: 1 };
    let body = { data, navigate };
    dispatch(actionBannerTemplatesApiCall(body));
    setBannerBudget(organisationListData[0]?.banner_budget);
    console.log(typeof bannerDetails?.id);
    if (typeof bannerDetails?.id === "undefined") {
      console.log("before dispatch");
      dispatch(
        actionViewBannerDetailsApiCall({ id: location[location.length - 1] })
      );
      console.log("after dispatch");
    }
    setTitle(addBannerData.title);
    setQuote(addBannerData.quote);
  }, []);
  const [suppliers, setSuppliers] = useState(
    typeof stateData.saveSuppliers != "undefined" &&
      searchParams.get("object") != null
      ? stateData.saveSuppliers.suppliers
      : []
  );
  const [totalCosts, setTotalCosts] = useState(0);
  const [costsPerDay, setCostsPerDay] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [viewsPerDay, setViewsPerDay] = useState(0);
  const [selectedSuppliers, setSelectedSuppliers] = useState({});

  const regionHandler2 = (option) => {
    console.log("regionHandler2");
    console.log(option);
    setNewRegion(option);
  };

  const updateSuppliers = async (regs) => {
    let body = { regions: JSON.stringify(regs) };
    const suppliersThing = await GetSuppliersApiCall(body);

    // dispatch(actionGetSuppliersApiCall(body));
    setSuppliers(suppliersThing.data.suppliers);
    handleInitialValues(suppliersThing.data.suppliers, regs);
  };

  const handleChangeSupplier = (event, region, item) => {
    console.log("handleChangeSupplier");
    console.log(event.target.checked);
    console.log(item);
    let tmpSelected = selectedSuppliers;
    if (event.target.checked) {
      tmpSelected[region].push(item.id);
    } else {
      tmpSelected[region] = tmpSelected[region].filter(
        (value) => value !== item.id
      );
    }
    setSelectedSuppliers(tmpSelected);
    console.log(selectedSuppliers);
  };

  const handleChangeBudget = (event, item) => {
    console.log("handleChangeBudget");
    console.log(event.target.value);
    console.log(item);
    let regs = regions;
    console.log("regs", regs);
    for (let i = 0; i < regs.length; i++) {
      if (regs[i].id === item.id) {
        regs[i].budget = event.target.value;
      }
    }
    setRegions(regs);
    console.log(regions);
    const arr = regs.map((item) => item.budget);
    console.log(arr);
    const sum = arr.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );
    console.log(sum);
    setTotalCosts(sum);
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        setCostsPerDay(
          Math.round(
            (sum / eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );
      }
    }
  };

  const UpdateBannerHandler = () => {
    setSubmitted(true);
    let formData = new FormData();
    formData.append("id", params.id ? params.id : null);
    formData.append("user_id", state_id);
    formData.append("agency_id", agencyId);
    formData.append("title", title);
    formData.append("quote", quote);
    formData.append("regions", JSON.stringify(regions));
    formData.append("suppliers", JSON.stringify(suppliers));
    formData.append("selectedSuppliers", JSON.stringify(selectedSuppliers));
    formData.append("radius", addBannerData.radius);
    formData.append("credits", addBannerData.credits);
    formData.append("budgets", JSON.stringify(addBannerData.budgets));
    formData.append("costs_per_day", costsPerDay);
    formData.append("total_costs", totalCosts);
    formData.append("credit_period", addBannerData.credit_period);
    formData.append("banner_types", JSON.stringify(["ONLINE"])); //JSON.stringify(selectedCheckboxes));
    formData.append("auto_select", false);
    formData.append("selected_properties", JSON.stringify(selectedProperties));
    formData.append("template_id", JSON.stringify(templateId));
    formData.append("start_date", addBannerData.start_date);
    formData.append("end_date", addBannerData.end_date);
    let body = {
      formData,
      navigate,
    };
    dispatch(actionUpdateBannerApiCall(body));
  };
  const changeRadius = (event) => {
    let tmpRegions = regions;
    let rad = 0;
    for (let i = 0; i < tmpRegions.length; i++) {
      if (
        tmpRegions[i].id === parseInt(event.target.id.replace("radius_", ""))
      ) {
        regions[i].radius = parseInt(event.target.value);
        rad = parseInt(event.target.value);
      }
    }
    if (rad <= 5) {
      setZoom(11);
    } else if (rad <= 10) {
      setZoom(10);
    } else if (rad <= 25) {
      setZoom(9);
    } else if (rad <= 50) {
      setZoom(8);
    } else {
      setZoom(7);
    }

    setRegions(tmpRegions);
    setChangedRadius(changedRadius + 1);
    updateSuppliers(regions);
  };
  const handleDeleteRegion = async (event, id) => {
    event.preventDefault();
    await updateSuppliers(regions.filter((item) => item.id !== id));

    setZoom(zoom);
    setRegions(regions.filter((item) => item.id !== id));
    setChangedRadius(changedRadius + 1);
  };

  const handleChangeDates = (event) => {
    addBannerData[event.target.id] = event.target.value;
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        let views = 0;
        for (let i = 0; i < suppliers.length; i++) {
          const supplier = suppliers[i];
          const cpm = supplier.price_tables.map((ptItem) =>
            ptItem.price_lines
              .map((plItem) => parseFloat(plItem.price))
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
              }, 0)
          );
          const budget = addBannerData.budgets[supplier.id];
          views +=
            Math.round(
              (parseFloat(budget) / parseFloat(cpm) + Number.EPSILON) * 100
            ) / 100;
        }
        setTotalViews(views * 1000);
        setDuration(eDate.diff(sDate, "days").toObject()["days"]);
        setViewsPerDay(
          Math.round(
            ((1000 * views) / eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );

        setCostsPerDay(
          Math.round(
            (addBannerData.total_costs /
              eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );
      }
    }
  };

  const format = new Intl.NumberFormat("nl-NL", { maximumFractionDigits: 2 });

  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Aantal views per dag",
      fontSize: 20,
    },
    axisY: {
      title: "Views",
    },
    axisX: {
      labelAngle: -20,
    },

    toolTip: {
      shared: true,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
    },
    data: bannerDetails?.chart_options?.data,
  };

  const handleInitialValues = (sups, regs) => {
    console.log("IB");
    console.log("sups", sups);
    console.log("regs", regs);
    const keys = Object.keys(sups);
    let tmpHash = {};
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i]);
      console.log(sups[keys[i]]);
      tmpHash[keys[i]] = sups[keys[i]].map((item) => item.id);
    }
    setSelectedSuppliers(tmpHash);
    console.log(selectedSuppliers);
    // const newRegs = regs.map(v => ({...v, budget: 50}))
    let newRegs = [];
    for (let i = 0; i < regs.length; i++) {
      let item = regs[i];
      if (typeof item["budget"] === "undefined" || item["budget"] === null) {
        item["budget"] = 50;
      }
      newRegs.push(item);
    }
    setRegions(newRegs);
    console.log(regions);
    const arr = newRegs.map((item) => item.budget);
    console.log(arr);
    const sum = arr.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );
    console.log(sum);
    setTotalCosts(sum);
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        setCostsPerDay(
          Math.round(
            (sum / eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );
      }
    }
    console.log(
      "calculated id",
      myPropertyListingData?.rows
        ?.filter((item) => item.id === parseInt(selectedProperties[0]))
        .map((item) => item.agency_id)[0]
    );
    setId(
      myPropertyListingData?.rows
        ?.filter((item) => item.id === parseInt(selectedProperties[0]))
        .map((item) => item.agency_id)[0]
    );
    console.log("id", id);
  };

  useEffect(() => {
    handleSave();
    dispatch(
      actionCityInfoApiCall({
        navigate,
        search: addressSearch ? addressSearch : "",
        countryId: 1,
      })
    );
  }, [addressSearch]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async () => {
    const property = bannerDetails?.banner_campaign_listing[0].listing_property;
    const body = { id: property.zipcode_id };
    console.log("body", body);
    const response = await GetZipcodeApiCall(body);
    console.log("response", response);
    const zipcode = response.data.data;
    setSelectedProperties([property.id]);
    setId(property.id);

    console.log("zipcode", zipcode);
    console.log("before setCenter 3");

    setCenter({ lat: zipcode.latitude, lng: zipcode.longitude });

    console.log("regions", bannerDetails?.campaign_regions);
    let tmpRegions = [];

    for (let i = 0; i < bannerDetails?.campaign_regions.length; i++) {
      const region = bannerDetails?.campaign_regions[i];
      const newReg = {
        budget: region.budget,
        name: region.region,
        type: region.region_type,
        id: zipcode.id,
        lat: zipcode.latitude,
        lng: zipcode.longitude,
        radius: region.radius,
      };
      tmpRegions.push(newReg);
    }

    setRegions(tmpRegions);

    setTitle(bannerDetails?.title);
    setQuote(bannerDetails?.quote);

    await updateSuppliers(tmpRegions);
    const arr = tmpRegions.map((item) => item.budget);
    console.log("arr", arr);
    const sum = arr.reduce(
      (accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue),
      0
    );
    console.log(sum);
    setTotalCosts(sum);
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        setCostsPerDay(
          Math.round(
            (sum / eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );
      }
    }

    for (let j = 0; j < mapRef.current.props.children.length; j++) {
      const child = mapRef.current.props.children[j];
    }
  };

  const handleOrganisationChange = (event, id) => {
    setAgencyId(id);
    filterProperties(event, id);
  };

  const filterProperties = (event, agency_id) => {
    const value = document.getElementById("search").value.toLowerCase();
    console.log("value", value);
    console.log("agencyId", agencyId);
    console.log(typeof agencyId);
    if (typeof agency_id === "undefined") {
      agency_id = agencyId;
    }
    if (value === "") {
      if (typeof agency_id === "undefined") {
        setProperties(myPropertyListingData.rows);
      } else {
        setProperties(
          myPropertyListingData.rows.filter(
            (item) => item.agency_id === agency_id
          )
        );
      }
    } else {
      if (typeof agency_id !== "undefined") {
        setProperties(
          myPropertyListingData.rows.filter((item) => {
            return (
              (item.address.toLowerCase().includes(value) ||
                item.city.toLowerCase().includes(value)) &&
              item.agency_id === agency_id
            );
          })
        );
      } else {
        setProperties(
          myPropertyListingData.rows.filter(
            (item) =>
              item.address.toLowerCase().includes(value) ||
              item.city.toLowerCase().includes(value)
          )
        );
      }
    }
  };

  const handlePropertyChange = async (event, id) => {
    setId(id);
    setRegions([]);
  };

  const addRegion = async (event) => {
    if (typeof newRegion != "undefined") {
      geocodeByAddress(newRegion.name)
        .then((results) => getLatLng(results[0]))
        .then(async ({ lat, lng }) => {
          console.log("before setCenter 2");
          setCenter({ lat: lat, lng: lng });

          console.log("regions", regions);

          const newReg = {
            budget: 50,
            name: newRegion.name,
            type: newRegion.type,
            id: newRegion.id,
            lat: lat,
            lng: lng,
            radius: newRadius,
          };

          console.log(newReg);

          const tmpRegions = [...regions, newReg];
          console.log("tmpRegions", tmpRegions);
          await updateSuppliers(tmpRegions);
          const arr = tmpRegions.map((item) => item.budget);
          console.log("arr", arr);
          const sum = arr.reduce(
            (accumulator, currentValue) =>
              parseInt(accumulator) + parseInt(currentValue),
            0
          );
          console.log(sum);
          setTotalCosts(sum);
          const sArr = addBannerData.start_date.split("-");
          if (sArr.length > 2) {
            const sDate = DateTime.local(
              parseInt(sArr[0]),
              parseInt(sArr[1]),
              parseInt(sArr[2])
            );
            const eArr = addBannerData.end_date.split("-");
            if (eArr.length > 2) {
              const eDate = DateTime.local(
                parseInt(eArr[0]),
                parseInt(eArr[1]),
                parseInt(eArr[2])
              );

              setCostsPerDay(
                Math.round(
                  (sum / eDate.diff(sDate, "days").toObject()["days"] +
                    Number.EPSILON) *
                    100
                ) / 100
              );
            }
          }

          setRegions([
            ...regions,
            {
              name: newRegion.name,
              type: newRegion.type,
              id: newRegion.id,
              lat: lat,
              lng: lng,
              radius: newRadius,
              budget: newBudget,
            },
          ]);
        });
    }
    for (let j = 0; j < mapRef.current.props.children.length; j++) {
      const child = mapRef.current.props.children[j];
    }
  };

  if (!loading) {
    console.log("not loading anymore...");
    console.log(bannerDetails);
    console.log("params", params);
    if (parseInt(params.id) != bannerDetails?.id) {
      const data = { id: params.id };
      dispatch(actionSaveBannerDetails(null));
      dispatch(actionViewBannerDetailsApiCall(data));
    }
  } else {
    console.log("loading ...");
  }

  let CJS = {};

  return (
    <DashboardLayout>
      <div className={classes.page_content}>
        <section id="stopSection" className={classes.centertext}>
          <div className={classes.container}>
            <h2>Woning campagne bijwerken</h2>
            <p>
              Eenvoudig en snel een woningcampagne met de Bannercampagnemodule!
              Geef jouw woningaanbod via Woningmarkt.nl extra zichtbaarheid met
              een paar simpele stappen. Selecteer een woning, stel je bereik in,
              kies je budget en ga live! Het werkt flexibel en is ook nog eens
              effectief. Ga nu aan de slag en start jouw eerste bannercampagne.
            </p>
          </div>
        </section>

        <section className={classes.box}>
          <div className={classes.container}>
            <div className={classes.content}>
              <h2>{t("banner_statistics")}</h2>
              {bannerDetails?.chart_options?.data.length > 0 && (
                <CanvasJSChart
                  options={options}
                  onRef={(ref) => (CJS.chart = ref)}
                />
              )}
              {bannerDetails?.chart_options?.data.length === 0 && (
                <p>{t("banner_not_viewed")}</p>
              )}
              <div className={classes.RegionTitle}>
                <p>{t("banner_regions")}</p>
                <div className={classes.InputFieldBox}>
                  <div className={classes.Regions}>
                    {bannerDetails?.campaign_regions?.map((region) => {
                      return (
                        <>
                          <div className={classes.RegionName}>
                            {region.region}
                          </div>
                          <div className={classes.RegionRadius}>
                            Straal {region.radius} km.
                          </div>
                          <div className={classes.RegionRadius}>
                            Budget € {region?.region_budget_details[0]?.credits}
                            ,-
                          </div>
                          <div className={classes.RegionRadius}>
                            Rest. €{" "}
                            {(
                              "" +
                              region?.region_budget_details[0]
                                ?.remaining_credits /
                                1000
                            )
                              .replace(".", ",")
                              .replace(",00", ",-")}
                            {(
                              "" +
                              region?.region_budget_details[0]
                                ?.remaining_credits /
                                1000
                            ).indexOf(".") < 0
                              ? ",-"
                              : ""}
                          </div>
                          {region?.region_budget_details[0]?.budget_supplier_details.map(
                            (sup) => {
                              return (
                                <>
                                  <div></div>
                                  <div className={classes.Suppliers}>
                                    {sup.supplier_details.url}
                                  </div>
                                  <div>cpm: € {sup.cpm},-</div>
                                  <div></div>
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={classes.box}>
          <div className={classes.container}>
            <div className={classes.content}>
              <h2>{t("selected_property")}</h2>
              {bannerDetails?.banner_campaign_listing.map((item) => {
                return (
                  <div className={classes.PropertyCard} key={item.id}>
                    <div
                      className={classes.PropertyImg}
                      onClick={() =>
                        navigate(
                          `/view-listing/${item.real_estate_property_id}`
                        )
                      }
                    >
                      {item.listing_property?.image_list.length > 0 && (
                        <img
                          alt=""
                          src={
                            item.listing_property?.image_list[0].url_thumb_file
                          }
                        />
                      )}
                    </div>
                    <div className={classes.MainContentWrap}>
                      <div className={classes.PropertyContent}>
                        <h5>{item?.listing_property?.address}</h5>
                      </div>
                      <div className={classes.PropertyContent}>
                        <span>{item?.listing_property?.city}</span>
                      </div>
                      <div className={classes.PropertyContent}>
                        <div className={classes.PriceText}>
                          {" "}
                          {item?.listing_property?.PropertyPrice?.length > 0 ? (
                            <p>
                              €{" "}
                              {PriceFormatter(
                                item.listing_property?.PropertyPrice[0].price
                              )}{" "}
                              {
                                item.listing_property?.PropertyPrice[0]
                                  .price_type
                              }
                            </p>
                          ) : (
                            <p>
                              €{" "}
                              {item.listing_property?.is_sell === 1
                                ? `${PriceFormatter(
                                    item.listing_property?.sell_price
                                  )} ${
                                    item.listing_property?.sell_price_unit
                                      ? item.listing_property?.sell_price_unit
                                      : ""
                                  }`
                                : item.listing_property?.is_rent === 1 &&
                                  `${PriceFormatter(
                                    item.listing_property?.rent_price
                                  )}${
                                    item.listing_property?.rent_price_type
                                      ? item.listing_property?.rent_price_type
                                      : ""
                                  }`}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className={classes.box}>
          <div className={classes.container}>
            <div className={classes.content}>
              <h2>{t("campaign_settings")}</h2>

              <div
                className={[classes.input_group, classes.full_width].join(" ")}
              >
                <span>{t("banner_title")}</span>
                <input
                  className={classes.input_title}
                  placeholder="Doorlopende campagne Garnizoenslaan 25, 9471ML Zuidlaren"
                  type="text"
                  name="title"
                  defaultValue={bannerDetails?.title}
                  onChange={(event) => setTitle(event.target.value)}
                  disabled
                />
              </div>
              <div
                className={[classes.input_group, classes.full_width].join(" ")}
              >
                <span>{t("banner_quote")}</span>
                <input
                  className={classes.input_quote}
                  placeholder="Nieuw in de verkoop in Zuidlaren"
                  type="text"
                  name="quote"
                  defaultValue={quote}
                  onChange={(event) => setQuote(event.target.value)}
                  disabled
                />
              </div>

              <div
                className={[classes.input_group, classes.date_group].join(" ")}
              >
                <div className={classes.half_width}>
                  <span>{t("banner_start_date")}</span>
                  <input
                    className={classes.input_startdate}
                    type="date"
                    name="start_date"
                    id="start_date"
                    defaultValue={addBannerData.start_date}
                    onChange={(event) => {
                      handleChangeDates(event);
                    }}
                    min={moment().format("Y-MM-DD")}
                    data-date-format="DD MMMM YYYY"
                    disabled
                  />
                </div>
                <div className={classes.half_width}>
                  <span>{t("banner_end_date")}</span>
                  <input
                    className={classes.input_enddate}
                    type="date"
                    name="end_date"
                    id="end_date"
                    defaultValue={addBannerData.end_date}
                    onChange={(event) => {
                      handleChangeDates(event);
                    }}
                    min={moment().format("Y-MM-DD")}
                    data-date-format="DD MMMM YYYY"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={classes.box}>
          <div className={classes.container}>
            <div className={classes.content}>
              <h2>Geografisch bereik</h2>
              <div className={classes.kaart}>
                {loadError ? (
                  <div>Error loading maps</div>
                ) : !isLoaded ? (
                  <div>Loading maps</div>
                ) : (
                  <div>
                    <GoogleMap
                      ref={mapRef}
                      mapContainerStyle={mapContainerStyle}
                      zoom={zoom}
                      center={center}
                      key={changedRadius}
                    >
                      {regions && regions.length > 0
                        ? regions.map((item) => (
                            <Circle
                              ref={circleRef}
                              options={{
                                strokeColor: "#9A1F60",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#9A1F60",
                                fillOpacity: 0.35,
                              }}
                              center={{
                                lng: item.lng,
                                lat: item.lat,
                              }}
                              radius={item.radius * 1000}
                              onLayout={() =>
                                this.setNativeProps({
                                  strokeColor: "#FFF000",
                                  fillColor: "#FFF000",
                                })
                              }
                            />
                          ))
                        : ""}
                    </GoogleMap>
                  </div>
                )}
              </div>
              <div
                className={[classes.input_group, classes.new_group].join(" ")}
              >
                <div>
                  <span>{t("banner_region")}</span>
                  <Select
                    className={classes.basic_single}
                    // classNamePrefix="select"
                    placeholder={t("area_neighbourhood_address_etc")}
                    defaultValue={
                      state?.saveSearchBackground &&
                      Object.keys(state?.saveSearchBackground).length > 0 &&
                      state?.saveSearchBackground.name
                        ? {
                            label: `${state?.saveSearchBackground.name}(${t(
                              state?.saveSearchBackground.type
                            )})`,
                            value: {
                              id: state?.saveSearchBackground.id,
                              type: state?.saveSearchBackground.type,
                              image: state?.saveSearchBackground.image,
                              name: state?.saveSearchBackground.name,
                            },
                          }
                        : null
                    }
                    isClearable={false}
                    isSearchable={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    name="address_data"
                    onInputChange={(data) => setAddressSearch(data)}
                    options={
                      state?.saveCityInfoData &&
                      state?.saveCityInfoData?.length > 0
                        ? state?.saveCityInfoData?.map((p) => {
                            return {
                              label: `${p.name} (${t(p.type)})`,
                              value: {
                                id: p.id,
                                type: p.type,
                                image: p.slider_image,
                                name: p.name,
                              },
                            };
                          })
                        : []
                    }
                    onChange={(option) => {
                      setNewRegion(option.value);
                      setSearchData({
                        ...searchData,
                        ["address_data"]: option,
                      });
                    }}
                  />
                </div>
                <div>
                  <span>Straal</span>
                  <select
                    className={classes.dropdown_small}
                    value={newRadius}
                    onChange={(event) => setNewRadius(event.target.value)}
                  >
                    {REGION_RADIUSES.map((item) => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <span>Budget</span>
                  <select
                    className={classes.dropdown_small}
                    value={newBudget}
                    onChange={(event) => setNewBudget(event.target.value)}
                  >
                    {SUPPLIER_BUDGETS.map((item) => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                className={classes.button}
                onClick={(event) => addRegion(event)}
              >
                + extra locatie
              </button>
            </div>
          </div>
        </section>

        <section className={classes.box}>
          <div className={classes.container}>
            <h2>Kanalen</h2>
            {regions && regions?.length > 0 ? (
              <RegionCardSelectable
                regions={regions}
                changeRadius={changeRadius}
                handleDeleteRegion={handleDeleteRegion}
                suppliers={suppliers}
                handleChangeSupplier={handleChangeSupplier}
                handleChangeBudget={handleChangeBudget}
              />
            ) : (
              ""
            )}
          </div>
        </section>

        <section className={[classes.box, classes.banner].join(" ")}>
          <div className={classes.container}>
            <h2>Banner Preview</h2>
            <div className={classes.content}>
              <TemplateCardSelectable
                choosenTemplateId={templateId}
                chooseTemplate={chooseTemplate}
                properties={
                  selectedProperties.length > 0
                    ? selectedProperties.join("-")
                    : myPropertyListingData?.rows
                        ?.filter((item) => item.agency_id === id)
                        .map((item) => item.id)
                        .slice(0, 3)
                        .join("-")
                }
                type="Leaderboard"
                quote={quote}
              />
              <TemplateCardSelectable
                choosenTemplateId={templateId}
                chooseTemplate={chooseTemplate}
                properties={
                  selectedProperties.length > 0
                    ? selectedProperties.join("-")
                    : myPropertyListingData?.rows
                        ?.filter((item) => item.agency_id === id)
                        .map((item) => item.id)
                        .slice(0, 3)
                        .join("-")
                }
                type="Mobile"
                quote={quote}
              />
            </div>
            <a href="#" className={classes.extend} id="toggleBanners">
              + alle formaten tonen
            </a>
          </div>
        </section>
      </div>
      <div className={classes.sidebar}>
        <h2>Totaal overzicht</h2>

        <div className={classes.bereik}>
          <span>Bereik:</span>
          {bannerDetails?.campaign_regions?.map((item) => (
            <p>
              {item.region} (+{item.radius}km)
            </p>
          ))}
        </div>

        <div className={classes.campagneduur}>
          <span>Campagne duur:</span>
          <p>
            {duration} {t("campaign_days")}
          </p>
        </div>

        <div className={classes.sub}>
          <p>Subtotaal:</p>
          <p> € {PriceFormatter(totalCosts, 2)}</p>
        </div>

        <div className={classes.totaal}>
          <p>Totaal</p>
          <p className={classes.discount_price}>
            € {PriceFormatter(totalCosts, 2)}
          </p>
          <p className={classes.main_price}>
            € {PriceFormatter(0.9 * totalCosts, 2)}
          </p>
        </div>

        <button className={classes.pinkbutton} onClick={UpdateBannerHandler}>
          {t("banner_update")}
        </button>
      </div>

      {/* <div className={classes.MyBannerLayoutMain}>
        <div className={classes.BannerFormMain}>
          {typeof searchParams.get("object") === "undefined" && (
            <div className={classes.OrgSelect}>
              <p>{t("banner_please_select_which_organisation")}</p>
              {organisationListData?.length > 0 ? (
                organisationListData.map((item) => {
                  return (
                    <div className={classes.OrganizationContainer}>
                      <p
                        onClick={() => {
                          setId(item?.id);
                          setBannerBudget(item?.banner_budget);
                          setAddBannerData((prevState) => ({
                            ...prevState,
                            agencyId: item?.id,
                          }));
                        }}
                        className={
                          id === item?.id
                            ? classes.OrgActive
                            : organisationListData?.length === 1
                            ? classes.OrgActive
                            : classes.Org
                        }
                      >
                        {item?.name}, {item?.city}
                      </p>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className={classes.OrganizationContainer}>
                    <p
                      onClick={() => {
                        setId(organisationListData?.id);
                        setAddBannerData((prevState) => ({
                          ...prevState,
                          agencyId: organisationListData?.id,
                        }));
                      }}
                    >
                      {organisationListData &&
                        `${organisationListData?.name}, ${organisationListData?.city}`}
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          <div className={classes.BannerRegionWrap}>
            <div className={classes.InputFieldBox}>
              <p>{t("banner_region")}</p>
            </div>
          </div>
          <div className={classes.BannerCreditWrap}>
            <div className={classes.InputFieldBox}>
              <p>{t("banner_select_credit")}</p>
              <div className={classes.BannerSummaryWrap}>
                <p>{t("banner_title")}</p>
                <p>{addBannerData.title}</p>
                <div className={classes.RowBorder}></div>
                <p>{t("banner_selected_regions_summary")}</p>
                <p>
                  {regions
                    .map((item) => `${item.name} (+ ${item.radius}km)`)
                    .join(", ")}
                </p>
                <div className={classes.RowBorder}></div>
                <p>{t("banner_runtime")}</p>
                <p>
                  {DateTime.fromISO(addBannerData.start_date).toFormat(
                    "dd-LL-yyyy"
                  )}{" "}
                  -{" "}
                  {DateTime.fromISO(addBannerData.end_date).toFormat(
                    "dd-LL-yyyy"
                  )}{" "}
                  ({" "}
                  {
                    DateTime.fromISO(addBannerData.end_date)
                      .diff(DateTime.fromISO(addBannerData.start_date), "days")
                      .toObject()["days"]
                  }{" "}
                  {t("banner_days")} )
                </p>
                <div className={classes.RowBorder}></div>
              </div>
              <div className={classes.BannerSummaryWrap}>
                <p>{t("banner_costs_per_day")}</p>
                <p>€ {PriceFormatter(costsPerDay, 2)}</p>
                <div className={classes.RowBorder}></div>
                <p>{t("banner_runtime_costs")}</p>
                <p>€ {PriceFormatter(totalCosts, 2)}</p>
                <div className={classes.RowBorder}></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Huis selecteren</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.modal_grid}>
            <div className={[classes.modal_box, classes.small].join(" ")}>
              {organisationListData?.length > 0
                ? organisationListData.map((item) => {
                    return (
                      <label>
                        <input
                          type="radio"
                          name="organisation"
                          value={item.id}
                          onChange={(event) => {
                            handleOrganisationChange(event, item.id);
                          }}
                        />{" "}
                        {item.name}
                      </label>
                    );
                  })
                : ""}
            </div>
            <div className={classes.modal_content_right}>
              <div className={classes.search_container}>
                <div className={classes.search}>
                  <input
                    id="search"
                    type="text"
                    placeholder="Zoeken..."
                    onChange={(event) => filterProperties(event)}
                  />
                  <button className={classes.search_button}>
                    <FontAwesomeIcon icon={fas.faSearch} />
                  </button>
                </div>
                <span className={classes.filter}>
                  Filter <FontAwesomeIcon icon={far.faFilter} />
                </span>
              </div>

              <div className={[classes.modal_box, classes.large].join(" ")}>
                <div className={classes.scrollable_box}>
                  {properties.length > 0
                    ? properties.map((item) => {
                        return (
                          <label>
                            <input
                              type="radio"
                              name="property"
                              value={item.id}
                              onChange={(event) => {
                                handlePropertyChange(event, item.id);
                              }}
                            />{" "}
                            {item.address}, {item.postcode} {item.city}
                          </label>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={classes.pinkbutton} onClick={handleSave}>
            Opslaan
          </button>
        </Modal.Footer>
      </Modal>
    </DashboardLayout>
  );
};
export default MyUpdateBannerLayout;
