import React from "react";
import MainContainer from "../layouts/MainContainer";
import classes from './viewallblogs.module.scss';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetLatestBlogsApiCall } from "../../Redux/Actions";
import { useEffect, useState, useRef } from "react";
import Loader from "../Loader";

const ViewAllBlogsMain = () => {
    const [columns, setColumns] = useState(4);
    const [searchQuery, setSearchQuery] = useState('');
    const searchTimeoutRef = useRef(null);

    const viewAllBlogs = useSelector(
        (state) => state.CommonReducer.SaveLatestBlogsData
    );
    const loading = useSelector((state) => state.CommonReducer.loading);
    const dispatch = useDispatch()
    console.log("the viewAllBlogs is", viewAllBlogs);
    const navigate = useNavigate();
    const { country } = useParams();



    useEffect(() => {
        const handleResize = () => {
            setColumns(window.innerWidth > 1440 ? 4 : 3);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        dispatch(actionGetLatestBlogsApiCall({
            navigate,
            search: country,
        }))
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        console.log("the searchQuery is", searchQuery);
        dispatch(actionGetLatestBlogsApiCall({
            navigate,
            search: country,
            title: searchQuery
        }));
    };

    const handleSearchChange = (e) => {
        const newValue = e.target.value;
        setSearchQuery(newValue);

        // Clear any existing timeout
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        // Set new timeout for API call
        searchTimeoutRef.current = setTimeout(() => {
            dispatch(actionGetLatestBlogsApiCall({
                navigate,
                search: country,
                title: newValue
            }));
        }, 700); // Delay of 700ms
    };

    // Cleanup timeout on component unmount
    useEffect(() => {
        return () => {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }
        };
    }, []);

    const formatUrlTitle = (title) => {
        return title
            .toLowerCase()
            .replace(/\s+/g, '-')        // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '')     // Remove special characters
            .replace(/--+/g, '-');       // Replace multiple hyphens with single hyphen
    };

    return (
        <>
            <div className={classes.searchBanner}>
                <div className={classes.searchContent}>
                    <h1>Blogs doorzoeken?</h1>
                    <form onSubmit={handleSubmitSearch} className={classes.searchForm}>
                        <input
                            type="text"
                            placeholder="Doorzoek onze blogs"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <button type="submit">Zoeken!</button>
                    </form>
                </div>
            </div>

            <MainContainer>
                {loading ? (
                    <div className={classes.blogLoaderWrap}>
                        <Loader />

                    </div>
                ) : (
                    <div className={`${classes.blogSection} ${classes[`columns-${columns}`]}`}>
                        <h1 className={classes.title}>Meest recent</h1>
                        <div className={classes.blogGrid}>
                            {viewAllBlogs && viewAllBlogs.length > 0 ? (
                                viewAllBlogs.map((blog) => (
                                    <div key={blog.id} className={classes.blogCard}>
                                        <div className={classes.imageWrapper}>
                                            <img src={blog?.attributes?.Image?.data?.attributes?.url} alt={blog.title} />
                                        </div>
                                        <div className={classes.content}>
                                            <h2>{blog?.attributes?.Title}</h2>
                                            <p>
                                                {blog?.attributes?.summary?.length > 100
                                                    ? `${blog?.attributes?.summary.substring(0, 150)}...`
                                                    : blog?.attributes?.summary}
                                            </p>
                                            <Link
                                                to={`/blog/${formatUrlTitle(blog?.attributes?.Title)}`}
                                                state={{ blogId: blog.id, country:country }}
                                                className={classes.cardReadMore}
                                                replace={true}
                                            >
                                                Lees meer {'>'}
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={classes.noBlogResults}>
                                    No Data!
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </MainContainer>
        </>
    );
}

export default ViewAllBlogsMain;