import React from "react";
import MainContainer from "../layouts/MainContainer";
import classes from "./cmsmain.module.scss";
import { useTranslation } from "react-i18next";
import Accordion from "react-bootstrap/Accordion";
import TemplateLayout from "../layouts/TemplateLayout";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetSlugDataStrapiApiCall,
  actionSaveSlugDataStrapi,
} from "../../Redux/Actions";
import Loader from "../Loader";

const CMSMain = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveSlugData = useSelector((state) => state.CommonReducer.saveSlugData);
  const params = useParams();
  const loading = useSelector((state) => state.CommonReducer.loading);
  useEffect(() => {
    dispatch(
      actionGetSlugDataStrapiApiCall({
        navigate,
        id: location?.state?.id,
        slug: location?.state?.slug ? location?.state?.slug : params?.slug,
      })
    );
  }, [location]);
  useEffect(() => {
    dispatch(actionSaveSlugDataStrapi(""));
  }, []);
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location])
  return (
    <React.Fragment>
      {loading ? (
        <div className={classes.LoaderWrap}>
          <Loader />
        </div>
      ) : (
        <TemplateLayout
          title={saveSlugData?.data?.attributes?.Title}
          image={saveSlugData?.data?.attributes?.Banner?.data?.attributes?.url}
        >
          <div className={classes.TemplateContentSection}>
            {saveSlugData?.data?.attributes?.Section?.length > 0 &&
              saveSlugData?.data?.attributes?.Section?.map((item) => {
                if (item.__component === "cms.accordion") {
                  return (
                    <div className={classes.AccordionSection}>
                      <Accordion>
                        {item?.Items?.length > 0 &&
                          item.Items.map((val) => (
                            <Accordion.Item eventKey={val.id}>
                              <Accordion.Header>{val.Title}</Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html: val.Content,
                                }}
                              ></Accordion.Body>
                            </Accordion.Item>
                          ))}
                      </Accordion>
                    </div>
                  );
                } else if (item.__component === "cms.description") {
                  return (
                    <div className={classes.DescriptionWrap}>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.Description }}
                      ></p>
                    </div>
                  );
                } else if (item.__component === "cms.grid-box-items") {
                  return (
                    <div className={classes.ContactCardSection}>
                      {item?.Items?.length > 0 &&
                        item.Items.map((val) => (
                          <div className={classes.CardMain}>
                            <div className={classes.CardTitleWrap}>
                              {val?.Icon?.data?.attributes?.url && (
                                <img src={val?.Icon?.data?.attributes?.url} />
                              )}
                              <h1>{val.Title}</h1>
                            </div>
                            <p
                              dangerouslySetInnerHTML={{ __html: val.Content }}
                            ></p>
                          </div>
                        ))}
                    </div>
                  );
                } else if (item.__component === "cms.media") {
                  return (
                    <div className={classes.ImgWrap}>
                      <img src={item?.Image?.data?.attributes?.url} />
                    </div>
                  );
                }
              })}
          </div>
        </TemplateLayout>
      )}
      {(location?.state?.slug ? location?.state?.slug : params?.slug) ===
        "verkoop-particulier" && (
        <MainContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: `<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
    <form action="https://woningmarkt.us21.list-manage.com/subscribe/post?u=da20256111923a525edd6b4b3&amp;id=5c36fed2d3&amp;f_id=00b0b2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
        <h2>Aanmelden nieuwsbrief</h2>
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
	<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_da20256111923a525edd6b4b3_5c36fed2d3" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='MMERGE7';ftypes[7]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->`,
            }}
          ></div>
        </MainContainer>
      )}
    </React.Fragment>
  );
};

export default CMSMain;
