const inititalState = {
  saveForgotEmail: "",
  saveOtp: "",
  loginToken: "",
  homePropertyData: "",
  myPropertyListingData: "",
  myOrganisationListingData: "",
  saveSearchOrganisationData: "",
  singlePropertyDetails: "",
  savedFilterData: "",
  myBannerCampaignsData: "",
  myBannerTemplatesData: "",
  bannerDetails: "",
  loginInfo: {},
  languages: "",
  selectedLanguage: "",
  organisationDetail: "",
  loading: false,
  saveHomeBanner: "",
  saveHomeStrapi: "",
  saveFooterStrapi: "",
  savePropertyTypeList: "",
  saveApplicableOptions: "",
  saveCities: "",
  saveRentPrefix: "",
  saveRentPostfix: "",
  saveSellPrefix: "",
  saveSellPostfix: "",
  saveHeaderStrapi: "",
  saveCopyRightContentStrapi: "",
  saveSocialIconsStrapi: "",
  saveSlugData: "",
  savePropertyType: "both",
  approvedOrganisationData: "",
  organisationEmployeeList: "",
  saveEmployeeJobTitle: "",
  saveSiteSettings: "",
  saveGetAllCountries:[],
  pdfLink: "",
  userVerified: "",
  paymentUrl: "",
  saveSearchOrganisationWithoutLogin: "",
  isPropertyAvailable: true,
  saveSubscriptionData: "",
  savePaymentStatus: "",
  saveSingleSubscriptionData: "",
  saveOrgDetailsWithoutLogin: "",
  saveSelfOrganisationforSubscription: "",
  cityList: "",
  cityInfo: [],
  TranslationList: [],
  saveCityInfoData: [],
  saveCountryImageInfoData:[],
  saveBanner: [],
  saveSearchBackground: {},
  saveAgencyBannerRecommendations: {},
  defaultPropertyBannerRegion: {},
  saveSearchStatus: "",
  getPropertyAllData: [],
  saveMagazineData: {},
  saveMagazinesData: [],
  SaveMainMenuButton:[],
  SaveTopMenuButton:[],
  SaveSliderDashboardData: {},
  SaveLatestBlogsData:[],
  SaveSingleBlogData: {}
};
function CommonReducer(state = inititalState, action) {
  switch (action.type) {
    case "ACTION_SAVE_FORGOT_EMAIL":
      return { ...state, saveForgotEmail: action.payload };
    case "ACTION_SAVE_OTP":
      return { ...state, saveOtp: action.payload };
    case "ACTION_SAVE_LOGIN_TOKEN":
      return { ...state, loginToken: action.payload };
    case "ACTION_SAVE_LATEST_PROPERTY_LISTING_HOME":
      return { ...state, homePropertyData: action.payload };
    case "ACTION_SAVE_MY_PROPERTY_LISTING":
      return { ...state, myPropertyListingData: action.payload };
    // case "ACTION_SAVE_ALL_PROPERTY_DATA_LISTING":
    //   return { ...state, getPropertyAllData: action.payload };

    //GET PROPERTY DATA
    case "ACTION_SAVE_PROPERTY_DATA":
      return { ...state, getPropertyAllData: action.payload };
    case "ACTION_SAVE_SEARCH_ORGANISATION":
      return { ...state, saveSearchOrganisationData: action.payload };
    case "ACTION_SAVE_BANNER_CAMPAIGNS_LISTING":
      return { ...state, myBannerCampaignsData: action.payload };
    case "ACTION_SAVE_BANNER_TEMPLATES_LISTING":
      return { ...state, myBannerTemplatesData: action.payload };
    case "ACTION_EMPTY_SEARCH_ORGANISATION":
      return { ...state, saveSearchOrganisationData: [] };
    case "ACTION_REMOVE_SAVE_JOIN_ORGANISATION":
      let RemainingOrganisation = state.myOrganisationListingData.filter(
        (data) => data.id !== action.payload.id
      );
      return { ...state, myOrganisationListingData: RemainingOrganisation };
    case "ACTION_CHANGE_FAVOURITE":
      const { id, isFavourite, pathName } = action.payload;
      if (pathName === "/favourites") {
        let PropertyListing = state.myPropertyListingData.rows.find(
          (data) => data.id === id
        );
        if (PropertyListing) {
          PropertyListing.is_favourite = isFavourite;
        }
        const remainingFavouriteData = state.myPropertyListingData.rows.filter(
          (item) => item.is_favourite === 1
        );
        return {
          ...state,
          myPropertyListingData: {
            ...state.myPropertyListingData,
            rows: remainingFavouriteData,
          },
        };
      } else {
        let PropertyListing = state.myPropertyListingData.rows.find(
          (data) => data.id === id
        );
        if (PropertyListing) {
          PropertyListing.is_favourite = isFavourite;
        }
        const remainingFavouriteData = state.myPropertyListingData.rows.map(
          (item) => {
            return item;
          }
        );
        return {
          ...state,
          myPropertyListingData: {
            ...state.myPropertyListingData,
            rows: remainingFavouriteData,
          },
        };
      }
    case "ACTION_SAVE_SINGLE_PROPERTY_DETAILS":
      return { ...state, singlePropertyDetails: action.payload };
    case "ACTION_SAVE_BANNER_DETAILS":
      return { ...state, bannerDetails: action.payload };
    case "ACTION_SAVE_FILTER_DATA":
      return { ...state, savedFilterData: action.payload };
    case "ACTION_UPDATE_HIDE_PROPERTY":
      const { property_id, hide_property } = action.payload;
      let propertyData = state.myPropertyListingData.rows.find(
        (data) => data.id === property_id
      );
      if (propertyData) {
        propertyData.hide_property = hide_property;
      }
      let allPropertyData = state.myPropertyListingData.rows.map((item) => {
        if (item.id === propertyData.id) {
          return propertyData;
        } else {
          return item;
        }
      });
      return {
        ...state,
        myPropertyListingData: {
          ...state.myPropertyListingData,
          rows: allPropertyData,
        },
      };
    case "ACTION_SAVE_LOGIN_INFO":
      return { ...state, loginInfo: action.payload };
    case "RESET_STATE":
      return {
        ...state,
        saveForgotEmail: "",
        saveOtp: "",
        loginToken: "",
        homePropertyData: "",
        myPropertyListingData: "",
        myOrganisationListingData: "",
        saveSearchOrganisationData: "",
        singlePropertyDetails: "",
        savedFilterData: "",
        loginInfo: {},
        languages: "",
        organisationDetail: "",
        loading: false,
        saveHomeBanner: "",
        approvedOrganisationData: "",
        saveHomeStrapi: "",
        saveFooterStrapi: "",
        savePropertyTypeList: "",
        saveApplicableOptions: "",
        saveCities: "",
        cityInfo: [],
        saveRentPrefix: "",
        saveRentPostfix: "",
        saveSellPrefix: "",
        saveSellPostfix: "",
        saveHeaderStrapi: "",
        saveCopyRightContentStrapi: "",
        saveSocialIconsStrapi: "",
        saveSlugData: "",
        savePropertyType: "both",
        organisationEmployeeList: "",
        saveEmployeeJobTitle: "",
        saveSiteSettings: "",
        isPropertyAvailable: true,
      };
    case "ACTION_SAVE_DEFAULT_PROPERTY_BANNNER_REGION":
      return { ...state, defaultPropertyBannerRegion: action.payload };
    case "ACTION_SAVE_GET_LANGUAGES":
      return { ...state, languages: action.payload };
    case "ACTION_SAVE_SELECTED_LANGUAGE":
      return { ...state, selectedLanguage: action.payload };
    case "ACTION_SAVE_ORGANISATION_DETAIL":
      return { ...state, organisationDetail: action.payload };
    case "ACTION_SET_LOADER":
      return { ...state, loading: action.payload };
    case "ACTION_SAVE_GET_HOME_BANNER":
      return { ...state, saveHomeBanner: action.payload };
    case "ACTION_SAVE_HOME_STRAPI":
      return { ...state, saveHomeStrapi: action.payload };
    case "ACTION_SAVE_FOOTER_STRAPI":
      return { ...state, saveFooterStrapi: action.payload };
    case "ACTION_SAVE_GET_PROPERTY_TYPE":
      return { ...state, savePropertyTypeList: action.payload };
    case "ACTION_SAVE_GET_APPLICABLE_OPTIONS":
      return { ...state, saveApplicableOptions: action.payload };
    case "ACTION_SAVE_GET_CITIES":
      return { ...state, saveCities: action.payload };
    case "ACTION_SAVE_GET_TRANSLATION_LIST":
      return { ...state, TranslationList: action.payload };
    case "ACTION_SAVE_GET_RENT_PREFIX":
      return { ...state, saveRentPrefix: action.payload };
    case "ACTION_SAVE_GET_RENT_POSTFIX":
      return { ...state, saveRentPostfix: action.payload };
    case "ACTION_SAVE_GET_SELL_PREFIX":
      return { ...state, saveSellPrefix: action.payload };
    case "ACTION_SAVE_GET_SELL_POSTFIX":
      return { ...state, saveSellPostfix: action.payload };
    case "ACTION_SAVE_HEADER_STRAPI":
      return { ...state, saveHeaderStrapi: action.payload };
    case "ACTION_SAVE_COPY_RIGHT_CONTENT_STRAPI":
      return { ...state, saveCopyRightContentStrapi: action.payload };
    case "ACTION_SAVE_SOCIAL_ICONS_STRAPI":
      return { ...state, saveSocialIconsStrapi: action.payload };
    case "ACTION_SAVE_SLUG_DATA_STRAPI":
      return { ...state, saveSlugData: action.payload };
    case "ACTION_SAVE_PROPERTY_TYPE":
      return { ...state, savePropertyType: action.payload };
    case "ACTION_SAVE_APPROVED_ORGANISATIONS":
      return { ...state, approvedOrganisationData: action.payload };
    case "ACTION_SAVE_ORGANISATION_EMPLOYEE_LIST":
      return { ...state, organisationEmployeeList: action.payload };
    case "ACTION_SAVE_EMPLOYEE_JOB_TITLE":
      return { ...state, saveEmployeeJobTitle: action.payload };
    case "ACTION_SAVE_REMOVE_ORGANISATION_EMPLOYEE":
      let RemainingOrganisationEmployee =
        state.organisationEmployeeList.agency_members.filter(
          (data) => data.model.user_id !== action.payload.employee_id
        );
      return {
        ...state,
        organisationEmployeeList: {
          ...state.organisationEmployeeList,
          agency_members: RemainingOrganisationEmployee,
        },
      };
    case "ACTION_SAVE_GET_SITE_SETTINGS":
      return { ...state, saveSiteSettings: action.payload };
    case "ACTION_SAVE_GET_ALL_COUNTRIES":
      return { ...state, saveGetAllCountries: action.payload };
    case "ACTION_SAVE_DELETE_MY_PROPERTY":
      let RemainingPropertyData = state.myPropertyListingData.rows.filter(
        (data) => data.id !== action.payload
      );
      return {
        ...state,
        myPropertyListingData: {
          ...state.myPropertyListingData,
          rows: RemainingPropertyData,
        },
      };
    case "ACTION_SAVE_PDF_LINK":
      return { ...state, pdfLink: action.payload };
    case "ACTION_USER_VERIFIED":
      return { ...state, userVerified: action.payload };
    case "ACTION_SAVE_MOLLIE_PAYMENT_URL":
      return { ...state, paymentUrl: action.payload };
    case "ACTION_SAVE_SEARCH_ORGANISATION_WITHOUT_LOGIN":
      return { ...state, saveSearchOrganisationWithoutLogin: action.payload };
    case "ACTION_SAVE_MY_ORGANISATION_LISTING":
      return { ...state, myOrganisationListingData: action.payload };

    case "ACTION_SAVE_IS_PROPERTY_AVAILABLE":
      return { ...state, isPropertyAvailable: action.payload };
    case "ACTION_SAVE_DELETE_PROPERTY_BANNER":
      let RemainingBannerCampaignsData = state.myBannerCampaignsData.filter(
        (data) => data.id !== action.payload
      );
      return {
        ...state,
        myBannerCampaignsData: RemainingBannerCampaignsData,
      };

    case "ACTION_SAVE_SUBSCIPTION":
      return { ...state, saveSubscriptionData: action.payload };

    case "ACTION_SAVE_SUBSCIPTION_WITH_STATUS":
      return { ...state, savePaymentStatus: action.payload };

    case "ACTION_SAVE_SINGLE_SUBSCIPTION_DETAILS":
      return { ...state, saveSingleSubscriptionData: action.payload };

    case "SAVE_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN":
      return { ...state, saveOrgDetailsWithoutLogin: action.payload };

    case "ACTION_SAVE_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION":
      return { ...state, saveSelfOrganisationforSubscription: action.payload };

    case "ACTION_SAVE_GET_CITY_LIST":
      return { ...state, cityList: action.payload };

    case "ACTION_SAVE_AUTO_SUGGEST_CITY":
      return { ...state, cityInfo: action.payload };

    case "ACTION_CITY_INFO_SAVE_DATA":
      return { ...state, saveCityInfoData: action.payload };
    case "ACTION_COUNTRY_IMAGE_SAVE_DATA":
      return { ...state, saveCountryImageInfoData: action.payload };

    case "ACTION_SAVE_BANNERS_DATA":
      return { ...state, saveBanner: action.payload };

    case "ACTION_SAVE_SEARCH_BACKGROUND_IMAGE_URL":
      return { ...state, saveSearchBackground: action.payload };

    case "ACTION_SAVE_AGENCY_BANNER_RECOMMENDATIONS_DATA":
      return { ...state, saveAgencyBannerRecommendations: action.payload };

    case "ACTION_SAVE_SUPPLIERS_DATA": {
      return { ...state, saveSuppliers: action.payload };
    }
    case "ACTION_SEARCH_STATUS": {
      return { ...state, saveSearchStatus: action.payload };
    }
    case "ACTION_SAVE_INVOICES_DATA":
      return { ...state, myInvoicesData: action.payload };
    case "ACTION_SAVE_INVOICE_DATA":
      return { ...state, myInvoiceData: action.payload };
    // MAGAZINES
    case "ACTION_SAVE_MAGAZINE_DATA":
      return { ...state, saveMagazineData: action.payload };
    case "ACTION_SAVE_MAGAZINES_DATA":
      console.log("Save Magazines Data", action.payload);
      return { ...state, saveMagazinesData: action.payload };
    case "ACTION_SAVE_MAGAZINE_AD_KINDS_DATA":
      console.log("saveMagazineAdKindsData", action.payload);
      return { ...state, saveMagazineAdKindsData: action.payload };
    case "ACTION_SAVE_TOP_MENU_DATA":
      return {...state, SaveTopMenuButton: action.payload}
    case "ACTION_SAVE_MAIN_MENU_DATA":
      return {...state, SaveMainMenuButton: action.payload}
    case "ACTION_SAVE_SLIDER_DASHBOARD_DATA":
      return {...state, SaveSliderDashboardData: action.payload}
    case "ACTION_SAVE_LATEST_BLOGS_DATA" :
      return {...state, SaveLatestBlogsData: action.payload}
    case "ACTION_SAVE_SINGLE_BLOG_DATA" :
      return {...state, SaveSingleBlogData: action.payload}
    default:
      return state;
  }
}
export default CommonReducer;
