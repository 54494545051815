import React from "react";
import classes from "./addslayout.module.scss";
import MainContainer from "../MainContainer";
import BackImg from "../../../assets/images/AddsLaoutBuldingImg.svg";
import RedirectionTab from "../../../utils/RedirectionTab";
import { useNavigate } from "react-router-dom";

const AddsLayoyt = (props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {props?.adsData?.length > 0 && (
        <div className={classes.AddsLayoutSection}>
          <img
            className={classes.backGroundImg}
            src={BackImg}
            alt="Background"
          />
          <div className={classes.AddLayoutContainer}>
            <MainContainer>
              <div className={classes.AddsLayoutWrap}>
                {props?.adsData?.length > 0 &&
                  props.adsData.map((item) => (
                    <div className={classes.AddImgWrap}>
                      <img
                        src={item.url_original_file}
                        onClick={() => {
                          let data = {
                            link: item.link,
                            link_target: item.link_target,
                          };
                          RedirectionTab(data, navigate);
                        }}
                        alt={item.original_file_name}
                      />
                    </div>
                  ))}
              </div>
            </MainContainer>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddsLayoyt;
